/*eslint-disable*/

/**
 * NOTE TO AVIGYAN: Check line 151 of this file for generating model answer API Call
 */

/**
 * Project: myAcademic
 * Filename: Quiz.jsx 
 * Author: Arin Ray
 * Created on: 2024-MM-DD
 * Last Modified by: Arin Ray
 * Last Modified on: 2024-11-11
 * Version: 1.0.0
 * Purpose: UI for Creating/Editing a Question form
 */

import { Chip, createTheme, FormControl, FormHelperText, TextField, ThemeProvider, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import styles from './CreateQuiz.module.css'


import NewOption from './NewOption';
import AddIcon from '@mui/icons-material/Add';
import RTE from './RTE';
import Button from '@mui/material/Button';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import axios from 'axios';

const NewQuestion = ({currentQuestion, setCurrentQuestion, addQuestion, isNew}) => {
	const [currentCorrectAnswer, setCurrentCorrectAnswer] = useState('');
	const [isCurrentCorrectAnswerValid, setIsCurrentCorrectAnswerValid] = useState(true);
	const [selectedBlankId, setSelectedBlankId] = useState('');
	const [isValidStatement, setIsValidStatement] = useState(true);
	const [isValidCorrectAnswers, setIsValidCorrectAnswers] = useState(true);
	const [isValidCorrectAnswersForBlank, setIsValidCorrectAnswersForBlank] = useState(true);
	const [isValidPoints, setIsValidPoints] = useState(true);
	const [isValidFITBQuestion, setIsValidFITBQuestion] = useState(currentQuestion.correctAnswers.length > 0);
	const [isValidSelectedBlankId, setIsValidSelectedBlankId] = useState(true);

	const validateQuestion = () => {
		var isValid = true;
		var id = null;

		// points cannot be less than 1 -> done
		if (currentQuestion.points === null || currentQuestion.points.length === 0) {
			setIsValidPoints(false);
			if (isValid) {
				id = 'question-points';
			}
			isValid = false;
		}

		// statement cannot be empty
		if (currentQuestion.statement === null || currentQuestion.statement.length === 0) {
			setIsValidStatement(false);
			if (isValid) {
				id = 'question-statement';
			}
			isValid = false;
		}

		// type cannot be empty -> done

		// if type MCQ || TF => correctAnswers cannot be empty 
		if (currentQuestion.type === 'MCQ' || currentQuestion.type === 'TF') {
			if (!currentQuestion.correctAnswers.length) {
				setIsValidCorrectAnswers(false);
				if (isValid) {
					id = 'mark-correct-answers';
				}
				isValid = false;
			}
		}

		// if type FITB => each correctAnswer.correctAnswers cannot be empty
		if (currentQuestion.type === 'FITB') {
			currentQuestion.correctAnswers.forEach((correctAnswer) => {
				if (!correctAnswer.correctAnswers.length) {
					setSelectedBlankId(correctAnswer.id)
					setIsValidCorrectAnswersForBlank(false);
					isValid = false;
				}
			})
		}

		// if type FITB => There needs to be atleast one blank
		if (currentQuestion.type === 'FITB' && !isValidFITBQuestion) {
			if (isValid) {
				id = 'question-statement';
			}
			isValid = false;
		}

		document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
		return isValid;
	}

	const [nextBlankId, setNextBlankId] = useState(
		1 + Math.max(0, ...(Array.from(currentQuestion.statement.matchAll(/id="Blank-[0-9]*/g)).map(a => parseInt(a[0].substring(10)))))
	);
	
	const handleFillInTheBlanksCorrectOptionChoice = (id) => {
		setCurrentQuestion((prevCurrentQuestion) => ({
			...prevCurrentQuestion,
			correctAnswers: [{id: id, correctAnswers: []}]
		}))
	}

	const handleMultipleChoiceQuestionOptionChoice = (id) => {
		setCurrentQuestion((prevCurrentQuestion) => ({
			...prevCurrentQuestion,
			correctAnswers: prevCurrentQuestion.correctAnswers.filter(correctAnswer => correctAnswer.id === id).length ? (
				prevCurrentQuestion.correctAnswers.filter(correctAnswer => (correctAnswer.id !== id))
			) : (
				[...prevCurrentQuestion.correctAnswers, {id: id, correctAnswers: []}]
			)
		}))
	}

	const deleteOption = (id) => {
		setCurrentQuestion((prevCurrentQuestion) => ({
			...prevCurrentQuestion,
			options: prevCurrentQuestion.options.filter(option => (id !== option.id))
		}))
	}

	const theme = createTheme({
		components: {
			MuiFormLabel: {
				styleOverrides: {
					asterisk: { color: "#d32f2f" },
				},
			},
		},
	
	})

	const generateModelAnswer = async () => {
		const extractContent = (s) => {
			const processedString = s.replace(/<input class="question-input" placeholder="Blank-[0-9]*" id="Blank-[0-9]*">/g, "___")
			var span = document.createElement('span');
			span.innerHTML = processedString;
			return span.textContent || span.innerText;
		};

		const questionStatement = extractContent(currentQuestion.statement);
		/**
		 * Call your generate model answer API here and store the answer in the variable modelAnswer
		 * INPUT: questionStatement
		*/
		const req_data = {
			question:questionStatement
		};
		const modelAnswerGenertorAPICall = async (req_data)=>{
			try {
				const response = await axios.post("http://localhost:8000/generate-model-answer/", req_data, {
				  headers: {
					"Content-Type": "application/json"
				  },
				});
				
				console.log("Response from Django:", response.data);
				return response.data.generated_answer;
			  } catch (error) {
				console.error("Error making POST request:", error);
				return "";
			  }
		}
		const modelAnswer = await modelAnswerGenertorAPICall(req_data);
		setCurrentQuestion((prevCurrentQuestion) => ({
			...prevCurrentQuestion,
			correctAnswers: [{
				id: 1,
				correctAnswers: [
					modelAnswer
				]
			}]
		}))
	}

	return (
		<>
			<div
				className={`${styles['row']} ${styles['to-col']}`}
				style={{
					alignItems: 'flex-start'
				}}
			>
				<FormControl sx={{ minWidth: 80 }}>
					<InputLabel id="demo-simple-select-label">Type</InputLabel>
					<ThemeProvider theme={theme}>
						<Select
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={currentQuestion.type}
							label="Type"
							onChange={(e) => {
								setCurrentQuestion((prevCurrentQuestion) => ({
									...prevCurrentQuestion,
									options: (e.target.value === "TF") ? ([{
										id: 1,
										optionStatement: "True"
									}, {
										id: 2,
										optionStatement: "False"
									}]) : ((e.target.value === "MCQ") ? ([{
										id: 1,
										optionStatement: "Option"
									}]) : ([
										
									])),
									correctAnswers: (e.target.value === "FITB") ? [
										...(Array.from(currentQuestion.statement.matchAll(/id="Blank-[0-9]*/g)).map(a => ({
											id: (a[0].substring(4)),
											correctAnswers: [] 
										})))
									] : (e.target.value === "SAT") ? [{
										id: 1,
										correctAnswers: [
											""
										]
									}] : [],
									type: e.target.value
								}))
							}}
							autoWidth
							required
						>
							<MenuItem value={"MCQ"}>Multiple Choice Question</MenuItem>
							<MenuItem value={"FITB"}>Fill In The Blanks</MenuItem>
							<MenuItem value={"TF"}>True Or False</MenuItem>
							<MenuItem value={"SAT"}>Short Answer</MenuItem>
						</Select>
					</ThemeProvider>
				</FormControl> 
				<ThemeProvider theme={theme}>
					<TextField
						id={'question-points'}
						label="Points"
						type="number"
						sx={{width: "9em"}}
						onBlur={() => {
							if (currentQuestion.points === '') {
								setIsValidPoints(false);
							}
						}}
						onChange={(e) => {
							setCurrentQuestion((prevCurrentQuestion) => ({
								...prevCurrentQuestion,
								points: e.target.value === '' ? '' : Math.max(e.target.value, 0)
							})
						)}}
						value={currentQuestion.points}
						required
						error={currentQuestion.points === ''}
						helperText={currentQuestion.points === '' ? '* Points is required' : ''}
						color={currentQuestion.points === '' ? 'error' : 'primary'}
					/>
				</ThemeProvider>
			</div>

			<RTE 
				question={currentQuestion} 
				setQuestion={setCurrentQuestion} 
				nextBlankId={nextBlankId} 
				setNextBlankId={setNextBlankId} 
				isValid={isValidStatement} 
				setIsValid={(newStatement) => {
					setIsValidStatement(newStatement !== ''); 
					setIsValidFITBQuestion(Array.from(newStatement.matchAll(/id="Blank-[0-9]*/g)).length !== 0);
				}}
				isValidFITBQuestion={isValidFITBQuestion}
				onBlur={() => {
					if (currentQuestion.statement === '') {
						setIsValidStatement(false);
					}

					if (currentQuestion.type === 'FITB') {
						if (Array.from(currentQuestion.statement.matchAll(/id="Blank-[0-9]*/g)).length === 0) {
							setIsValidFITBQuestion(false);
						}
					}
				}}
			/>

			{
				(currentQuestion.type === "TF") ? (
					<>
					<div id='mark-correct-answers'>
							Mark the correct option
							{
								!isValidCorrectAnswers &&
								<FormHelperText
									error
								>
									* One option is required to be marked as correct
								</FormHelperText>
							}
						</div>
						<div className={`${styles['question-options']}`}>
							<NewOption contentEditable={false} setIsValid={setIsValidCorrectAnswers} isValid={isValidCorrectAnswers} onClick={handleFillInTheBlanksCorrectOptionChoice} handleChange={handleFillInTheBlanksCorrectOptionChoice} selected={currentQuestion.correctAnswers.filter(correctAnswer => correctAnswer.id === 1).length} isNew={false} option={{id: 1, optionStatement: 'True'}} />
							<NewOption contentEditable={false} setIsValid={setIsValidCorrectAnswers} isValid={isValidCorrectAnswers} onClick={handleFillInTheBlanksCorrectOptionChoice} handleChange={handleFillInTheBlanksCorrectOptionChoice} selected={currentQuestion.correctAnswers.filter(correctAnswer => correctAnswer.id === 2).length} isNew={false} option={{id: 2, optionStatement: 'False'}} />
						</div>
					</>
				) : ((currentQuestion.type === "MCQ") ? (
					<>
						<div id='mark-correct-answers'>
							Add options and mark the correct ones
							{
								!isValidCorrectAnswers &&
								<FormHelperText
									error
								>
									* At least one option is required to be marked as correct
								</FormHelperText>
							}
						</div>
						
						<div className={`${styles['question-options']}`}>
							{
								currentQuestion.options.map((option, i) => (
									<NewOption 
										contentEditable={true} 
										onClick={handleMultipleChoiceQuestionOptionChoice} 
										handleChange={handleMultipleChoiceQuestionOptionChoice} 
										selected={currentQuestion.correctAnswers.filter(correctAnswer => correctAnswer.id === option.id).length} 
										isNew={false} 
										option={option} 
										onChange={(val) => {
											setCurrentQuestion((prevCurrentQuestion) => ({
												...prevCurrentQuestion,
												options: prevCurrentQuestion.options.map((optionObj)=>({
													...optionObj,
													optionStatement: (optionObj.id === option.id) ? (val) : (optionObj.optionStatement)
												}))
											}))
										}}
										onBlur={(val) => {
											setCurrentQuestion((prevCurrentQuestion) => ({
												...prevCurrentQuestion,
												options: prevCurrentQuestion.options.map((optionObj)=>({
													...optionObj,
													optionStatement: (optionObj.id === option.id) ? (val) : (optionObj.optionStatement)
												}))
											}))
										}}
										deleteOption={deleteOption}
										deletable={currentQuestion.options.length > 1}
										isValid={isValidCorrectAnswers}
										setIsValid={setIsValidCorrectAnswers}
									/>
								))
							}
							<div 
								className={`${styles['add-option-button']}`}
								onClick={()=>{
									setCurrentQuestion((prevCurrentQuestion) => ({
										...prevCurrentQuestion,
										options: [...prevCurrentQuestion.options, {
											id: prevCurrentQuestion.options.length === 0 ? 1 : prevCurrentQuestion.options[prevCurrentQuestion.options.length - 1].id + 1,
											optionStatement: 'Option'
										}]
									}))
								}}	
							>
								<AddIcon color="primary" fontSize="medium" />
								Add an Option
							</div>
						</div>
					</>
				) : ((currentQuestion.type === "FITB") ? (
					
					isValidFITBQuestion && (
					<>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								gap: '1em'
							}} 
							className={`${styles['to-col']}`}
						>
							<div
								style={{
									marginTop: '0.7em'
								}}
							>
								Add possible answer for
							</div>
							<FormControl sx={{ minWidth: 150 }} variant="standard">
								<InputLabel id="select-blank-id-label">Blank Id</InputLabel>
								<Select
									labelId="select-blank-id-label"
									id="select-blank-id"
									value={selectedBlankId}
									label="Blank Id"
									onChange={(e) => {
										setIsValidSelectedBlankId(true)
										setIsValidCorrectAnswersForBlank(true)
										setSelectedBlankId(e.target.value)
									}}
									autoWidth
									error={!isValidCorrectAnswersForBlank || !isValidSelectedBlankId}
									color={(isValidCorrectAnswersForBlank && isValidSelectedBlankId) ? 'primary' : 'error'}
								>
									{
										currentQuestion.correctAnswers.map((correctAnswer)=>(
											<MenuItem value={correctAnswer.id}>{correctAnswer.id}</MenuItem>
										))
									}
								</Select>
								{!isValidSelectedBlankId && <FormHelperText error>* Select a Blank Id</FormHelperText>}
							</FormControl>
						</div>
						{
							(((currentQuestion.correctAnswers.filter(correctAnswer => correctAnswer.id === selectedBlankId))[0])?.correctAnswers.length > 0) &&
							<div
								style={{
									display: 'flex',
									gap: '0.5em',
									flexWrap: 'wrap'
								}} 
							>
								{
									((currentQuestion.correctAnswers.filter(correctAnswer => correctAnswer.id === selectedBlankId))[0])?.correctAnswers.map((correctAnswer, i) => (
										<Chip 
											label={correctAnswer}
											onDelete={() => {
												setCurrentQuestion(prevCurrentQuestion => ({
													...prevCurrentQuestion,
													correctAnswers: prevCurrentQuestion.correctAnswers.map((prevCorrectAnswer) => {
														if (prevCorrectAnswer.id === selectedBlankId) {
															return ({
																...prevCorrectAnswer,
																correctAnswers: prevCorrectAnswer.correctAnswers.filter((ans, j) => (i !== j))
															})
														} else {
															return prevCorrectAnswer
														}
													})
												}))
											}} 
										/>
									))
								}
							</div>
						}
						<div style={{width: '100%'}}>
							<div className={`${styles['row']} ${styles['to-col']}`} style={{gap: '1em'}}>
								<TextField
									fullWidth
									label="Correct Answer"
									onChange={(e) => {
										setIsCurrentCorrectAnswerValid(true)
										setCurrentCorrectAnswer(e.target.value)
									}}
									value={currentCorrectAnswer}
									error={!isValidCorrectAnswersForBlank || !isCurrentCorrectAnswerValid}
									color={(isValidCorrectAnswersForBlank && isCurrentCorrectAnswerValid) ? 'primary' : 'error'}
								/>

								<Button
									variant='contained'
									className={`btn btn-primary ${styles['add-possible-answer-btn']}`}
									color={isValidCorrectAnswersForBlank ? 'primary' : 'error'}
									onClick={() => {
										if (currentCorrectAnswer === '') {
											setIsCurrentCorrectAnswerValid(false);
										} else if (selectedBlankId === '') {
											setIsValidSelectedBlankId(false);
										} else {
											setIsValidCorrectAnswersForBlank(true);
											setIsCurrentCorrectAnswerValid(true);
											setCurrentQuestion((prevCurrentQuestion) => ({
												...prevCurrentQuestion,
												correctAnswers: prevCurrentQuestion.correctAnswers.map((correctAnswer)=>{
													if (correctAnswer.id === selectedBlankId) {
														return ({
															...correctAnswer,
															correctAnswers: [...correctAnswer.correctAnswers, currentCorrectAnswer]
														})
													} else {
														return correctAnswer
													}
												})
											}))
											setCurrentCorrectAnswer('')
										}
									}}
								> 
									Add
								</Button>
							</div>
							{
								!isValidCorrectAnswersForBlank && 
								<FormHelperText
									error
								>
									* At least one correct answer required for this blank 
								</FormHelperText>
							}
							{
								!isCurrentCorrectAnswerValid &&
								<FormHelperText
									error
								>
									* Possible answer cannot be empty 
								</FormHelperText> 
							}
						</div>
						
					</>)
				) : (((currentQuestion.type === "SAT")) ? (
					<>
						<div className={`${styles['flex-space-between']}`}> 
							Enter a model answer
							<Tooltip title="Generate Model Answer using AI">
								<Button
									variant='contained'
									onClick={generateModelAnswer}
								>
									<AutoAwesomeIcon />
								</Button> 
							</Tooltip>
						</div>
						<TextField
							fullWidth
							label="Model Answer"
							onBlur={(e) => {
								setCurrentQuestion((prevCurrentQuestion) => ({
									...prevCurrentQuestion,
									correctAnswers: [{
										id: 1,
										correctAnswers: [
											e.target.value
										]
									}]
								})
							)}}
							onChange={(e) => {
								setCurrentQuestion((prevCurrentQuestion) => ({
									...prevCurrentQuestion,
									correctAnswers: [{
										id: 1,
										correctAnswers: [
											e.target.value
										]
									}]
								})
							)}}
							value={currentQuestion.correctAnswers[0].correctAnswers[0]}
							multiline
							rows={3}	
						/>
					</>
				) : (
					<>
						Choose the type of Question
					</>
				))))
			}

			<TextField
				fullWidth
				label="Explanation"
				onBlur={(e) => {
					setCurrentQuestion((prevCurrentQuestion) => ({
						...prevCurrentQuestion,
						explanation: e.target.value
					})
				)}}
				onChange={(e) => {
					setCurrentQuestion((prevCurrentQuestion) => ({
						...prevCurrentQuestion,
						explanation: e.target.value
					})
				)}}
				value={currentQuestion.explanation}
				multiline
				rows={3}	
			/>

			<Button
				variant='contained'
				className={`btn btn-primary ${styles['add-to-course-btn']}`}
				onClick={() => {
					if (validateQuestion()) {
						addQuestion();
					}
				}}
			> 
				{isNew ? "Add" : "Save"} 
			</Button>
		</>
	)
}

export default NewQuestion
