export const GET_QUIZ_CATEGORIES_REQUEST = 'GET_QUIZ_CATEGORIES_REQUEST'
export const GET_QUIZ_CATEGORIES_SUCCESS = 'GET_QUIZ_CATEGORIES_SUCCESS'
export const GET_QUIZ_CATEGORIES_FAILURE = 'GET_QUIZ_CATEGORIES_FAILURE'

export const ADD_QUIZ_CATEGORY_REQUEST = 'ADD_QUIZ_CATEGORY_REQUEST'
export const ADD_QUIZ_CATEGORY_SUCCESS = 'ADD_QUIZ_CATEGORY_SUCCESS'
export const ADD_QUIZ_CATEGORY_FAILURE = 'ADD_QUIZ_CATEGORY_FAILURE'

export const EDIT_QUIZ_CATEGORY_REQUEST = 'EDIT_QUIZ_CATEGORY_REQUEST'
export const EDIT_QUIZ_CATEGORY_SUCCESS = 'EDIT_QUIZ_CATEGORY_SUCCESS'
export const EDIT_QUIZ_CATEGORY_FAILURE = 'EDIT_QUIZ_CATEGORY_FAILURE'

export const DELETE_QUIZ_CATEGORY_REQUEST = 'DELETE_QUIZ_CATEGORY_REQUEST'
export const DELETE_QUIZ_CATEGORY_SUCCESS = 'DELETE_QUIZ_CATEGORY_SUCCESS'
export const DELETE_QUIZ_CATEGORY_FAILURE = 'DELETE_QUIZ_CATEGORY_FAILURE'
