/*eslint-disable*/
import React from "react";
import styles from './Quiz.module.css'
import { TextField } from "@mui/material";

function Question ({question, questionsCount, questionIndex, setQuestionIndex, results, setResults}) {
    const handleOnPrevClick = () => {
		setQuestionIndex((prevQuestionIndex) => (
			Math.max(0, prevQuestionIndex - 1)
		))
	}

	const handleOnNextClick = () => {
		setQuestionIndex((prevQuestionIndex) => (
			Math.min(questionsCount - 1, prevQuestionIndex + 1)
		))
	}

	const handleOnOptionClick = (option) => {
		if (question.type === 'MCQ') {
			setResults(prevResults => (
				prevResults.map(prevResultsObj => {
					if (prevResultsObj.id === question.id) {
						if (prevResultsObj.selectedAnswers.includes(option)) {
							return {
								...prevResultsObj,
								selectedAnswers: prevResultsObj.selectedAnswers.filter(prevSelectedAnswer => prevSelectedAnswer != option),
								isAttempted: (prevResultsObj.selectedAnswers.filter(prevSelectedAnswer => prevSelectedAnswer != option)).length !== 0
							}
						} else {
							return {
								...prevResultsObj,
								selectedAnswers: [...prevResultsObj.selectedAnswers, option],
								isAttempted: ([...prevResultsObj.selectedAnswers, option]).length !== 0
							}
						}
					} else {
						return prevResultsObj
					}
				})
			))
		} else if (question.type === 'TF') {
			setResults(prevResults => (
				prevResults.map(prevResultsObj => {
					if (prevResultsObj.id === question.id) {
						if (prevResultsObj.selectedAnswers.includes(option)) {
							return {
								...prevResultsObj,
								selectedAnswers: [],
								isAttempted: false
							}
						} else {
							return {
								...prevResultsObj,
								selectedAnswers: [option],
								isAttempted: true
							}
						}
					} else {
						return prevResultsObj
					}
				})
			))
		} 
	}

	const handleOnSATInputChange = (e) => {
		setResults(prevResults => (
			prevResults.map(prevResultsObj => {
				if (prevResultsObj.id === question.id) {
					return {
                        ...prevResultsObj,
                        selectedAnswers: [e.target.value],
						isAttempted: e.target.value.length > 0
					}
				} else {
					return prevResultsObj
				}
			})
		))
	}

	const handleOnInputChange = (e, inputIndex) => {
		setResults(prevResults => (
			prevResults.map(prevResultsObj => {
				if (prevResultsObj.id === question.id) {
					var isAttempted = false;
					return {
                        ...prevResultsObj,
                        selectedAnswers: prevResultsObj.selectedAnswers.map((selectedAnswer, i) => {
							if (selectedAnswer.id.substring(6) === e.target.id.substring(6)) {
								isAttempted = isAttempted || e.target.value.length > 0 
								return {
									...selectedAnswer,
									selectedAnswer: e.target.value
								};
							} else {
								isAttempted = isAttempted || selectedAnswer.selectedAnswer.length > 0  
								return selectedAnswer
							}
                    	}),
						isAttempted: isAttempted
					}
				} else {
					return prevResultsObj
				}
			})
		))
	}

    return (
		<div className={`${styles['question']}`}>
			<div className={`${styles['question-header']}`}>
				<h5 
					className={`${styles['question-nav-btn']}`}
					onClick={handleOnPrevClick}
				> 
					&lt; PREV
				</h5>
				<h3 className={`${styles['question-index']}`}>QUESTION {questionIndex + 1}</h3>
				<h5 
					className={`${styles['question-nav-btn']}`}
					onClick={handleOnNextClick}
				> 
					NEXT &gt;
				</h5>
			</div>
			<div className={`${styles['question-header']}`}>
				<h4 
					className={`${styles['question-instruction']}`}
				> 
					{
						question.type === "MCQ" ? (
							"Choose the correct options"
						) : (
							question.type === "TF" ? (
								"Choose true or false"
							) : question.type === "SAT" ? (
								"Short answer"
							) : (
								"Fill in the blanks"
							)
						)
					}
				</h4>
				
				<h4
					className={`${styles['question-points']}`}
				> 
					{question.points} Points
				</h4>
			</div>
			<div dangerouslySetInnerHTML={{__html: (question.statement)}}></div>
			{
				question.type === "FITB" ? (
					<div className={`${styles['blank-inputs']}`}>
						{
							question.correctAnswers.map((correctAnswer, i) => {
								var isNumeric = true;
								correctAnswer.correctAnswers.forEach((correctAnswerStatement) => {
									if (!parseFloat(correctAnswerStatement)) {
										isNumeric = false;
									}
								})
								return <TextField
									id={'input-' + correctAnswer.id.substring(6)}
									label={correctAnswer.id}
									type = {isNumeric ? 'number' : 'text'}
									onBlur={() => {
										(Array.from(document.getElementsByClassName('question-input'))).forEach((blank) => blank.classList.remove('focused'));
										setResults(results);
									}}
									onChange={(e) => {handleOnInputChange(e, i)}}
									value={(results[questionIndex].selectedAnswers.filter(selectedAnswer => selectedAnswer.id === correctAnswer.id))[0].selectedAnswer}
									multiline = {!isNumeric}
									fullWidth
									rows={2}
									className={styles['blank-input']}
									onFocus={() => {
										document.getElementById(correctAnswer.id).classList.add('focused');
									}}
								/>
							})
						}
					</div>
				) : question.type === "SAT" ? (
					<div className={`${styles['blank-inputs']}`}>
						<TextField
							label = "Answer"
							type = 'text'
							onBlur={() => {
								setResults(results);
							}}
							onChange={(e) => {handleOnSATInputChange(e)}}
							value={results[questionIndex].selectedAnswers[0]}
							multiline
							fullWidth
							rows={3}
							className={styles['blank-input']}
							onFocus={() => {
							}}
						/>
					</div>
				) : (
					<div className={`${styles['question-options']}`}>
						{
							question.options.map((option, i) => (
								<div
									key={option.id}
									className={`${styles['question-option']} ${results.filter(selectedAnswersObj => selectedAnswersObj.id === question.id)[0].selectedAnswers.includes(option) ? styles['selected'] : ''}`}
								>
									<div 
										className={`${styles['question-option-front']}`}
										onClick={()=>{handleOnOptionClick(option)}}
									>
										<div> {String.fromCharCode('A'.charCodeAt(0) + i)} </div>
										<div> {option.optionStatement} </div>
									</div>
								</div>
							))
						}
					</div>
				)
			}
		</div>
	)
}

export default Question;