/* eslint-disable */

import React, { useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import EditOffIcon from '@mui/icons-material/EditOff'
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'
// import { decrypt } from '../../../edm-node/utils/encryption'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Modal,
    TextField,
    Typography,
} from '@mui/material'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import {
    achieveTargetGrade,
    courseNameUpdate,
    courseTargetGradeUpdate,
    modifyKnownScores,
    saveCourseGrades,
    setErrorForKey,
    updateCourseGrades,
    deleteCourse,
    courseDescriptionUpdate,
    courseNotesUpdate,
    updateCourseFields,
    updateCourseDetails,
} from '../redux'
import GradeTable from './GradeTable'
import Meters from './commons/Meters'
import Report from './Report'
import {
    addAnchorTagsForLinksInText,
    hasCategoryError,
    roundValueToDecimals,
} from '../utils'
import {
    CATEGORY_WEIGHT_DECIMAL_ACCURACY,
    GRADE_DECIMAL_ACCURACY,
} from '../utils/constants'
import TopLoader from './commons/TopLoader'
import { getCleanCategories } from '../redux/course/helpers/categoryHelper'
import Notification from './commons/Notification'
import { setCourseGrade } from '../redux'

function GradeAnalyzer() {
    const dispatch = useDispatch()
    const history = useHistory()
    const [notificationSeverity, setNotificationSeverity] = useState('success')
    const [showNotification, setShowNotification] = useState(false)
    const [showShareModal, setShowShareModal] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState('')
    const [enableDescriptionEdit, setEnableDescriptionEdit] = useState(false)
    const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false)
    const [enableNotesEdit, setEnableNotesEdit] = useState(false)
    const [isNotesExpanded, setIsNotesExpanded] = useState(false)
    const [newTargetGrade, setNewTargetGrade] = useState('')
    const userData = useSelector((state) => state.userData)
    console.log("Testing user data in grade analyzer");
    console.log(userData);
    const sid = userData && userData.user && userData.user.id;
    const user = userData && userData.user
    const isInstructor =
        userData && userData.user && userData.user.role === 'Instructor'
    const currentCourseData = useSelector((state) => state.currentCourseData)
    console.log("Testing the current course data");
    console.log(currentCourseData.createdBy);
    console.log(sid)
    const isInstructorOfCourse = (sid === currentCourseData.createdBy);
    const errorData = useSelector((state) => state.errorData)
    const { course_id: courseId } = useParams()
    console.log(courseId);
    const isSavedCourse = !!courseId
    useEffect(() => {
        if (currentCourseData.savedCourseId) {
            setNotificationSeverity('success')
            setShowNotification(true)
            setNotificationMessage(`${currentCourseData.courseName} is created`)
            history.push(`/course/${currentCourseData.savedCourseId}`)
        }
    }, [history, currentCourseData.savedCourseId, currentCourseData.courseName])

    useEffect(() => {
        if (currentCourseData.isDeleted) {
            setNotificationSeverity('success')
            setShowNotification(true)
            setNotificationMessage(`${currentCourseData.courseName} is deleted`)
            history.push('/grade-analyzer')
            window.location.reload()
        }
    }, [history, currentCourseData.isDeleted, currentCourseData.courseName])

    useEffect(() => {
        if (currentCourseData.courseOperationError) {
            setNotificationSeverity('error')
            setShowNotification(true)
            setNotificationMessage(currentCourseData.courseOperationError)
        }
    }, [currentCourseData.courseOperationError])

    useEffect(() => {
        if (currentCourseData.successNotificationMessage) {
            setNotificationSeverity('success')
            setShowNotification(true)
            setNotificationMessage(currentCourseData.successNotificationMessage)
        }
    }, [dispatch, currentCourseData.successNotificationMessage])



    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!user || user.id === undefined)
    ) {
        return <Redirect to="/" />
    }

    let {
        courseName,
        courseDescription,
        savedCourseNotes,
        targetGrade,
        gradeMaps = [],
        categories = [],
        achievingTargetGrade = false,
        recommendedGrades = [],
        meterDivisions,
        totalGrades = 0,
        totalCategoriesWeight,
        projectedGrade,
        knownGradesSum,
        knownGradesWeightSum,
        courseOperating,
        courseOperatingMessage,
        maxAchievableGrade,
    } = currentCourseData || {}
    console.log("Testing the assignment");
    console.log(gradeMaps);
    var n = gradeMaps.length;
    useEffect(() => {
        setNewTargetGrade(targetGrade.toString());
    }, [targetGrade]);
    // let targetGradePersonal;

    useEffect(() => {
        for (var i = 0; i < n; i++) {
            console.log(gradeMaps[i].studentId.toString());
            console.log(sid.toString());
            if (gradeMaps[i].studentId.toString() === sid.toString()) {
                // targetGradePersonal = gradeMaps[i].grade;
                setNewTargetGrade(gradeMaps[i].grade);
            }
        }
    }, [gradeMaps]);

    const userLoggedIn = user && user.id !== undefined

    const onCourseChange = (updatedCourse) => {
        let errorMessage = null
        if (updatedCourse.trim().length === 0)
            errorMessage = 'Enter course'
        else if (updatedCourse.length > 40)
            errorMessage = 'Course name too long'
        dispatch(setErrorForKey('course', errorMessage))
        dispatch(courseNameUpdate(updatedCourse))
    }

    const onDescriptionChange = (updatedDescription) => {
        let errorMessage = null
        if (updatedDescription.length > 1024)
            errorMessage = 'Description too long'
        dispatch(setErrorForKey('description', errorMessage))
        dispatch(courseDescriptionUpdate(updatedDescription))
    }

    const onNotesChange = (updatedNotes) => {
        let errorMessage = null
        if (updatedNotes.length > 1024) errorMessage = 'Notes too long'
        dispatch(setErrorForKey('notes', errorMessage))
        dispatch(courseNotesUpdate(updatedNotes))
    }

    const onTargetChange = (updatedTarget) => {
        let errorMessage = null
        if (updatedTarget.length === 0 || Number(updatedTarget) === 0)
            errorMessage = 'Enter target grade'
        else if (updatedTarget <= 0)
            errorMessage = 'Target grade cannot be below 0'
        else if (updatedTarget > 100)
            errorMessage = 'Target grade cannot be above 100'
        dispatch(setErrorForKey('target', errorMessage))
        dispatch(courseTargetGradeUpdate(Number(updatedTarget)))
        setNewTargetGrade(updatedTarget)
    }

    const onCloseNotification = () => {
        setShowNotification(false)
        setNotificationMessage('')
    }

    const handleUpdateGradesClick = () => {
        if (
            // eslint-disable-next-line no-alert
            window.confirm(
                'This will update this saved course. Do you want to continue?'
            )
        ) {
            dispatch(
                updateCourseGrades(
                    courseId,
                    courseName,
                    courseDescription,
                    savedCourseNotes,
                    targetGrade,
                    getCleanCategories(categories),
                    meterDivisions
                )
            )
            console.log("Test updating name")
            const fields = {
                name: courseName,
                description: courseDescription,
                notes: savedCourseNotes,
            }
            dispatch(updateCourseFields(courseId,fields))
            console.log("Testing before setting the grade");
            console.log(targetGrade);
            console.log(courseId);
            console.log(sid);
            setCourseGrade(courseId, sid, targetGrade);
        }
    }

    const handleDeleteGradesClick = () => {
        if (
            // eslint-disable-next-line no-alert
            window.confirm(
                'Are you sure you want to delete this saved course? This action is irreversible.'
            )
        ) {
            dispatch(deleteCourse(courseId))
        }
    }

    const enableSave =
        userLoggedIn &&
        courseName !== '' &&
        !errorData.course &&
        !errorData.target &&
        !errorData.description &&
        !errorData.totalWeights &&
        !courseOperating &&
        categories.reduce((total, ct) => ct.items.length + total, 0) > 0 &&
        !hasCategoryError(errorData.categoriesErrors)
    // && isInstructor
    return (
        <div className="container main-container mb-5">
            {courseOperating ? (
                <TopLoader message={courseOperatingMessage} />
            ) : null}
            <Notification
                message={notificationMessage}
                onClose={onCloseNotification}
                severity={notificationSeverity}
                show={showNotification}
            />
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-4 mt-3">
                        <TextField
                            autoFocus={userLoggedIn}
                            error={!!errorData.course}
                            fullWidth
                            helperText={errorData.course}
                            id="course"
                            label="Course Name"
                            onBlur={() => onCourseChange(courseName)}
                            onChange={(e) => onCourseChange(e.target.value)}
                            value={courseName}
                        />
                    </div>
                    <div className="col-md-4 mt-3">
                        <TextField
                            disabled={achievingTargetGrade}
                            error={!!errorData.target}
                            fullWidth
                            helperText={errorData.target}
                            id="targetGrade"
                            InputProps={{ inputProps: { min: 0, max: 100 } }}
                            label="Target Grade"
                            onChange={(e) => { onTargetChange(e.target.value); }}
                            onWheel={(e) => e.target.blur()}
                            type="number"
                            value={newTargetGrade.toString()}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-12 mt-4">
                <Accordion
                    onClick={() =>
                        setIsDescriptionExpanded(!isDescriptionExpanded)
                    }
                >
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        expandIcon={<ExpandMoreIcon />}
                        id="description-header"
                        sx={{ fontWeight: 'bold' }}
                    >
                        <Typography fontWeight="bold">Description</Typography>
                        {isDescriptionExpanded ? (
                            <IconButton
                                className="pt-0 pb-0 ml-3"
                                onClick={(e) => {
                                    setEnableDescriptionEdit(
                                        !enableDescriptionEdit
                                    )
                                    e.stopPropagation(true)
                                }}
                            >
                                {enableDescriptionEdit ? (
                                    <EditOffIcon />
                                ) : (
                                    <EditIcon />
                                )}
                            </IconButton>
                        ) : null}
                    </AccordionSummary>
                    <AccordionDetails>
                        {enableDescriptionEdit ? (
                            <TextField
                                error={!!errorData.description}
                                fullWidth
                                helperText={`${courseDescription.length}/1024 characters`}
                                id="description-edit"
                                inputProps={{
                                    style: {
                                        padding: 0,
                                    },
                                }}
                                multiline
                                onBlur={() =>
                                    setEnableDescriptionEdit(
                                        !enableDescriptionEdit
                                    )
                                }
                                onChange={(e) =>
                                    onDescriptionChange(e.target.value)
                                }
                                onClick={(e) => e.stopPropagation()}
                                rows={4}
                                value={courseDescription}
                                variant="filled"
                            />
                        ) : (
                            <Typography
                                id="description"
                                onClick={(e) => e.stopPropagation()}
                                style={{ whiteSpace: 'pre-line' }}
                                sx={{
                                    display: '-webkit-box',
                                    overflow: 'scroll',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: 4,
                                    background: 'rgb(247, 247, 247)',
                                    minHeight: '7.8em',
                                    padding: '25px 12px 8px',
                                    border: errorData.description
                                        ? '1px red solid'
                                        : 'none',
                                }}
                                variant="body1"
                            >
                                {addAnchorTagsForLinksInText(courseDescription)}
                            </Typography>
                        )}
                    </AccordionDetails>
                </Accordion>
            </div>
            {isSavedCourse ? (
                <div className="col-md-12 mt-4">
                    <Accordion
                        onClick={() => setIsNotesExpanded(!isNotesExpanded)}
                    >
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            expandIcon={<ExpandMoreIcon />}
                            id="notes-header"
                            sx={{ fontWeight: 'bold' }}
                        >
                            <Typography fontWeight="bold">Notes</Typography>
                            {isNotesExpanded ? (
                                <IconButton
                                    className="pt-0 pb-0 ml-3"
                                    onClick={(e) => {
                                        setEnableNotesEdit(!enableNotesEdit)
                                        e.stopPropagation(true)
                                    }}
                                >
                                    {enableNotesEdit ? (
                                        <EditOffIcon />
                                    ) : (
                                        <EditIcon />
                                    )}
                                </IconButton>
                            ) : null}
                        </AccordionSummary>
                        <AccordionDetails>
                            {enableNotesEdit ? (
                                <TextField
                                    error={!!errorData.notes}
                                    fullWidth
                                    helperText={`${savedCourseNotes.length}/1024 characters`}
                                    id="notes-edit"
                                    inputProps={{
                                        style: {
                                            padding: 0,
                                        },
                                    }}
                                    multiline
                                    onBlur={() =>
                                        setEnableNotesEdit(!enableNotesEdit)
                                    }
                                    onChange={(e) =>
                                        onNotesChange(e.target.value)
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                    rows={4}
                                    value={savedCourseNotes}
                                    variant="filled"
                                />
                            ) : (
                                <Typography
                                    id="notes"
                                    onClick={(e) => e.stopPropagation()}
                                    style={{ whiteSpace: 'pre-line' }}
                                    sx={{
                                        display: '-webkit-box',
                                        overflow: 'scroll',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 4,
                                        background: 'rgb(247, 247, 247)',
                                        minHeight: '7.8em',
                                        padding: '25px 12px 8px',
                                        border: errorData.notes
                                            ? '1px red solid'
                                            : 'none',
                                    }}
                                    variant="body1"
                                >
                                    {addAnchorTagsForLinksInText(
                                        savedCourseNotes
                                    )}
                                </Typography>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </div>
            ) : null}

            <div className="mt-4">
                <div className="col-md-12">
                    <span>
                        <b>
                            Enter known points by clicking the &#39;Points&#39;
                            check box:
                        </b>
                    </span>
                    <GradeTable
                        currentTotalGrade={roundValueToDecimals(
                            achievingTargetGrade ? totalGrades : knownGradesSum,
                            GRADE_DECIMAL_ACCURACY
                        )}
                        totalCategoriesWeight={roundValueToDecimals(
                            totalCategoriesWeight,
                            CATEGORY_WEIGHT_DECIMAL_ACCURACY
                        )}
                    />
                </div>
            </div>
            <div className="row mt-2 ml-3">
                {achievingTargetGrade ? (
                    <button
                        className="btn btn-primary m-2"
                        onClick={() => dispatch(modifyKnownScores())}
                        type="button"
                    >
                        <ArrowBackIosRoundedIcon fontSize="small" />
                        Back
                    </button>
                ) : (
                    <button
                        className="btn btn-primary m-2"
                        onClick={() => dispatch(achieveTargetGrade())}
                        type="submit"
                    >
                        Achieve Target Grade
                    </button>
                )}
            </div>
            <div className="row mt-2 ml-3">
                <Report
                    achievableTargetGrades={recommendedGrades}
                    categories={categories}
                    courseName={courseName}
                    currentGrade={Math.round(knownGradesSum * 100) / 100}
                    disabled={!achievingTargetGrade || !enableSave}
                    knownGradesWeightSum={knownGradesWeightSum}
                    maxAchievableGrade={maxAchievableGrade}
                    meterDivisions={meterDivisions}
                    projectedPercent={projectedGrade}
                    targetPercent={newTargetGrade / 100}
                    username={`${user.firstName} ${user.lastName}`}
                />
                {isSavedCourse ? ((
                    <>
                        <button
                            className="btn btn-primary m-2"
                            disabled={!enableSave}
                            id="saveGrades"
                            onClick={handleUpdateGradesClick}
                            type="button"
                        >
                            Update
                        </button>
                        {isInstructorOfCourse && enableSave ? (<><button
                            className="btn btn-primary m-2"
                            disabled={!enableSave}
                            id="deleteGrades"
                            onClick={handleDeleteGradesClick}
                            type="button"
                        >
                            Delete
                        </button>
                            <button
                                className="btn btn-primary m-2"
                                disabled={!enableSave}
                                id="share"
                                onClick={() => setShowShareModal(true)}
                                type="button"
                            >
                                Share
                            </button>
                        </>) : <div></div>}

                    </>
                )) : (
                    (isInstructor ?
                    <button
                        className="btn btn-primary m-2"
                        disabled={!enableSave}
                        id="saveGrades"
                        onClick={() =>
                            dispatch(
                                saveCourseGrades(
                                    courseName,
                                    courseDescription,
                                    targetGrade,
                                    getCleanCategories(categories),
                                    meterDivisions
                                )
                            )
                        }
                        type="button"
                    >
                        Create Course
                    </button> : null)
                )}

                <Modal
                    aria-describedby="modal-modal-description"
                    aria-labelledby="modal-modal-title"
                    onClose={() => setShowShareModal(false)}
                    open={showShareModal}
                >
                    <Box
                        className="modal-body"
                        sx={{
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                        }}
                    >
                        <Typography
                            component="h2"
                            id="modal-modal-title"
                            variant="h6"
                        >
                            Share this course&#39;s URL
                        </Typography>
                        <div className="row">
                            <button
                                className="mt-2 mr-2 btn"
                                id="saveGrades"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        window.location.href
                                    )
                                    setNotificationSeverity('success')
                                    setShowNotification(true)
                                    setNotificationMessage(
                                        'Course URL copied to clipboard'
                                    )
                                    setShowShareModal(false)
                                }}
                                title="Copy URL"
                                type="button"
                            >
                                <ContentCopyIcon />
                            </button>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                {window.location.href}
                            </Typography>
                        </div>
                    </Box>
                </Modal>
            </div>
            <div className="row mt-5 ml-3" />
            <Meters
                divisions={meterDivisions}
                meter1Label="Predicted"
                meter2Label="Target"
                projectedPercent={projectedGrade}
                targetPercent={newTargetGrade / 100}
            />
        </div>
    )
}

export default GradeAnalyzer
