/*eslint-disable*/
import React, { useState } from 'react';
import { Divider, Fab, Skeleton, Tab, Tabs, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import tabStyles from '../Tab.module.css';
import CreateQuiz from './../../../quiz-new/CreateQuiz';
import EditQuiz from './../../../quiz-new/EditQuiz';

const Quizzes = ({isLoading}) => {
	const [isCreateQuizModalOpen, setIsCreateQuizModalOpen] = useState(false);
	const [isEditQuizModalOpen, setIsEditQuizModalOpen] = useState(false);
	const [editQuizId, setEditQuizId] = useState(-1);

    return (
		<div>
			<div className={`${tabStyles['tab-heading']}`}>
                Quizzes
				<Tooltip title="Add Quiz">
					<Fab aria-label="add" color="primary" disabled={isLoading} onClick={()=>setIsCreateQuizModalOpen(true)}>
						<AddIcon />
					</Fab>
				</Tooltip>
			</div>
            <Divider />
			<CreateQuiz open={isCreateQuizModalOpen} setOpen={setIsCreateQuizModalOpen}/>
            {
				editQuizId !== -1 && <EditQuiz open={isEditQuizModalOpen} setOpen={setIsEditQuizModalOpen} quizId={editQuizId} />
			}
			
		</div>
	)
}

export default Quizzes