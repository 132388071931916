import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <footer 
            className="page-footer"
            style={{
                background: "url(/images/bg-triangles-up-big.svg), linear-gradient(-180deg, #0055a2 20%, #0761b3 100%)"
            }}
        >
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h6 className="text-uppercase font-weight-bold">
                            Additional Information
                        </h6>
                        <p>
                            <i>my</i>
                            Academic is reinventing higher education to improve
                            learning outcomes with active student engagement.
                        </p>
                    </div>
                </div>
                <div className="footer-copyright text-center">
                    <Link
                        data-target=".navbar-collapse.show"
                        data-toggle="collapse"
                        to="/contact-us"
                    >
                        Contact Us
                    </Link>
                    <br />© {new Date().getFullYear()} Copyright: myacademic.org
                </div>
            </div>
        </footer>
    )
}

export default Footer
