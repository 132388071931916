/* eslint-disable */

import axios from 'axios'

import {
    ADD_QUESTIONS,
    DELETE_QUESTIONS,
    EDIT_QUESTIONS,
    FETCH_QUESTIONS,
    ADD_QUESTION_SUCCESS,
    ADD_QUESTION_FAILURE,
} from './questionBankTypes'

export const addQuestion = (questionData) => async (dispatch) => {
    // const {
    //     categoryId,
    //     answerSelectionType,
    //     answers,
    //     concepts,
    //     correctAnswer,
    //     difficulty,
    //     point,
    //     question,
    //     question_type,
    //     questionType,
    // } = questionData
    await axios
        .post(`${process.env.REACT_APP_BASE_URL}/questions`, questionData)
        .then(() => {
            const status = 'Successfully added!'
            dispatch({ type: ADD_QUESTION_SUCCESS, payload: status })
        })
        .catch((err) => {
            const error = 'Failed to add question. Please try again later.'
            dispatch({ type: ADD_QUESTION_FAILURE, payload: error })
        })
}

export const deleteQuestion = (ques) => ({
    type: DELETE_QUESTIONS,
    payload: ques,
})

export const editQuestion = (errorMsg) => ({
    type: EDIT_QUESTIONS,
    payload: errorMsg,
})

export const getAllQuestions = (ques) => ({
    type: FETCH_QUESTIONS,
    payload: ques,
})

export const fetchQuestions = (categoryId, limit, keyword) => (dispatch) => {
    axios
        .get(
            `${process.env.REACT_APP_BASE_URL}/questions?category=${categoryId}&limit=${limit}&keyword=${keyword}`
        )
        .then((response) => {
            // dispatch({
            //     type: FETCH_QUESTIONS,
            //     payload: response.data,
            // });
            const questions = response.data
            console.log(questions)
            if (questions) {
                dispatch(getAllQuestions(questions))
            }
        })
        .catch((error) => {
            console.error(error)
        })
}

export const fetchAllQuestions = () => (dispatch) => {
    axios
        .get(
            `${process.env.REACT_APP_BASE_URL}/all-questions`
        )
        .then((response) => {
            // dispatch({
            //     type: FETCH_QUESTIONS,
            //     payload: response.data,
            // });
            const questions = response.data
            // console.log(questions)
            if (questions) {
                dispatch(getAllQuestions(questions))
            }
        })
        .catch((error) => {
            console.error(error)
        })
}

export const removeQuestion = (questionId) => (dispatch) => {
    return axios
        .delete(
            `${process.env.REACT_APP_BASE_URL}/questions/${questionId}`
        )
        .then((response) => {
            // dispatch({
            //     type: FETCH_QUESTIONS,
            //     payload: response.data,
            // });
            const questions = response.data
            // console.log(questions)
            if (questions) {
                dispatch(deleteQuestion(questions))
            }
        })
        .catch((error) => {
            console.error(error)
        })
}

export const updateQuestion = (questionId, question) => async (dispatch) => {
    return axios
        .put(
            `${process.env.REACT_APP_BASE_URL}/questions/${questionId}`, question
        )
        .then((response) => {
            const questions = response.data
            // console.log(questions)
            if (questions) {
                dispatch(editQuestion(questions))
            }
        })
        .catch((error) => {
            console.error(error)
        })
}