/*eslint-disable*/

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { emails, password } from './TuringUsers' // contains the emails of users who can access turing website and the common password
import Metrics from '../utils/Metrics'

import LoginPrompt from './commons/LoginPrompt'
import styles from '../css/LandingPage.module.css'

function LandingPage() {
    const history = useHistory()
    const dispatch = useDispatch()
    const [showLoginDialog, setShowLoginDialog] = useState(false)
    
    const userData = useSelector((state) => state.userData)
    const isFetchingUser = userData && userData.loading
    
    const isAdminAccount = userData && userData.user && userData.user.email === process.env.REACT_APP_ADMIN_EMAIL_ADDRESS;
    
    const timeNow = new Date().getHours()
    let x = 0;
    const userLoggedIn =
        userData && userData.user && userData.user.id !== undefined
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        const clearLocalStorage = () => {
            localStorage.clear();
        };

        window.addEventListener('unload', clearLocalStorage);

        return () => {
            window.removeEventListener('unload', clearLocalStorage);
        };
    }, []);
    const goToCareerPlanning = (e) => {
        Metrics.event({
            category: 'button',
            action: 'click',
            label: 'go to careers',
        })
        e.preventDefault()
        history.push('/careers')
    }

    const goToGradeAnalyzerIfLoggedIn = (e) => {
        e.preventDefault()
        if (!isFetchingUser && userLoggedIn) {
            history.push('/grade-analyzer')
        } else {
            setShowLoginDialog(true)
        }
    }
    /*eslint-disable*/

    


    /*eslint-disable*/
    
    const toggleBool = () => {
        dispatch({ type: 'TOGGLE_BOOL' });
      };
    
    function handleValidationSubmit() {
        for (const email of emails) {
            if (valemail === email) {
                x = 1;
                break;
            }
        }

        if (valPass === password && x === 1) {
            setisTuringuserValidated(true);
            toggleBool();
            localStorage.setItem('isUserValidated', 'true');
            return;
        } else {
            x = 0;
            alert("Wrong mail id or password. Try Again");
        }
    }

    
        return (
            <div className="main-container">
                <LoginPrompt
                    history={history}
                    message="To access this page, you need to login. Proceed?"
                    onClose={() => setShowLoginDialog(false)}
                    open={showLoginDialog}
                />

                <header
                    className="page-header header container-fluid"
                    style={{ backgroundImage: 'url("images/book.jpg")' }}
                >
                    <div className="description">
                        <h1
                            className="mt-5"
                            style={{ color: 'rgba(37, 39, 37, 0.918)' }}
                        >
                            {timeNow >= 5 && timeNow <= 12
                                ? `Good Morning, `
                                : timeNow >= 12 && timeNow < 18
                                    ? `Good Afternoon, `
                                    : `Good evening, `}
                            {userData && userData.user && userData.user.id
                                ? `${userData.user.firstName}`
                                : null}{' '}
                            {userData &&
                                userData.user &&
                                userData.user.id &&
                                isAdminAccount
                                ? null
                                : null}
                        </h1>

                        {userData &&
                            userData.user &&
                            userData.user.id &&
                            isAdminAccount ? (
                            <h3 className="mt-4" style={{ color: '#0055A2' }}>
                                This is your admin page
                            </h3>
                        ) : (
                            <h3 className="mt-4" style={{ color: '#0055A2' }}>
                                <span>
                                    Welcome to <i>my</i>Academic
                                </span>
                            </h3>
                        )}

                        <button
                            className="btn btn-primary btn-lg mt-5"
                            onClick={() => {
                                window.location.href = '#Academics'
                            }}
                            type="button"
                        >
                            Learn More
                        </button>
                    </div>
                </header>

                {(userData &&
                    userData.user &&
                    userData.user.id &&
                    isAdminAccount) || (
                        <div className={`container`}>
                            <div
                                className={`row ${styles['features']}`}
                                id="Academics"
                            >
                                <div
                                    id="career-planning-feature"
                                    onClick={goToCareerPlanning}
                                    type="button"
                                    className={`${styles['feature-card']} ${styles['career-planning-feature']}`}
                                >
                                    <div
                                        className={`${styles['feature-card-content']}`}
                                    >
                                        <h3
                                            className={`${styles['feature-card-title']}`}
                                        >
                                            Career Planning
                                        </h3>
                                        {/* <div className="feature-image-container">
                                            <img
                                                alt="Career staircase"
                                                className="img-fluid"
                                                src="images/stairs-5957112_1920_Copy.jpg"
                                            />
                                        </div> */}
                                        <ul
                                            className={`${styles['feature-card-desc']}`}
                                        >
                                            <li>Explore career options</li>
                                            <li>Plan future course of study</li>
                                            <li>Learn about job opportunities</li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    id="academic-goals-feature"
                                    onClick={goToGradeAnalyzerIfLoggedIn}
                                    type="button"
                                    className={`${styles['feature-card']} ${styles['academic-goals-feature']}`}
                                >
                                    <div
                                        className={`${styles['feature-card-content']}`}
                                    >
                                        <h3
                                            className={`${styles['feature-card-title']}`}
                                        >
                                            Achieve Academic Goals
                                        </h3>
                                        {/* <div className="feature-image-container">
                                            <img
                                                alt="Ecstatic student"
                                                className="img-fluid"
                                                src="images/student-4311761_1920.jpg"
                                            />
                                        </div> */}
                                        <ul
                                            className={`${styles['feature-card-desc']}`}
                                        >
                                            <li>Set target goals</li>
                                            <li>Prepare for tests and quizzes with confidence</li>
                                            <li>Optimize and boost grades</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        )
    
    
}

export default LandingPage
/*eslint-disable*/
