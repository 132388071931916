import {
    CANVAS_MODULES_META_FAILURE,
    CANVAS_MODULES_META_REQUEST,
    CANVAS_MODULES_META_SUCCESS,
    CANVAS_MODULE_ITEMS_META_REQUEST,
    CANVAS_MODULE_ITEMS_META_SUCCESS,
    CANVAS_MODULE_ITEMS_META_FAILURE,
} from './canvasModulesCategoryTypes'

const initialState = {
    loading: false,
    loadingMessage: '',
    fetchSuccess: false,
    modulesData: [],
    message: '',
    error: '',
}

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case CANVAS_MODULES_META_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Fetching modules data...',
                fetchSuccess: false,
                modulesData: {},
                message: '',
                error: '',
            }
        case CANVAS_MODULES_META_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: true,
                modulesData: action.payload,
                message: '',
                error: '',
            }
        case CANVAS_MODULES_META_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: false,
                modulesData: {},
                message: '',
                error: action.payload,
            }
        case CANVAS_MODULE_ITEMS_META_REQUEST:
            return {
                ...state,
                loading: true,
                loadingMessage: 'Fetching module Items data...',
                fetchSuccess: false,
                moduleItemsData: {},
                message: '',
                error: '',
            }
        case CANVAS_MODULE_ITEMS_META_SUCCESS:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: true,
                moduleItemsData: action.payload,
                message: '',
                error: '',
            }
        case CANVAS_MODULE_ITEMS_META_FAILURE:
            return {
                ...state,
                loading: false,
                loadingMessage: '',
                fetchSuccess: false,
                moduleItemsData: {},
                message: '',
                error: action.payload,
            }
        default:
            return state
    }
}

export default reducer
