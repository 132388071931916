import axios from 'axios'
import {
    CANVAS_QUIZ_META_FAILURE,
    CANVAS_QUIZ_META_REQUEST,
    CANVAS_QUIZ_META_SUCCESS,
} from './canvasQuizTypes'

export const quizDataRequest = () => ({
    type: CANVAS_QUIZ_META_REQUEST,
})

export const quizDataSuccess = (quizData) => ({
    type: CANVAS_QUIZ_META_SUCCESS,
    payload: quizData,
})

export const quizDataFailure = (errorMsg) => ({
    type: CANVAS_QUIZ_META_FAILURE,
    payload: errorMsg,
})

export const fetchquizData = (courseId) => (dispatch) => {
    console.log(courseId)
    dispatch(quizDataRequest())
    const url = `${process.env.REACT_APP_BASE_URL}/canvas/courses/${courseId}/quiz`
    // if (courseId && courseId.trim().length > 0) {
    //     url += `?courseId=${courseId}/quiz`
    // }
    console.log(url)
    axios
        .get(url)
        .then((res) => {
            const quizData = res.data
            if (!quizData) {
                dispatch(quizDataFailure('No module/quiz available'))
            } else {
                dispatch(quizDataSuccess(quizData))
            }
        })
        .catch((err) => {
            dispatch(
                quizDataFailure(
                    (err.response && err.response.data) ||
                        'Error fetching quiz data from canvas'
                )
            )
        })
}

// export const fetchSingleCourse = () => (dispatch) => {
//     dispatch(canvasDataRequest())
//     axios
//         .get(`${process.env.REACT_APP_BASE_URL}/canvas/courses`)
//         .then((res) => {
//             const canvasData = res.data
//             if (!canvasData) {
//                 dispatch(canvasDataFailure('No courses available'))
//             } else {
//                 dispatch(canvasDataSuccess(canvasData))
//             }
//         })
//         .catch((err) => {
//             dispatch(
//                 canvasDataFailure(
//                     (err.response && err.response.data) ||
//                         'Error fetching courses from canvas'
//                 )
//             )
//         })
// }
// export const saveQuizCategory = (category) => (dispatch) => {
//     dispatch(canvasDataRequest())
//     axios({
//         method: 'post',
//         url: `${process.env.REACT_APP_BASE_URL}/quiz/category`,
//         data: { category },
//         headers: {
//             'Content-Type': 'application/json',
//         },
//     })
//         .then((response) => {
//             const quizCategory = response.data
//             dispatch(canvasDataSuccess(quizCategory))
//         })
//         .catch((err) => {
//             dispatch(
//                 canvasDataFailure(
//                     (err.response && err.response.data) ||
//                         'Error adding quiz category'
//                 )
//             )
//         })
// }
