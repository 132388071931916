/* eslint-disable */

import { React } from 'react'

// import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'
import EmailIcon from '@mui/icons-material/Email'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import GitHubIcon from '@mui/icons-material/GitHub'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function StudentCard({ student }) {
    const se = 'mailto:' + student.email
    return (
        <Card
            style={{
                width: '18rem',
                position: 'absolute',
                zIndex: '999',
                background: 'white',
                boxShadow: '0px 0px 0.5px 0.5px grey',
            }}
        >
            {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
            <Card.Body>
                <Card.Title style={{ color: 'black' }}>
                    {student.name}
                </Card.Title>
                <hr style={{ width: '2000' }}></hr>
                {student.role == 'Student' ? (
                    <Card.Text style={{ color: 'grey' }}>
                        I am a student of this course. Click on the links below
                        to contact me.
                    </Card.Text>
                ) : (
                    <Card.Text style={{ color: 'grey' }}>
                        I am an instructor of the course. Click on the below
                        links to contact me, send your doubts here as well.
                    </Card.Text>
                )}
                <Container>
                    <Row>
                        <Col>
                            {student.email ? (
                                <a
                                    href={se}
                                    style={{ color: 'red', textAlign: 'left' }}
                                >
                                    <EmailIcon />
                                </a>
                            ) : (
                                ''
                            )}
                        </Col>
                        <Col>
                            <a href="" style={{ textAlign: 'right' }}>
                                <LinkedInIcon />
                            </a>
                        </Col>
                    </Row>
                </Container>
                {/* <a href = '' style = {{color: 'black'}}><GitHubIcon/></a> */}
                {/* <Button variant="primary">Go somewhere</Button> */}
            </Card.Body>
        </Card>
    )
}

export default StudentCard

// StudentCard.propTypes = {
//     student: PropTypes.string.isRequired,
// }
