/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import {
    Radio,
    Card,
    CardContent,
    DialogActions,
    Typography,
    Grid,
    Stack,
    DialogTitle,
    DialogContent,
    TextField,
    Button,
    Container,
    IconButton,
    TextareaAutosize,
    MenuItem,
    Select,
    Dialog,
    Box,
} from '@mui/material'
import { updateQuiz, fetchSavedCoursesMeta } from '../../redux'
import { useSelector, useDispatch } from 'react-redux'
import TopLoader from '../commons/TopLoader'
import { makeStyles } from '@mui/styles'
import AddIcon from '@mui/icons-material/Add'
import parse from 'html-react-parser'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CloseIcon from '@material-ui/icons/Close'

const useStyles = makeStyles({
    card: {
        border: '1px solid #e0e0e0',
        padding: 0,
    },
    title: {
        fontSize: 20,
        backgroundColor: '#f5f5f5',
        padding: 15,
        margin: 0,
        color: '#333',
    },
    question: {
        fontSize: 20,
        padding: 15,
        margin: 0,
        color: '#333',
    },
    input: {
        display: 'none',
    },
    previewImage: {
        maxWidth: '30%',
        maxHeight: '30%',
    },
    bodyItem: {
        border: '1px solid #e0e0e0',
        padding: 15,
        transition: 'background-color 0.3s ease', // smooth transition
        '&:hover': {
            backgroundColor: '#f0f8ff', // light gray on hover
        },
        margin: 0,
    },
})
const CanvasEditQuiz = ({
    ques,
    quizDetails,
    quizCategories,
    id,
    onRefresh,
}) => {
    // console.log(ques)
    const [selectedFile, setSelectedFile] = useState({})
    const [mountFiles, setMountFiles] = useState({})

    const [open, setOpen] = useState(false)
    // const [quizCategory, setQuizCategory] = useState(quizCategories)
    const [quizTitle, setQuizTitle] = useState(quizDetails.quizTitle)
    const [quizSynopsis, setQuizSynopsis] = useState(quizDetails.quizSynopsis)
    const [edit, setEdit] = useState(0)
    const [questions, setQuestions] = useState(ques)

    useEffect(() => {
        setQuestions(ques)
    }, [ques])
    console.log(questions)

    useEffect(() => {
        const updatedSelectedFile = {}
        ques.forEach((question) => {
            if (question.questionPic) {
                updatedSelectedFile[question._id] = question.questionPic
            }
        })
        setSelectedFile(updatedSelectedFile)
        setMountFiles(updatedSelectedFile)
    }, [ques])

    console.log(selectedFile)
    console.log(mountFiles)

    const handleFileInputChange = (id, event) => {
        console.log(id)
        const file = event.target.files[0]
        setSelectedFile((prevFiles) => ({ ...prevFiles, [id]: file }))
        console.log(selectedFile)
        const formData = new FormData()
        formData.append('file', file)
    }

    const handleDeleteButtonClick = (id) => {
        setSelectedFile((prevFiles) => {
            const updatedFiles = { ...prevFiles }
            delete updatedFiles[id]
            return updatedFiles
        })
        const inputElement = document.getElementById(
            `contained-button-file-${id}`
        )
        if (inputElement) {
            inputElement.value = ''
        }
        const updatedQuestions = questions.map((question) =>
            question._id === id ? { ...question, questionPic: null } : question
        )
        setQuestions(updatedQuestions)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    const handleEdit = (id) => {
        setEdit(id)
    }

    const handleDelete = (id) => {
        const updatedQuestions = questions.filter(
            (question) => question._id !== id
        )
        setQuestions(updatedQuestions)
    }

    const handleUpdate = (id, updatedQuestion) => {
        const hasCorrectAnswer = updatedQuestion.answers.some(
            (answer) => answer.weight === 100
        )

        if (
            !hasCorrectAnswer &&
            updatedQuestion.question_type === 'multiple_choice_question'
        ) {
            alert('Please select at least one correct answer')
            return
        }

        const updatedQuestions = questions.map((question) =>
            question._id === id ? updatedQuestion : question
        )
        setMountFiles((files) => ({ ...selectedFile }))
        setQuestions(updatedQuestions)
        setEdit(0)
    }

    const handleCancel = () => {
        setEdit(0)
        setSelectedFile(mountFiles)
        console.log(selectedFile)
        setQuestions(questions)
    }

    const handleQuestionChange = (id, event) => {
        const updatedQuestions = questions.map((question) =>
            question._id === id
                ? { ...question, question: event.target.value }
                : question
        )
        setQuestions(updatedQuestions)
    }

    const handleAnswerChange = (questionId, answerId, event) => {
        const updatedQuestions = questions.map((question) => {
            if (question._id === questionId) {
                const updatedAnswers = question.answers.map((answer) =>
                    answer._id === answerId
                        ? { ...answer, text: event.target.value }
                        : answer
                )
                return { ...question, answers: updatedAnswers }
            }
            return question
        })
        setQuestions(updatedQuestions)
    }
    const handleCheckboxChange = (questionId, answerId, checked) => {
        const updatedQuestions = questions.map((question) => {
            if (question._id === questionId) {
                const updatedAnswers = question.answers.map((answer) =>
                    answer._id === answerId
                        ? { ...answer, weight: checked ? 100 : 0 }
                        : answer
                )
                return { ...question, answers: updatedAnswers }
            }
            return question
        })
        setQuestions(updatedQuestions)
    }

    const addQuestion = () => {
        const newQuestion = {
            _id: Math.random().toString(36).substr(2, 10),
            question_type: 'multiple_choice_question',
            question:
                'New Question Statement',
            point: 1.0,
            answers: [
                {
                    weight: 100,
                    _id: Math.random().toString(36).substr(2, 9),
                    text: 'Option 1',
                },
                {
                    weight: 0,
                    _id: Math.random().toString(36).substr(2, 9),
                    text: 'Option 2',
                },
                {
                    weight: 0,
                    _id: Math.random().toString(36).substr(2, 9),
                    text: 'Option 3',
                },
                {
                    weight: 0,
                    _id: Math.random().toString(36).substr(2, 9),
                    text: 'Option 4',
                },
            ],
        }
        setQuestions([...questions, newQuestion])
    }
    const handleDeleteAnswer = (questionId, answerId) => {
        const updatedQuestions = questions.map((question) => {
            if (question._id === questionId) {
                const filteredAnswers = question.answers.filter(
                    (answer) => answer._id !== answerId
                )
                return {
                    ...question,
                    answers: filteredAnswers,
                }
            }
            return question
        })
        setQuestions(updatedQuestions)
    }
    const handleAddNewAnswer = (questionId) => {
        const updatedQuestions = questions.map((question) => {
            if (question._id === questionId) {
                const newAnswer = {
                    _id: Math.random().toString(36).substr(2, 9),
                    text: '',
                    weight: 0,
                }
                return {
                    ...question,
                    answers: [...question.answers, newAnswer],
                }
            }
            return question
        })
        setQuestions(updatedQuestions)
    }
    // const handleAnswerTypeChange = (event, question) => {
    //     const updatedQuestions = questions.map((q) =>
    //         q._id === question._id
    //             ? { ...q, question_type: event.target.value }
    //             : q
    //     )
    //     setQuestions(updatedQuestions)
    // }
    const quizQuestions = questions.map((q) => ({
        id: q._id,
        question: q.question,
        questionType: q.questionType,
        questionPic: q.questionPic,
        answerSelectionType:
            q.question_type === 'multiple_choice_question'
                ? 'multiple'
                : 'single',
        answers: q.answers.map((a) => a.text),
        correctAnswer:
            q.question_type === 'multiple_choice_question'
                ? q.answers
                      .map((a, index) => (a.weight === 100 ? index + 1 : -1))
                      .filter((index) => index !== -1)
                : (q.answers.findIndex((a) => a.weight === 100) + 1).toString(),
        point: q.point,
    }))

    const handlePublish = () => {
        const hasCorrectAnswers = questions.every((question) => {
            if (question.question_type === 'multiple_choice_question') {
                return question.answers.some((answer) => answer.weight === 100)
            } else {
                return question.answers.some((answer) => answer.weight === 100)
            }
        })

        if (!hasCorrectAnswers) {
            alert('Please ensure every question has a correct answer.')
            return
        }
        handleOpen()
    }

    const handleSaveAndPublish = (publish) => {
        console.log(questions)

        console.log(quizQuestions)
        const deleted = false
        const newQuiz = {
            quizTitle: quizTitle,
            quizSynopsis: quizSynopsis,
            questions: quizQuestions,
        }
        const filteredMountFiles = Object.fromEntries(
            Object.entries(mountFiles).filter(
                ([key, value]) => typeof value !== 'string'
            )
        )
        console.log(newQuiz, 'hello')
        dispatch(
            updateQuiz({
                id,
                publish,
                deleted,
                newQuiz,
                filteredMountFiles,
            })
        )
        onRefresh()
        handleClose()
        // window.location.href = `/quizzes/${quizCategory}`
    }

    const handleAnswerTypeChange = (event, question) => {
        const confirmed = window.confirm(
            'The selected options and answer will not be saved. Do you want to proceed?'
        )
        if (confirmed) {
            let updatedQuestions = questions.map((q) =>
                q._id === question._id
                    ? { ...q, question_type: event.target.value }
                    : q
            )
            if (
                question.question_type !== 'true_false_question' &&
                event.target.value === 'true_false_question'
            ) {
                const updatedAnswers = question.answers
                    .slice(0, 2)
                    .map((answer, index) => {
                        if (index === 0) {
                            return { ...answer, text: 'True', weight: 100 }
                        } else {
                            return { ...answer, text: 'False', weight: 0 }
                        }
                    })
                updatedQuestions = updatedQuestions.map((q) =>
                    q._id === question._id
                        ? { ...q, answers: updatedAnswers }
                        : q
                )
            } else if (
                question.question_type !== 'fill_in_the_blanks_question' &&
                event.target.value === 'fill_in_the_blanks_question'
            ) {
                const updatedAnswers = question.answers.map(
                    (answer, index) => ({
                        _id: answer._id,
                        text: answer.text,
                        weight: index === 0 ? 100 : 0,
                    })
                )
                updatedQuestions = updatedQuestions.map((q) =>
                    q._id === question._id
                        ? { ...q, answers: updatedAnswers }
                        : q
                )
            }
            if (event.target.value === 'multiple_choice_question') {
                const hasChecked = question.answers.some(
                    (answer) => answer.weight === 100
                )
                if (!hasChecked) {
                    const updatedAnswers = question.answers.map(
                        (answer, index) => ({
                            _id: answer._id,
                            text: answer.text,
                            weight: index === 0 ? 100 : 0,
                        })
                    )
                    updatedQuestions = updatedQuestions.map((q) =>
                        q._id === question._id
                            ? { ...q, answers: updatedAnswers }
                            : q
                    )
                }
            }
            setQuestions(updatedQuestions)
        }
    }

    const handleTrueFalseAnswerChange = (questionId, answerId) => {
        const updatedQuestions = questions.map((question) => {
            if (question._id === questionId) {
                const updatedAnswers = question.answers.map((answer) =>
                    answer._id === answerId
                        ? { ...answer, weight: 100 }
                        : { ...answer, weight: 0 }
                )
                return { ...question, answers: updatedAnswers }
            }
            return question
        })
        setQuestions(updatedQuestions)
    }

    const classes = useStyles()
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const savedCourseData = useSelector((state) => state.savedCourseData)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchSavedCoursesMeta())
            // dispatch(fetchquizData(courseId))
            // dispatch(fetchCanvasData(courseId))
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])

    console.log(savedCourseData)

    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    if (userData.user.role !== 'Instructor') {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {
                        'To view this page, the user must log in as an instructor.'
                    }
                </h4>
            </div>
        )
    }

    return (
        <Stack spacing={30} direction={'row'}>
            <Container maxWidth="lg">
                <Stack spacing={2}>
                    <Stack
                        container
                        spacing={2}
                        paddingRight={2}
                        maxHeight={'100%'}
                        fullWidth
                    >
                        {/* <Stack direction={'row'} spacing={2}>
                            <Button
                                startIcon={<AddIcon />}
                                variant="contained"
                                align="right"
                                sx={{ marginLeft: 'auto' }}
                                onClick={addQuestion}
                            >
                                Add Question
                            </Button>
                            <Button
                                startIcon={<AddIcon />}
                                variant="contained"
                                align="right"
                                sx={{ marginLeft: 'auto' }}
                            >
                                Upload Questions
                            </Button>
                        </Stack> */}
                        {/* <Grid container xs={12}> */}

                        {/* </Grid> */}
                        {questions.map((question, index) =>
                            question.answers.length > 0 ? (
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Grid
                                            container
                                            xs={12}
                                            justifyContent="space-between"
                                            className={classes.title}
                                        >
                                            <Stack
                                                direction={'row'}
                                                spacing={2}
                                            >
                                                <Typography inline variant="h6">
                                                    Question
                                                </Typography>

                                                {edit === question._id ? (
                                                    <Select
                                                        variant="outlined"
                                                        size="small"
                                                        style={{ width: 300 }}
                                                        InputLabelProps={{
                                                            shrink: false,
                                                        }}
                                                        label=""
                                                        labelId="answerType"
                                                        id="answerType"
                                                        value={
                                                            question.question_type
                                                        }
                                                        onChange={(event) =>
                                                            handleAnswerTypeChange(
                                                                event,
                                                                question
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value="multiple_choice_question">
                                                            Multiple Choice
                                                        </MenuItem>
                                                        <MenuItem value="fill_in_the_blanks_question">
                                                            Fill in the Blanks
                                                        </MenuItem>
                                                        <MenuItem value="true_false_question">
                                                            True or False
                                                        </MenuItem>
                                                    </Select>
                                                ) : null}
                                            </Stack>

                                            <Stack
                                                direction={'row'}
                                                spacing={2}
                                            >
                                                <Stack
                                                    marginTop={-1}
                                                    direction={'row'}
                                                    spacing={1}
                                                >
                                                    {edit ===
                                                    question._id ? null : (
                                                        <>
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleEdit(
                                                                        question._id
                                                                    )
                                                                }
                                                                aria-label="edit"
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleDelete(
                                                                        question._id
                                                                    )
                                                                }
                                                                aria-label="delete"
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </>
                                                    )}
                                                </Stack>
                                                {edit ===
                                                question._id ? null : (
                                                    <Typography
                                                        marginTop={1}
                                                        inline
                                                        align="right"
                                                    >
                                                        {question.point} {'pts'}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </Grid>
                                        {edit !== question._id ? (
                                            <Stack
                                                className={classes.question}
                                                direction={'row'}
                                            >
                                                <Typography variant="h6">
                                                    {parse(question.question)}
                                                </Typography>
                                            </Stack>
                                        ) : null}
                                        {!!selectedFile[question._id] &&
                                            edit != question._id && (
                                                <Box
                                                    p={'1rem'}
                                                    style={{
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            typeof selectedFile[
                                                                question._id
                                                            ] === 'string'
                                                                ? selectedFile[
                                                                      question
                                                                          ._id
                                                                  ]
                                                                : URL.createObjectURL(
                                                                      selectedFile[
                                                                          question
                                                                              ._id
                                                                      ]
                                                                  )
                                                        }
                                                        alt="Preview"
                                                        style={{
                                                            maxWidth: '30%',
                                                            maxHeight: '30%',
                                                        }}
                                                    />
                                                </Box>
                                            )}
                                        {edit === question._id ? (
                                            <>
                                                <TextareaAutosize
                                                    aria-label="question"
                                                    placeholder="Question"
                                                    style={{
                                                        width: '100%',
                                                        minHeight: 100,
                                                    }} // Set the width here
                                                    value={question.question}
                                                    onChange={(e) =>
                                                        handleQuestionChange(
                                                            question._id,
                                                            e
                                                        )
                                                    }
                                                />
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems="center"
                                                >
                                                    <Grid item>
                                                        <input
                                                            accept="image/*"
                                                            className={
                                                                classes.input
                                                            }
                                                            id={`contained-button-file-${question._id}`}
                                                            type="file"
                                                            onChange={(e) =>
                                                                handleFileInputChange(
                                                                    question._id,
                                                                    e
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor={`contained-button-file-${question._id}`}
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                component="span"
                                                                startIcon={
                                                                    <PhotoCamera />
                                                                }
                                                            >
                                                                Choose Picture
                                                            </Button>
                                                        </label>
                                                    </Grid>
                                                    {!!selectedFile[
                                                        question._id
                                                    ] && (
                                                        <>
                                                            <Grid item>
                                                                <Typography variant="body1">
                                                                    {
                                                                        selectedFile[
                                                                            question
                                                                                ._id
                                                                        ].name
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    color="error"
                                                                    variant="outlined"
                                                                    aria-label="delete picture"
                                                                    component="span"
                                                                    onClick={() =>
                                                                        handleDeleteButtonClick(
                                                                            question._id
                                                                        )
                                                                    }
                                                                >
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>

                                                {!!selectedFile[
                                                    question._id
                                                ] && (
                                                    <Box
                                                        p={'1rem'}
                                                        style={{
                                                            textAlign: 'left',
                                                        }}
                                                    >
                                                        <img
                                                            src={
                                                                typeof selectedFile[
                                                                    question._id
                                                                ] === 'string'
                                                                    ? selectedFile[
                                                                          question
                                                                              ._id
                                                                      ]
                                                                    : URL.createObjectURL(
                                                                          selectedFile[
                                                                              question
                                                                                  ._id
                                                                          ]
                                                                      )
                                                            }
                                                            alt="Preview"
                                                            style={{
                                                                maxWidth: '30%',
                                                                maxHeight:
                                                                    '30%',
                                                            }}
                                                        />
                                                    </Box>
                                                )}

                                                {question.question_type ===
                                                    'true_false_question' && (
                                                    <div>
                                                        {question.answers.map(
                                                            (answer) => (
                                                                <div
                                                                    key={
                                                                        answer._id
                                                                    }
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            answer.weight ===
                                                                            100
                                                                        }
                                                                        onChange={() =>
                                                                            handleTrueFalseAnswerChange(
                                                                                question._id,
                                                                                answer._id
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            answer.weight ===
                                                                            100
                                                                        }
                                                                    />
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        style={{
                                                                            marginLeft: 10,
                                                                        }}
                                                                    >
                                                                        {
                                                                            answer.text
                                                                        }
                                                                    </Typography>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                                {question.question_type ===
                                                    'multiple_choice_question' && (
                                                    <div>
                                                        {question.answers.map(
                                                            (answer) => (
                                                                <div
                                                                    key={
                                                                        answer._id
                                                                    }
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            answer.weight ===
                                                                            100
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            handleCheckboxChange(
                                                                                question._id,
                                                                                answer._id,
                                                                                e
                                                                                    .target
                                                                                    .checked
                                                                            )
                                                                        }
                                                                    />
                                                                    <TextareaAutosize
                                                                        aria-label="answer"
                                                                        placeholder="Answer"
                                                                        value={
                                                                            answer.text
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            handleAnswerChange(
                                                                                question._id,
                                                                                answer._id,
                                                                                e
                                                                            )
                                                                        }
                                                                        style={{
                                                                            width: '90%',
                                                                            minHeight: 50,
                                                                            marginLeft: 10,
                                                                            marginBottom: 10,
                                                                        }}
                                                                    />
                                                                    {question.question_type !==
                                                                    'true_false_question' ? (
                                                                        <IconButton
                                                                            sx={{
                                                                                justifyContent:
                                                                                    'center',
                                                                                alignItems:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() =>
                                                                                handleDeleteAnswer(
                                                                                    question._id,
                                                                                    answer._id
                                                                                )
                                                                            }
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    ) : null}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                                {question.question_type ===
                                                    'fill_in_the_blanks_question' && (
                                                    <div>
                                                        {question.answers.map(
                                                            (answer, index) => (
                                                                <div
                                                                    key={
                                                                        answer._id
                                                                    }
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <Radio
                                                                        checked={
                                                                            answer.weight ===
                                                                            100
                                                                        }
                                                                        onChange={() => {
                                                                            const updatedAnswers =
                                                                                question.answers.map(
                                                                                    (
                                                                                        ans,
                                                                                        i
                                                                                    ) => ({
                                                                                        ...ans,
                                                                                        weight:
                                                                                            i ===
                                                                                            index
                                                                                                ? 100
                                                                                                : 0,
                                                                                    })
                                                                                )
                                                                            setQuestions(
                                                                                questions.map(
                                                                                    (
                                                                                        q
                                                                                    ) =>
                                                                                        q._id ===
                                                                                        question._id
                                                                                            ? {
                                                                                                  ...q,
                                                                                                  answers:
                                                                                                      updatedAnswers,
                                                                                              }
                                                                                            : q
                                                                                )
                                                                            )
                                                                        }}
                                                                    />
                                                                    <TextareaAutosize
                                                                        aria-label="answer"
                                                                        placeholder="Answer"
                                                                        value={
                                                                            answer.text
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            handleAnswerChange(
                                                                                question._id,
                                                                                answer._id,
                                                                                e
                                                                            )
                                                                        }
                                                                        style={{
                                                                            width: '90%',
                                                                            minHeight: 50,
                                                                            marginLeft: 10,
                                                                            marginBottom: 10,
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                                {question.question_type !==
                                                'true_false_question' ? (
                                                    <Button
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: 'skyBlue',
                                                            float: 'right',
                                                            marginBottom: 4,
                                                            marginTop: 1,
                                                        }}
                                                        onClick={() =>
                                                            handleAddNewAnswer(
                                                                question._id
                                                            )
                                                        }
                                                        variant="link"
                                                        startIcon={<AddIcon />}
                                                    >
                                                        Add New Answer
                                                    </Button>
                                                ) : null}
                                                <br></br>
                                                <br></br>
                                                <label
                                                    style={{
                                                        fontWeight: 'bold',
                                                        textAlign: 'left',
                                                    }}
                                                >
                                                    Points Possible :{'   '}
                                                    <input
                                                        type="number"
                                                        value={question.point}
                                                        onChange={(e) =>
                                                            setQuestions(
                                                                questions.map(
                                                                    (q) =>
                                                                        q._id ===
                                                                        question._id
                                                                            ? {
                                                                                  ...q,
                                                                                  point: e
                                                                                      .target
                                                                                      .value,
                                                                              }
                                                                            : q
                                                                )
                                                            )
                                                        }
                                                    />
                                                </label>
                                                <Stack
                                                    justifyContent={'flex-end'}
                                                    marginTop={-1}
                                                    direction={'row'}
                                                    spacing={1}
                                                >
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() =>
                                                            handleUpdate(
                                                                question._id,
                                                                question
                                                            )
                                                        }
                                                        aria-label="update"
                                                    >
                                                        Update
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={handleCancel}
                                                        aria-label="cancel"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Stack>
                                            </>
                                        ) : (
                                            question.answers.map(
                                                (answer, index) => (
                                                    <div
                                                        className={
                                                            classes.bodyItem
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                flexWrap:
                                                                    'wrap',
                                                            }}
                                                        >
                                                            <Stack
                                                                direction={
                                                                    'row'
                                                                }
                                                                spacing={2}
                                                            >
                                                                {answer.weight ==
                                                                100 ? (
                                                                    <RadioButtonChecked></RadioButtonChecked>
                                                                ) : (
                                                                    <RadioButtonUnchecked></RadioButtonUnchecked>
                                                                )}
                                                                <Typography variant="subtitle2">
                                                                    {
                                                                        answer.text
                                                                    }
                                                                </Typography>
                                                            </Stack>
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        )}
                                    </CardContent>
                                </Card>
                            ) : null
                        )}
                        <Stack
                            direction={'row'}
                            justifyContent={'flex-end'}
                            spacing={2}
                        >
                            <Button
                                startIcon={<AddIcon />}
                                variant="contained"
                                align="right"
                                sx={{ marginLeft: 'auto' }}
                                onClick={addQuestion}
                            >
                                Add Question
                            </Button>
                            <Button variant="contained" onClick={handlePublish}>
                                Next
                            </Button>
                            <Dialog
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="form-dialog-title"
                            >
                                <DialogTitle id="form-dialog-title">
                                    Quiz Details
                                </DialogTitle>
                                <DialogContent>
                                    {/* <TextField
                                        autoFocus
                                        margin="dense"
                                        id="quizCategory"
                                        label="Quiz Category"
                                        type="text"
                                        fullWidth
                                        value={quizCategory}
                                        onChange={(e) =>
                                            setQuizCategory(e.target.value)
                                        }
                                    /> */}
                                    <TextField
                                        margin="dense"
                                        id="quizTitle"
                                        label="Quiz Title"
                                        type="text"
                                        fullWidth
                                        value={quizTitle}
                                        onChange={(e) =>
                                            setQuizTitle(e.target.value)
                                        }
                                    />
                                    <TextField
                                        margin="dense"
                                        id="quizSynopsis"
                                        label="Quiz Synopsis"
                                        type="text"
                                        fullWidth
                                        value={quizSynopsis}
                                        onChange={(e) =>
                                            setQuizSynopsis(e.target.value)
                                        }
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        color="primary"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            handleSaveAndPublish(false)
                                        }
                                        color="primary"
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            handleSaveAndPublish(true)
                                        }
                                        color="primary"
                                    >
                                        Save and Publish
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    )
}

export default CanvasEditQuiz
