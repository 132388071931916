// import Button from 'react-bootstrap/Button';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import Dropdown from 'react-bootstrap/Dropdown';
/* eslint-disable */

import MoreVertIcon from '@mui/icons-material/MoreVert'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import * as React from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { deleteStudent } from '../../../../redux'
import { useDispatch } from 'react-redux'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

const deleteStudentFromCourse = (cid, sid, dispatch) => {
    dispatch(deleteStudent(cid, sid))
    window.location.reload()
}

export default function BasicMenu({ courseId, studentId, email, createdByUser}) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const [deletePermission, setDeletePermission] = React.useState(createdByUser)
    const [deleteClicked, setDeleteClicked] = React.useState(false)
    const dispatch = useDispatch()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        // console.log("Siuu");
        setDeleteClicked(false)
        setAnchorEl(null)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    return (
        <div>
            <button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                style={{ background: 'transparent', border: 'transparent' }}
            >
                <MoreVertIcon />
            </button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem>
                    <Router>
                        <Link
                            to={{
                                pathname: '/display-account/' + email,
                                // pathname: '/myaccount',
                            }}
                        >
                            <a
                                style={{ textDecoration: 'none' }}
                                href=""
                                onClick={() => {
                                    setTimeout(() => {
                                        window.location.reload()
                                    }, 500)
                                }}
                            >
                                Profile
                            </a>
                        </Link>
                    </Router>
                </MenuItem>
                {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                {deletePermission &&
                <MenuItem
                    onClick={() => {
                        setDeleteClicked(true)
                        
                    }}
                >
                    Remove Student
                </MenuItem>}

                {/* <MenuItem onClick={handleClose}>Logout</MenuItem> */}
            </Menu>
            <Modal
                open={deleteClicked}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                    >
                        Warning!!
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Do you want to remove the student permanently from the
                        course?
                    </Typography>
                    <Button
                        onClick={() => {
                            // setDeletePermission(true)
                            
                            deleteStudentFromCourse(
                                courseId,
                                studentId,
                                dispatch
                            )
                            handleClose()
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => {
                            handleClose()
                        }}
                    >
                        No
                    </Button>
                </Box>
            </Modal>
        </div>
    )
}
