/* eslint-disable */

import React, { useState } from 'react'
import {
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Stack,
    Paper,
    Typography,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect } from 'react-router-dom'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

const CategoryManagement = ({ categories, onAdd, onEdit, onDelete }) => {
    const [categoryName, setCategoryName] = useState('')
    const [editCategoryName, setEditCategoryName] = useState('')
    const [selectedCategoryId, setSelectedCategoryId] = useState(null)
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const categoryData = useSelector((state) => state.categoryData)

    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }

    const handleEdit = (categoryId) => {
        const selectedCategory = categories.find(
            (cat) => cat._id === categoryId
        )
        if (selectedCategory) {
            // setCategoryName(selectedCategory.categoryName)
            setEditCategoryName(selectedCategory.categoryName)
            setSelectedCategoryId(categoryId)
        }
    }

    const handleEditSave = () => {
        if (!editCategoryName.trim()) {
            alert('Please enter a valid category name.')
            return
        }
        onEdit(selectedCategoryId, { categoryName: editCategoryName })
        clearForm()
    }

    const handleSave = () => {
        if (!categoryName.trim()) {
            alert('Please enter a valid category name.')
            return
        }
        onAdd(categoryName)
        clearForm()
    }

    const findCategoryName = (categoryId) => {
        const matchingCategory = categoryData.categoryData.find(
            (category) => category._id.toString() == categoryId
        )
        return matchingCategory ? matchingCategory.categoryName : ''
    }

    const handleDelete = (categoryId) => {
        const confirmed = window.confirm(
            `The ${findCategoryName(categoryId)} category will be deleted. Do you want to proceed?`
        )
        if (confirmed){
            onDelete(categoryId)
        }
    }

    const clearForm = () => {
        setCategoryName('')
        setSelectedCategoryId(null)
    }

    return (
        <Paper elevation={3} style={{ marginTop: 20, padding: 20 }}>
            <Stack>
                <Typography fontSize={'1.5rem'} fontWeight={'bold'}>
                    Manage Categories
                </Typography>
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    maxWidth={'50rem'}
                >
                    <TextField
                        label="Category Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={categoryName}
                        onChange={(e) => setCategoryName(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        style={{ marginRight: '10px', width: '20rem' }}
                    >
                        Add Category
                    </Button>
                    {/* Redundant Button */}
                    {/* <Button variant="outlined" onClick={clearForm}>
                        Clear
                    </Button> */}
                </Stack>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '1.2rem',
                                    }}
                                >
                                    Category Name
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '1.2rem',
                                    }}
                                >
                                    Action
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {categories.map((category) => (
                                <TableRow key={category._id}>
                                    <TableCell>
                                        {selectedCategoryId === category._id ? (
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                spacing={1}
                                                maxWidth={'30rem'}
                                            >
                                                <TextField
                                                    label=""
                                                    variant="outlined"
                                                    fullWidth
                                                    margin="normal"
                                                    value={editCategoryName}
                                                    onChange={(e) =>
                                                        setEditCategoryName(
                                                            e.target.value
                                                        )
                                                    }
                                                />

                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleEditSave}
                                                    style={{
                                                        marginRight: '10px',
                                                        width: '20rem',
                                                    }}
                                                >
                                                    Edit Category
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    onClick={clearForm}
                                                >
                                                    Clear
                                                </Button>
                                            </Stack>
                                        ) : (
                                            category.categoryName
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        <IconButton
                                            onClick={() =>
                                                handleEdit(category._id)
                                            }
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            onClick={() =>
                                                handleDelete(category._id)
                                            }
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Paper>
    )
}

export default CategoryManagement
