/*eslint-disable*/
import React, { useRef, useState } from 'react';
import styles from './DragAndDrop.module.css'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DescriptionIcon from '@mui/icons-material/Description';

const DragAndDrop = ({accept, file, handleUpload}) => {
    const fileInputRef = useRef(null);
    const [draggedOver, setDraggedOver] = useState(false);
    const draggableStyles = {
        backgroundColor: draggedOver ? '#d5eaff' : 'transparent'
    };

    const handleDrop = (e)=>{
        e.preventDefault();
        console.log(e)
        setDraggedOver(false);
        if (e.dataTransfer.items) {
            [...e.dataTransfer.items].forEach((item, i) => {
            if (item.kind === "file") {
                const file = item.getAsFile();
                handleUpload(file);
            }
            });
        } else {
            [...e.dataTransfer.files].forEach((file, i) => {
                handleUpload(file);
            });
        }
    }

    const browseFiles = () => {
        document.getElementById('file-input').click();
    }

    return (
        <div 
            style={draggableStyles}
            className={`${styles['droppable-area']}`}
            onDrop={handleDrop} 
            onDragOver={(e)=>{
                e.preventDefault();
                setDraggedOver(true);
            }}
            onDragLeave={(e)=>{
                e.preventDefault();
                setDraggedOver(false);
            }}
        >
            <UploadFileIcon
                sx={{
                    fontSize: "3em"
                }}
            />
            <h4>{`${draggedOver ? '' : 'Drag and'} Drop File`} </h4>
            <h6>or <span className={`${styles['browse-files-link']}`} onClick={browseFiles}>browse files</span> from device</h6>
            <h6 className={`${styles['supported-extenstions']}`}>Supports {accept}</h6>
            {   
                file && (
                    <>
                        <h6 className={`${styles['file-name']}`}> <DescriptionIcon /> '{file.name}' uploaded</h6>
                    </>

                )
            }
            <input 
                ref={fileInputRef}
                id="file-input" 
                onChange={() => {
                    const file = fileInputRef.current.files[0];
                    handleUpload(file); 
                }} 
                type='file' 
                accept={accept}
                hidden
            />
        </div>
    )
}

export default DragAndDrop