/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Divider, Fab, Skeleton, Tab, Tabs, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import tabStyles from '../Tab.module.css';
import styles from './Announcements.module.css';
import AnnouncementThumbnail from './AnnouncementThumbnail';

const Announcements = ({isLoading}) => {
	const [isAnnouncementsLoading, setIsAnnouncementsLoading] = useState(true);
	const [announcements, setAnnouncements] = useState([{}, {}, {}]);

	const getAnnouncements = () => {
		setTimeout(() => {
			setAnnouncements(
				[{
					title: "Announcement Posted!",
					description: `Conceptual modelling tools export both semantic information and visual/positional data of
									diagrammatic elements. For model-driven software engineering, as well as interoperability 
									between diagrammatic tools, the OMG standards try to mitigate differences with the help 
									of various interchange formats. The BPMN standard facilitates both diagramming and 
									execution of complex event-based workflows by means of the WS-BPEL binding or the 
									BPMN 2.0 XML serialization. Other standards have been popular in the past e.g., XPDL, 
									seemingly discontinued after the dissolution of the Workflow Management Coalition. For 
									reasons perhaps historical, all standard serializations are XML-based, therefore 
									fundamentally hierarchical - and this is not limited to BPMN but also to other related 
									conceptual model serializations (XES for event logs, XMI for UML interchange). This 
									design choice has deviated from the fundamental graph-like (relationship-centric) nature 
									of most visual diagrams, which is hinted at by their meta-metamodels - all those analyzed 
									by the literature [9] having identified "relationship" (although under different names) as a 
									first-class citizen. Even in visual sketches where no visual connectors are present, but only 
									grouping or visual alignment, relationships are still implied by relative positioning or 
									hyperlinking, as shown in the taxonomy of diagrammatic relations discussed by [4].`,
					creatorName: "Arin Ray"
				}, {
					title: "Announcement Posted!",
					description: `Conceptual modelling tools export both semantic information and visual/positional data of
									diagrammatic elements. For model-driven software engineering, as well as interoperability 
									between diagrammatic tools, the OMG standards try to mitigate differences with the help 
									of various interchange formats. The BPMN standard facilitates both diagramming and 
									execution of complex event-based workflows by means of the WS-BPEL binding or the 
									BPMN 2.0 XML serialization. Other standards have been popular in the past e.g., XPDL, 
									seemingly discontinued after the dissolution of the Workflow Management Coalition. For 
									reasons perhaps historical, all standard serializations are XML-based, therefore 
									fundamentally hierarchical - and this is not limited to BPMN but also to other related 
									conceptual model serializations (XES for event logs, XMI for UML interchange). This 
									design choice has deviated from the fundamental graph-like (relationship-centric) nature 
									of most visual diagrams, which is hinted at by their meta-metamodels - all those analyzed 
									by the literature [9] having identified "relationship" (although under different names) as a 
									first-class citizen. Even in visual sketches where no visual connectors are present, but only 
									grouping or visual alignment, relationships are still implied by relative positioning or 
									hyperlinking, as shown in the taxonomy of diagrammatic relations discussed by [4].`,
					creatorName: "Arin Ray"
				}]
			)
			setIsAnnouncementsLoading(false);
		}, 6000);
	}

	useEffect(() => {
		getAnnouncements();
	}, [])

	return (
		<div>
			<div className={`${tabStyles['tab-heading']}`}>
				Announcements
				<Fab aria-label="add" color="primary" disabled={isLoading}>
					<Tooltip title="Add Course">
						<AddIcon />
					</Tooltip>
				</Fab>
			</div>
			<Divider />
			<div className={`${styles['announcements-list']}`}>
				{
					announcements.map((announcement) => (
						isAnnouncementsLoading ? (
							<Skeleton height={"100px"} width="100%" variant='rectangle' />
						) : (
							<AnnouncementThumbnail announcement={announcement} />
						)
					))
				}
			</div>
		</div>
	)
}

export default Announcements