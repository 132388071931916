/*eslint-disable*/
import AddIcon from '@mui/icons-material/Add'
import React, { useState, useEffect } from 'react'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import {
    Grid,
    IconButton,
    Divider,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Box,
} from '@mui/material'

import { deepOrange, yellow, lightBlue } from '@mui/material/colors'
import EditIcon from '@mui/icons-material/Edit'
import { Redirect, NavLink } from 'react-router-dom'
import { Fab, Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import GitHubIcon from '@mui/icons-material/GitHub'
import EmailIcon from '@mui/icons-material/Email'
import PersonIcon from '@mui/icons-material/Person'
import { connect, useSelector, useDispatch } from 'react-redux'
import { height, padding, textAlign, width } from '@mui/system'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
// import AccountMenu from './AccountMenu';
// import MyAccountForm from './MyAccountForm';
import { TextField } from '@mui/material'
// import myAccountModal from './myAccountModal';
import Checkbox from '@mui/material/Checkbox'
// import EditIcon from '@mui/icons-material/Edit';
// import { disableUpgrade } from '../redux';
// import { enableUpgrade } from '../redux';
import { enableCanvasAccess } from '../../../../redux'
import { disableCanvasAccess } from '../../../../redux'
import FormControlLabel from '@mui/material/FormControlLabel'
import { updateDescription } from '../../../../redux'
import { addLink, deleteLink } from '../../../../redux'
import DeleteIcon from '@mui/icons-material/Delete'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import '../../../style2.css'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import './style2.css'
// import Divider from '@mui/material';
// import Stack from '@mui/material/Stack';

export default function displayAccount() {
    console.log('Indside Display account')
    const param = useParams()
    // var user;
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [user, setUser] = useState(null)

    const userLinks = useSelector((state) => state.linkData)

    const email = param && param.email
    console.log(email)
    const fetchData = async () => {
        try {
            setLoading(true)

            if (email) {
                const response = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/getSearchData/${email}`
                )
                console.log('response', response)
                setUser(response.data)
                setData(response.data)
            }
        } catch (error) {
            console.log(error)
            setError(error.message)
        } finally {
            setLoading(false)
        }
    }
    // fetchData();
    useEffect(() => {
        fetchData()
    }, [email])

    // fetchData();
    console.log(data)
    const dispatch = useDispatch()
    console.log(user)
    const userData = user
    const name = user && user.firstName === 'Admin'
    const id = user && user.id
    const checkUser = user && user.role === 'Instructor'
    const role = user && user.role
    const description = user && user.description
    const links = user && user.links
    const phone = user && user.phone
    const upgradeRequest = user && user.upgradeRequest

    console.log(user, '--userData')

    if (loading) {
        return <h1>Loading...</h1>
    }

    if (error) {
        console.log(error)
        return <h1>Error: {error}</h1>
    }

    if (!user) {
        return <h1>No user data available</h1>
    }

    return (
        <Box padding={'5rem'} mb={'10rem'}>
            <div className="card mb-2" style={{ borderRadius: '.5rem' }}>
                <div className="row g-5">
                    <div
                        className="col-md-4 gradient-custom text-right text-white"
                        style={{
                            borderTopLeftRadius: '.5rem',
                            borderBottomLeftRadius: '.5rem',
                            padding: '30px',
                        }}
                    >
                        <div
                            className="img-fluid my-5"
                            style={{ width: '80px', textAlign: 'center' }}
                        >
                            <Avatar
                                sx={{
                                    bgcolor: lightBlue[50],
                                    width: 120,
                                    height: 120,
                                    marginLeft: 15,
                                    marginRight: 15,
                                    paddingTop: 1,
                                }}
                            >
                                <h2 style={{ color: 'black' }}>
                                    {user
                                        ? user.firstName[0] + user.lastName[0]
                                        : ' '}
                                </h2>
                            </Avatar>
                        </div>
                        <hr
                            style={{ borderColor: 'white' }}
                            className="mt-0 mb-4"
                        />
                        <h4>{user.firstName + ' ' + user.lastName}</h4>
                        {user.role == 'Student' ? (
                            <p>Instructor Access: No</p>
                        ) : (
                            <p>Instructor Access: Yes</p>
                        )}

                        <div className="mb-3">
                            <h5>Email</h5>
                            <p className="text" style={{ color: 'white' }}>
                                {user.email}
                            </p>
                        </div>
                        <div className="mb-3"></div>
                        {user.canvasAccess ? (
                            <p>Canvas Access: Yes</p>
                        ) : (
                            <p>Canvas Access: No</p>
                        )}
                    </div>
                    <div className="col-md-8">
                        <div className="card-body p-4">
                            <h5>Profile</h5>
                            <hr className="mt-0 mb-4" />

                            <br />

                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Grid item>
                                    <Typography
                                        variant="h5"
                                        color={'black'}
                                        fontSize={'1.5rem'}
                                        fontWeight={'bold'}
                                    >
                                        About
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />

                            <hr className="mt-0 mb-4" />

                            <p>{description || 'No description available'}</p>

                            <br />
                            <Stack>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Grid item>
                                        <Typography
                                            variant="h5"
                                            color={'black'}
                                            fontSize={'1.5rem'}
                                            fontWeight={'bold'}
                                        >
                                            Links
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Divider />
                                <hr className="mt-0 mb-4" />
                                {links.length > 0 ? (
                                    <TableContainer component={Paper} center>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">
                                                        <Typography
                                                            variant="h6"
                                                            fontWeight="bold"
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid black',
                                                            }}
                                                        >
                                                            Title
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography
                                                            variant="h6"
                                                            fontWeight="bold"
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid black',
                                                            }}
                                                        >
                                                            Link
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {links.map(
                                                    (studentLink, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell align="center">
                                                                <Typography variant="body1">
                                                                    {
                                                                        studentLink.title
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Typography variant="body1">
                                                                    {
                                                                        studentLink.link
                                                                    }
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : (
                                    <p>No links for the current user.</p>
                                )}
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
        </Box>
    )
}

/*eslint-disable*/
