/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import QuizEditQuestions from './QuizEditQuestions'
import AddIcon from '@mui/icons-material/Add'
import { Redirect } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
    Stack,
    Tabs,
    Tab,
    Paper,
    Typography,
    Box,
    Container,
    Select,
    MenuItem,
    Button,
    Divider,
    Alert,
    TextField,
    Autocomplete,
} from '@mui/material'
import {
    fetchQuizCategoryMetas,
    fetchQuizCategories,
    updateCanvasAccessData,
    fetchQuizQuestions,
} from '../../redux'
import { fetchQuestions } from '../../redux/QuestionBank/questionBankActions'
import CanvasQuiz from '../canvas/CanvasQuizQuestions'
import { useParams } from 'react-router-dom'

// Component for creating quiz manually
function CreateQuizTab() {
    const { courseId } = useParams()
    const [questions, setQuestions] = useState([])
    // const dispatch = useDispatch()
    // const userData = useSelector((state) => state.userData)

    const generateRandomId = () => {
        return Math.random().toString(36).substr(2, 10)
    }
    // Function to add a new question
    const handleNewQuestion = () => {
        const fakeQuestion = {
            _id: generateRandomId(),
            categoryId: '',
            questionType: 'text',
            question_type: 'multiple_choice_question',
            answers: [
                { _id: generateRandomId(), text: 'Option 1', weight: 0 },
                { _id: generateRandomId(), text: 'Option 2', weight: 0 },
                { _id: generateRandomId(), text: 'Option 3', weight: 0 },
                { _id: generateRandomId(), text: 'Option 4', weight: 0 },
                // Add more options as needed
            ],
            question: 'What is your new question?',
            correctAnswer: ['1'],
            point: '1',
            concepts: 'Fake Concept',
            difficulty: 'Easy',
        }
        setQuestions([fakeQuestion, ...questions])
    }
    // Function to handle updates to questions
    const handleCanvasQuizUpdate = (updatedQuestions) => {
        setQuestions(updatedQuestions)
    }

    return (
        <Stack mb={15}>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={4}
                mb={4}
            >
                <Typography variant="h4">Create Quiz Manually</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleNewQuestion}
                >
                    New Question
                </Button>
            </Box>
            {questions.length > 0 ? (
                <CanvasQuiz
                    ques={questions}
                    onUpdate={handleCanvasQuizUpdate}
                    courseId={courseId}
                />
            ) : (
                <h4
                    className="alert alert-info mt-4"
                    style={{
                        width: '30rem',
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '0 auto',
                    }}
                >
                    No questions have been added.
                </h4>
            )}
        </Stack>
    )
}
// Component for populating quiz from question bank
function PopulateQuizTab() {
    const { courseId } = useParams()
    const dispatch = useDispatch()
    const [selectedCategory, setSelectedCategory] = useState('')
    const [selectedKeyword, setSelectedKeyword] = useState('')
    const userData = useSelector((state) => state.userData)
    const quizQuestionData = useSelector((state) => state.quizQuestionData)
    const [numberOfQuestions, setNumberOfQuestions] = useState(0)
    const questionBank = useSelector((state) => state.questionBankData)
    const [previewQuestions, setPreviewQuestions] = useState([])
    const [saved, setSave] = useState(false)
    const [questionNumber, setQuestionNumber] = useState(0)
    const [inputValue, setInputValue] = useState('')

    const categoryData = useSelector((state) => state.categoryData)

    useEffect(() => {
        console.log(selectedCategory);
    });

    useEffect(() => {
        console.log('Quiz Questions:', quizQuestionData.questions); // Logging the questions array
        if (selectedCategory === 'all'){
            const newArray = [...previewQuestions, ...quizQuestionData.questions ]
            setPreviewQuestions(removeDuplicates(newArray, "question"))
        }
      }, [quizQuestionData]);

    // Function to handle creating quiz
    const handleCreateQuiz = () => {
        console.log(numberOfQuestions)
        if (numberOfQuestions == '0') {
            alert('Numbers of questions selected should be greater than zero')
        }
        setQuestionNumber(numberOfQuestions)
        dispatch(
            fetchQuestions(selectedCategory, numberOfQuestions, selectedKeyword)
        )
        // This section is added in order to get the questions that were manually added by an instructor, do we need the feature along with append?
        // if (selectedCategory === 'all') {
        //     dispatch(fetchQuizQuestions())
        // }
    }
    // Effect hook for updating preview questions
    useEffect(() => {
        setPreviewQuestions(questionBank.questions)
        console.log(previewQuestions)
    }, [questionBank])
    // Effect hook for fetching category data
    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            // setRole(userData.user.role)
            dispatch(fetchQuizCategoryMetas())
            dispatch(fetchQuizCategories())
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])
    // Function to remove duplicate questions
    const removeDuplicates = (array, key) => {
        // Create a Set to keep track of unique keys
        const seen = new Set();
      
        // Filter out duplicate objects based on 'id' field
        return array.filter(item => {
          const keyValue = item[key];
          if (!seen.has(keyValue)) {
            seen.add(keyValue);
            return true;
          }
          return false;
        });
      };
    const generateRandomId = () => {
        return Math.random().toString(36).substr(2, 10)
    }
    // Function to add a new question
    const handleNewQuestion = () => {
        const fakeQuestion = {
            _id: generateRandomId(),
            categoryId: '',
            questionType: 'text',
            question_type: 'multiple_choice_question',
            answers: [
                { _id: generateRandomId(), text: 'Option 1', weight: 0 },
                { _id: generateRandomId(), text: 'Option 2', weight: 0 },
                { _id: generateRandomId(), text: 'Option 3', weight: 0 },
                { _id: generateRandomId(), text: 'Option 4', weight: 0 },
                // Add more options as needed
            ],
            question: 'What is your new question?',
            correctAnswer: ['1'],
            point: '1',
            concepts: 'Fake Concept',
            difficulty: 'Easy',
        }
        setPreviewQuestions([fakeQuestion, ...previewQuestions])
    }

    const handleCanvasQuizUpdate = (updatedQuestions) => {
        setPreviewQuestions(updatedQuestions)
    }

    return (
        <div>
            <Typography variant="h5">
                Populate Quiz from Question Bank
            </Typography>

            <div
                style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '10px',
                }}
            >
                <Stack
                    style={{ display: 'flex', marginBottom: '20px' }}
                    spacing={4}
                >
                    <Stack direction={'row'} spacing={3}>
                        <Stack width={'15rem'}>
                            <Typography fontWeight="bold">
                                Select Quiz Category
                            </Typography>
                            <Select
                                label=""
                                value={selectedCategory}
                                onChange={(e) =>
                                    setSelectedCategory(e.target.value)
                                }
                            >
                                {categoryData.categoryData.map((category) => (
                                    <MenuItem
                                        key={category._id}
                                        value={category._id}
                                    >
                                        {category.categoryName}
                                    </MenuItem>
                                ))}
                                <MenuItem
                                        key={'all'}
                                        value={'all'}
                                    >
                                        {"All"}
                                </MenuItem>
                            </Select>
                        </Stack>

                        <Stack width={'auto'} minWidth={'13rem'}>
                            <Typography fontWeight="bold">
                                Enter Keywords
                            </Typography>
                            <Autocomplete
                                multiple
                                freeSolo
                                forcePopupIcon={false}
                                id="keywords"
                                options={[]} // Provide options if needed
                                value={selectedKeyword}
                                onChange={(event, newValue) => {
                                    setSelectedKeyword(newValue);
                                }}
                                inputValue={inputValue} // Control the input value
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="" 
                                        onBlur={(event) => {
                                            const newKeyword = event.target.value.trim();
                                            if (newKeyword && !selectedKeyword.includes(newKeyword)) {
                                                setSelectedKeyword([...selectedKeyword, newKeyword]);
                                            }
                                            setInputValue('')
                                        }}
                                    />
                                )}
                            />
                        </Stack>

                        <Stack width={'auto'}>
                            <Typography fontWeight="bold">
                                # of questions :
                            </Typography>
                            <input
                                type="number"
                                placeholder="Enter number of questions"
                                value={numberOfQuestions}
                                onChange={(e) =>
                                    setNumberOfQuestions(e.target.value)
                                }
                                style={{
                                    padding: '15px',
                                    borderRadius: '5px',
                                    border: '1px solid #ccc',
                                }}
                            />
                        </Stack>
                    </Stack>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setSave(true)
                            handleCreateQuiz()
                        }}
                        style={{ width: '10rem' }}
                    >
                        Generate Quiz
                    </Button>
                </Stack>
            </div>

            {previewQuestions.length === 0 || questionNumber === 0 && saved ? (
                <h4
                    className="alert alert-info mt-4 mb-5"
                    style={{
                        width: '35rem',
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '0 auto',
                    }}
                >
                    Currently, there are no available questions.
                </h4>
            ) : previewQuestions.length < questionNumber && saved ? (
                <h4
                    className="alert alert-info mt-4 mb-5"
                    style={{
                        width: '50rem',
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '0 auto',
                    }}
                >
                    The number of questions available in the question bank are{' '}
                    {previewQuestions.length}.
                </h4>
            ) : null}

            {previewQuestions.length > 0 && questionNumber > 0 && (
                <>
                    <Button
                        style={{ float: 'right' }}
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleNewQuestion}
                    >
                        New Question
                    </Button>
                    <QuizEditQuestions
                        ques={previewQuestions}
                        onUpdate={handleCanvasQuizUpdate}
                        courseId={courseId}
                    />
                </>
            )}
        </div>
    )
}
// Main component
function App() {
    const { courseId } = useParams()

    const [tabIndex, setTabIndex] = useState(0)
    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue)
    }
    const [loading, setLoading] = useState(true)
    const userData = useSelector((state) => state.userData)

    useEffect(() => {
        if (userData && userData.userFetchCompleted) {
            setLoading(false)
        }
    }, [userData])

    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }

    if (!userData.user || userData.user.role !== 'Instructor') {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    To view this page, the user must log in as an instructor.
                </h4>
            </div>
        )
    }
    const handleBack = () => {
        window.location.href = `/course/home/${encodeURI(courseId)}`
    }
    return (
        <Container style={{ marginBottom: '50rem' }} maxWidth>
            <Button
                startIcon={<ArrowBackIcon />}
                variant="contained"
                onClick={handleBack}
                style={{ marginTop: '1rem' }}
            >
                Back
            </Button>
            <Paper elevation={3} style={{ marginTop: 20, padding: 20 }}>
                <Tabs value={tabIndex} onChange={handleTabChange} centered>
                    <Tab label="Create Quiz Manually" />
                    <Tab label="Populate Quiz from Question Bank" />
                </Tabs>
                <Divider mt="1rem" mb="1rem" />

                <Box role="tabpanel" hidden={tabIndex !== 0} marginTop="2rem">
                    <CreateQuizTab />
                </Box>
                <Box role="tabpanel" hidden={tabIndex !== 1} marginTop="2rem">
                    <PopulateQuizTab />
                </Box>
            </Paper>
        </Container>
    )
}

export default App
