/*eslint-disable*/
import React from 'react'
import styles from './Quiz.module.css'

function QuestionPalette ({questionIndex, setQuestionIndex, results, isRunning}) {
	return (
		<div className={`${styles['questions-palette']}`}>
			<h4>Questions</h4>
			<div className={`${styles['questions-nav-panel']}`}>
				{results.map((result, i) => { 
					return (
						<div 
							className={`${styles['question-icon']} ${i === questionIndex ? styles['current'] : ''} 
										${isRunning && styles['running']}
										${result.isAttempted ? (isRunning ? styles['attempted'] : (result.isCorrect === true ? styles['correct'] : styles['incorrect'])) : '' }
									`}
							onClick={()=>{setQuestionIndex(i)}}
						>
							{i + 1}
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default QuestionPalette