/*eslint-disable*/

import React from 'react'
import { useSelector } from 'react-redux'
import LandingPage from './LandingPage';
import './style2.css';
import MyAccountPage from './MyAccountPage';

export default function MyAccount(){
    const userData = useSelector((state) => state.userData);
    const user = userData && userData.user;
    
    if(user) console.log(userData)
    return(user ?
    <div className="container main-container mb-5 accnt">
        {/* <Avatar sx={{ bgcolor: deepPurple[500], width: 120, height: 120}}><h1>{user ? userData.user.firstName[0] + userData.user.lastName[0] : ' '}</h1></Avatar>
        <h4>{user ? (timeNow >= 5 && timeNow <= 12 ? "Good Morning, " : (timeNow >= 12 && timeNow < 18 ? "Good Afternoon, " : "Good evening, " )) + userData.user.firstName : ""}</h4> */}
        <MyAccountPage user = {userData.user}/>       
    </div> : <LandingPage/>);
} 