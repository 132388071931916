/*eslint-disable*/
import { ButtonView, Plugin } from 'ckeditor5';

export default class InputUI extends Plugin {
    init() {
        const editor = this.editor;
        const t = editor.t;
        editor.ui.componentFactory.add( 'input', locale => {
            const command = editor.commands.get( 'insertInput' );
            const buttonView = new ButtonView( locale );

            buttonView.set( {
                label: t( 'Insert Blank' ),
                withText: true,
                tooltip: true
            } );

            buttonView.bind( 'isOn', 'isEnabled' ).to( command, 'value', 'isEnabled' );

            this.listenTo( buttonView, 'execute', () => {
                editor.execute( 'insertInput' )
            } );

            return buttonView;
        } );
    }
}