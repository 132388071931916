/*eslint-disable*/

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import RequestCard from './RequestCard';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function RequestModal({ student }) {
  const [open, setOpen] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const [LikeList, setLikeList] = useState('');

  const renderLikeList = (studentName, studentEmail) => {
    studentEmail = 'mailto:' + studentEmail;
    return (
      <RequestCard student={student} />
    );
  };

  const handleLeave = () => {
    setTimeout(() => {
      setLikeList('');
    }, 0);
  }

  const handleHover = (studentName, studentEmail) => {
    setTimeout(() => {
      setLikeList(renderLikeList(studentName, studentEmail));
    }, 0);
  }

  // On hover

  return (
    <div>
      <div
        href="javascript:void(0)"
        onMouseOver={() => handleHover(student.name, student.email)}
        onMouseLeave={handleLeave}
      >
        <a href = '' className="likes__relavance" onMouseOver={() => handleHover(student.name, student.email)} onMouseLeave={handleLeave}>
          {student.name}
        </a>
        <div className="likes__relavance">
        {LikeList}
        </div>
      </div>
    </div>
  );
}

// StudentDetailsModal.propTypes = {
//   student: PropTypes.string.isRequired,
// }
