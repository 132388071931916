import axios from 'axios'
import {
    CANVAS_MODULES_META_FAILURE,
    CANVAS_MODULES_META_REQUEST,
    CANVAS_MODULES_META_SUCCESS,
    CANVAS_MODULE_ITEMS_META_REQUEST,
    CANVAS_MODULE_ITEMS_META_SUCCESS,
    CANVAS_MODULE_ITEMS_META_FAILURE,
} from './canvasModulesCategoryTypes'

export const modulesDataRequest = () => ({
    type: CANVAS_MODULES_META_REQUEST,
})

export const moduleItemsDataRequest = () => ({
    type: CANVAS_MODULE_ITEMS_META_REQUEST,
})

export const modulesDataSuccess = (modulesData) => ({
    type: CANVAS_MODULES_META_SUCCESS,
    payload: modulesData,
})

export const moduleItemsDataSuccess = (moduleItemsData) => ({
    type: CANVAS_MODULE_ITEMS_META_SUCCESS,
    payload: moduleItemsData,
})

export const modulesDataFailure = (errorMsg) => ({
    type: CANVAS_MODULES_META_FAILURE,
    payload: errorMsg,
})

export const moduleItemsDataFailure = (errorMsg) => ({
    type: CANVAS_MODULE_ITEMS_META_FAILURE,
    payload: errorMsg,
})

export const fetchModulesData = (courseId) => (dispatch) => {
    // console.log(courseId)
    dispatch(modulesDataRequest())
    const url = `${process.env.REACT_APP_BASE_URL}/canvas/courses/${courseId}/modules`
    // if (courseId && courseId.trim().length > 0) {
    //     url += `?courseId=${courseId}/modules`
    // }
    // console.log(url)
    axios
        .get(url)
        .then((res) => {
            const modulesData = res.data
            if (!modulesData) {
                dispatch(modulesDataFailure('No module/modules available'))
            } else {
                dispatch(modulesDataSuccess(modulesData))
            }
        })
        .catch((err) => {
            dispatch(
                modulesDataFailure(
                    (err.response && err.response.data) ||
                        'Error fetching module/modules from canvas'
                )
            )
        })
}
export const fetchModuleItemsData = (courseId, moduleId) => (dispatch) => {
    // console.log(courseId)
    dispatch(moduleItemsDataRequest())
    const url = `${process.env.REACT_APP_BASE_URL}/canvas/courses/${courseId.courseId}/modules/${moduleId}/items`
    // if (courseId && courseId.trim().length > 0) {
    //     url += `?courseId=${courseId}/modules`
    // }
    // console.log(url)
    axios
        .get(url)
        .then((res) => {
            const modulesData = res.data
            if (!modulesData) {
                dispatch(moduleItemsDataFailure('No module items available'))
            } else {
                dispatch(moduleItemsDataSuccess(modulesData))
            }
        })
        .catch((err) => {
            dispatch(
                moduleItemsDataFailure(
                    (err.response && err.response.data) ||
                        'Error fetching module items from canvas'
                )
            )
        })
}
// export const fetchSingleCourse = () => (dispatch) => {
//     dispatch(canvasDataRequest())
//     axios
//         .get(`${process.env.REACT_APP_BASE_URL}/canvas/courses`)
//         .then((res) => {
//             const canvasData = res.data
//             if (!canvasData) {
//                 dispatch(canvasDataFailure('No courses available'))
//             } else {
//                 dispatch(canvasDataSuccess(canvasData))
//             }
//         })
//         .catch((err) => {
//             dispatch(
//                 canvasDataFailure(
//                     (err.response && err.response.data) ||
//                         'Error fetching courses from canvas'
//                 )
//             )
//         })
// }
// export const saveQuizCategory = (category) => (dispatch) => {
//     dispatch(canvasDataRequest())
//     axios({
//         method: 'post',
//         url: `${process.env.REACT_APP_BASE_URL}/quiz/category`,
//         data: { category },
//         headers: {
//             'Content-Type': 'application/json',
//         },
//     })
//         .then((response) => {
//             const quizCategory = response.data
//             dispatch(canvasDataSuccess(quizCategory))
//         })
//         .catch((err) => {
//             dispatch(
//                 canvasDataFailure(
//                     (err.response && err.response.data) ||
//                         'Error adding quiz category'
//                 )
//             )
//         })
// }
