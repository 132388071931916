import axios from 'axios'
import {
    QUIZ_META_FAILURE,
    QUIZ_META_REQUEST,
    QUIZ_META_SUCCESS,
} from './quizMetaTypes'

export const quizMetaRequest = () => ({
    type: QUIZ_META_REQUEST,
})

export const quizMetaSuccess = (quizMetas) => ({
    type: QUIZ_META_SUCCESS,
    payload: quizMetas,
})

export const quizMetaFailure = (errorMsg) => ({
    type: QUIZ_META_FAILURE,
    payload: errorMsg,
})

export const fetchQuizMetas = (courseId) => (dispatch) => {
    dispatch(quizMetaRequest())
    let url = `${process.env.REACT_APP_BASE_URL}/quiz/meta`
    if (courseId && courseId.trim().length > 0) {
        url += `?courseId=${courseId}`
    }
    axios
        .get(url)
        .then((res) => {
            const quizMetas = res.data
            // console.log(quizMetas)
            if (!quizMetas) {
                dispatch(quizMetaFailure('No quiz available'))
            } else {
                // console.log(quizMetas)
                quizMetas.sort((a, b) => (a.title > b.title ? 1 : -1))
                dispatch(quizMetaSuccess(quizMetas))
            }
        })
        .catch((err) => {
            dispatch(
                quizMetaFailure(
                    (err.response && err.response.data) ||
                        'Error fetching available quizzes'
                )
            )
        })
}
