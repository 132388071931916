import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    register as registerRedux,
    clearErrorForKey as clearErrorForKeyRedux,
    setErrorForKey as setErrorForKeyRedux,
} from '../../redux'
import { validateEmail } from '../../utils'

function RegisterForm({
    userData,
    errorData,
    register,
    setErrorForKey,
    clearErrorForKey,
}) {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    useEffect(
        () => () => {
            clearErrorForKey('firstName')
            clearErrorForKey('lastName')
            clearErrorForKey('email')
            clearErrorForKey('password')
            clearErrorForKey('confirmPassword')
        },
        [clearErrorForKey]
    )

    const onFirstNameChange = (updatedFirstName) => {
        let errorMessage = null
        if (updatedFirstName.trim().length < 1)
            errorMessage = 'Invalid first name'
        setErrorForKey('firstName', errorMessage)
        setFirstName(updatedFirstName)
    }

    const onLastNameChange = (updatedLastName) => {
        let errorMessage = null
        if (updatedLastName.trim().length < 1)
            errorMessage = 'Invalid last name'
        setErrorForKey('lastName', errorMessage)
        setLastName(updatedLastName)
    }

    const onEmailChange = (updatedEmail) => {
        let errorMessage = null
        if (!validateEmail(updatedEmail))
            errorMessage = 'Invalid email provided'
        setErrorForKey('email', errorMessage)
        setEmail(updatedEmail)
    }

    const onPasswordChange = (updatedPassword) => {
        let errorMessage = null
        if (updatedPassword.length < 8)
            errorMessage = 'Password length should be atleast 8'
        if (errorData.confirmPassword !== undefined)
            setErrorForKey(
                'confirmPassword',
                confirmPassword === updatedPassword
                    ? null
                    : "Passwords don't match"
            )
        setErrorForKey('password', errorMessage)
        setPassword(updatedPassword)
    }

    const onConfirmPasswordChange = (updatedConfirmPassword) => {
        let errorMessage = null
        if (updatedConfirmPassword !== password)
            errorMessage = "Passwords don't match"
        setErrorForKey('confirmPassword', errorMessage)
        setConfirmPassword(updatedConfirmPassword)
    }

    const isFormValid = () =>
        errorData.firstName === null &&
        errorData.lastName === null &&
        errorData.email === null &&
        errorData.password === null &&
        errorData.confirmPassword === null

    return (
        <form>
            <div className="row" style={{ margin: 0 }}>
                <div className="form-group">
                    <label className="input-max-400" htmlFor="text">
                        First Name
                        <input
                            className={`form-control ${
                                // eslint-disable-next-line no-nested-ternary
                                errorData.firstName === undefined
                                    ? ''
                                    : errorData.firstName
                                    ? 'form-input-invalid'
                                    : 'form-input-valid'
                            }`}
                            id="firstName"
                            maxLength={50}
                            onChange={(e) => onFirstNameChange(e.target.value)}
                            type="text"
                            value={firstName}
                        />
                    </label>
                </div>
                <div className="form-group">
                    <label className="input-max-400" htmlFor="text">
                        Last Name
                        <input
                            className={`form-control ${
                                // eslint-disable-next-line no-nested-ternary
                                errorData.lastName === undefined
                                    ? ''
                                    : errorData.lastName
                                    ? 'form-input-invalid'
                                    : 'form-input-valid'
                            }`}
                            id="lastName"
                            maxLength={50}
                            onChange={(e) => onLastNameChange(e.target.value)}
                            type="text"
                            value={lastName}
                        />
                    </label>
                </div>
            </div>
            <div className="row" style={{ margin: 0 }}>
                <div className="form-group">
                    <label className="input-max-400" htmlFor="email">
                        Email
                        <input
                            className={`form-control ${
                                // eslint-disable-next-line no-nested-ternary
                                errorData.email === undefined
                                    ? ''
                                    : errorData.email
                                    ? 'form-input-invalid'
                                    : 'form-input-valid'
                            }`}
                            id="email"
                            maxLength={50}
                            onChange={(e) => onEmailChange(e.target.value)}
                            type="email"
                            value={email}
                        />
                    </label>
                </div>
            </div>
            <div className="row" style={{ margin: 0 }}>
                <div className="form-group">
                    <label className="input-max-400" htmlFor="password">
                        Password
                        <input
                            className={`form-control ${
                                // eslint-disable-next-line no-nested-ternary
                                errorData.password === undefined
                                    ? ''
                                    : errorData.password
                                    ? 'form-input-invalid'
                                    : 'form-input-valid'
                            }`}
                            id="password"
                            maxLength={20}
                            onChange={(e) => onPasswordChange(e.target.value)}
                            type="password"
                            value={password}
                        />
                    </label>
                    {password.length > 0 && password.length < 8 && (
                        <div className="text-danger">Password should be at least 8 characters long.</div>
                    )}
                </div>
                <div className="form-group">
                    <label className="input-max-400" htmlFor="password">
                        Confirm Password
                        <input
                            className={`form-control ${
                                // eslint-disable-next-line no-nested-ternary
                                errorData.confirmPassword === undefined
                                    ? ''
                                    : errorData.confirmPassword
                                    ? 'form-input-invalid'
                                    : 'form-input-valid'
                            }`}
                            id="confirm-password"
                            maxLength={20}
                            onChange={(e) =>
                                onConfirmPasswordChange(e.target.value)
                            }
                            type="password"
                            value={confirmPassword}
                        />
                    </label>
                </div>
            </div>
            <button
                className="btn btn-primary"
                disabled={userData.loading || !isFormValid()}
                onClick={() => register(firstName, lastName, email, password)}
                type="submit"
            >
                Register
            </button>
        </form>
    )
}

RegisterForm.propTypes = {
    userData: PropTypes.shape({
        loading: PropTypes.bool,
    }).isRequired,
    errorData: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        password: PropTypes.string,
        confirmPassword: PropTypes.string,
    }).isRequired,
    register: PropTypes.func.isRequired,
    setErrorForKey: PropTypes.func.isRequired,
    clearErrorForKey: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    userData: state.userData,
    errorData: state.errorData,
})

const mapDispatchToProps = (dispatch) => ({
    register: (firstName, lastName, email, password) =>
        dispatch(registerRedux(firstName, lastName, email, password, "Student", [], [], [], [])),
    setErrorForKey: (key, errorMessage) =>
        dispatch(setErrorForKeyRedux(key, errorMessage)),
    clearErrorForKey: (key) => dispatch(clearErrorForKeyRedux(key)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm)
