/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import modalStyles from '../../../../css/Modal.module.css';
import DragAndDrop from '../../../commons/DragAndDrop';
import { Button, Modal } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const S3_BUCKET_NAME = 'myacademic-syllabusfiles'
const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: 'us-east-1',
})

const UploadFileModal = ({open, handleClose}) => {
    const { id: courseId } = useParams()

	const [file, setFile] = useState(null);
	const [fileList, setFileList] = useState([]);

	const handleUpload = (file) => {
		setFile(file);
	}

    const listS3Objects = async (prefix) => {
        try {
            const params = {
                Bucket: S3_BUCKET_NAME,
                Prefix: prefix,
            }

            const data = await s3.listObjectsV2(params).promise()
            const files = data.Contents.map((obj) => obj.Key)
            return files
        } catch (error) {
            console.error('Error listing S3 objects: ', error)
            return []
        }
    }

    const handleFileDownload = (fileKey) => {
        const url = s3.getSignedUrl('getObject', {
            Bucket: S3_BUCKET_NAME,
            Key: fileKey,
        })

        // You can now open the URL in a new tab or use it as needed
        window.open(url, '_blank')
    }

    const getFiles = async () => {
        const files = await listS3Objects(`${courseId}/syllabus`);
        setFileList(files);
        console.log("FILE LIST");
        console.log(files);
    }

    const handleFileUpload = async () => {
        if (!file) {
            return
        }

        /** DJANGO INTEGRATION */
        // const formData = new FormData();
        // formData.append("file", selectedFile);
        // formData.append("course_id", courseId);
        // await axios.post(
        //     "http://localhost:8000/uploadpdf",
        //     formData,
        //     {
        //     headers: {
        //         "Content-Type": "multipart/form-data",
        //     },          
        //     }
        // );


        try {
            const fileName = `${courseId}/syllabus/${file.name}`
            const params = {
                Bucket: S3_BUCKET_NAME,
                Key: fileName,
                Body: file,
            }

            await s3.upload(params).promise()
            alert('File successfully uploaded!')
            // Refresh the file list
            await getFiles();
            setFile(null);
        } catch (error) {
            console.error('Error uploading file: ', error)
            alert('File not uploaded!')
        }
    }

    useEffect(()=>{
        getFiles();
    }, []);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={`${modalStyles['container']}`}>
                <div className={`${modalStyles['top-container']}`}>
                    <h1 className={`${modalStyles['heading']}`}>Upload File</h1>
                    <ClearIcon 
                        onClick={handleClose}
                        sx={{
                            fontSize: "2.5em",
                            cursor: "pointer"
                        }}
                    />
                </div>
                <div className={`${modalStyles['main-container']}`}>
                    <DragAndDrop 
                        file={file}
                        accept={'.pdf, .docx'}
                        handleUpload={handleUpload} 
                    />
                </div>
                <div className={`${modalStyles['button-container']}`}>
                    <Button
                        className={`btn btn-primary ${modalStyles['add-to-course-btn']}`}
                        onClick={() => handleFileUpload()}
                        variant='contained'
                        size='large'
                    >
                        Upload
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default UploadFileModal