/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import {
    Stack,
    Menu,
    MenuItem,
    Box,
    Typography,
    Button,
    Link,
    Tooltip,
    Card,
    CardContent,
    Fab,
    Switch,
    FormControlLabel,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import LinkIcon from '@mui/icons-material/Link'
import AddIcon from '@mui/icons-material/Add'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import TopLoader from '../../../commons/TopLoader'
import {
    fetchQuizMetas,
    updateQuiz,
    updateQuizMetaData,
    fetchQuiz,
    fetchSavedCourse,
} from '../../../../redux'
import '../../../../css/Card.css'
import CanvasEditQuiz from '../../../canvas/CanvasEditQuizQuestions'
import { makeStyles } from '@mui/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import { NavLink } from 'react-bootstrap'
import CreateQuiz from '../../../quiz-new/CreateQuiz'
import EditQuiz from '../../../quiz-new/EditQuiz'
import EditIcon from '@mui/icons-material/Edit';
import { RestrictedEditingModeNavigationCommand } from 'ckeditor5'
import DeleteIcon from '@mui/icons-material/Delete';

// Custom styles using makeStyles utility
const useStyles = makeStyles({
    // card: {
    // minWidth: 1000,
    border: 'none',
    // padding: 0,
    // margin: -10,
    // },
    title: {
        fontSize: 20,
        backgroundColor: '#f5f5f5',
        padding: 15,
        margin: 0,
        color: '#333',
    },
    linkIcon: {
        marginLeft: 'auto',
    },
    bodyItem: {
        border: '1px solid #e0e0e0',
        padding: 10,
        transition: 'background-color 0.3s ease',
        '&:hover': {
            backgroundColor: '#f0f8ff',
        },
        // fontSize: 20,
        margin: 0,
    },
})

// Functional component for rendering a list of quizzes for a course
function Quiz(courseId) {
    const [isCreateQuizModalOpen, setIsCreateQuizModalOpen] = useState(false);
    const [isEditQuizModalOpen, setIsEditQuizModalOpen] = useState(false);
    const [editQuizId, setEditQuizId] = useState(null); 
    const classes = useStyles()
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const quizMetasData = useSelector((state) => state.quizMetaData)
    const currentCourseData = useSelector((state) => state.currentCourseData)
    const quizData = useSelector((state) => state.quizData)
    // const { category: quizCategory } = useParams()
    const [flag, setFlag] = useState(false)
    const [refreshFlag, setRefreshFlag] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [selectedQuiz, setSelectedQuiz] = useState(null)
    const [showCanvasQuiz, setShowCanvasQuiz] = useState(false)
    const [questions, setQuestions] = useState([])
    const [quizDetails, setQuizDetails] = useState([])
    const [quiz, setQuiz] = useState({})
    const idRef = useRef(null)

    // useEffect hook for fetching quiz metadata
    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            const fetchData = () => {
                if (
                    userData.userFetchCompleted &&
                    userData.user &&
                    userData.user.id !== undefined
                ) {
                    dispatch(fetchQuizMetas(courseId.courseId))
                }
            }
            fetchData()
        }
    }, [
        dispatch,
        userData.userFetchCompleted,
        userData.user,
        flag,
        refreshFlag,
    ])

    // useEffect hook for fetching course data
    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            const fetchCourseData = () => {
                if (
                    userData.userFetchCompleted &&
                    userData.user &&
                    userData.user.id !== undefined
                ) {
                    dispatch(fetchSavedCourse(courseId.courseId))
                }
            }
            fetchCourseData()
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])

    // console.log(currentCourseData.createdBy)
    // console.log(userData.user.id)

    // Function to handle refresh
    const handleRefresh = () => {
        setRefreshFlag(!refreshFlag)
    }

    // Function to handle opening the menu
    const handleMenuOpen = (event, category) => {
        setAnchorEl(event.currentTarget)
        setSelectedQuiz(category)
    }

    // Function to handle closing the menu
    const handleMenuClose = () => {
        setAnchorEl(null)
        setSelectedQuiz(null)
    }

    // Function to handle publishing a quiz
    const handlePublish = async (id, publish) => {
        await dispatch(updateQuizMetaData({ id, publish }))
        setFlag(!flag)
        handleMenuClose()
    }
    // Function to generate a random ID
    const generateRandomId = () => {
        return Math.random().toString(36).substr(2, 10)
    }
    // Function to convert question data to question model
    const convertToQuestionModel = (questions, id) => {
        return questions.map((questionData) => {
            console.log(questionData, 'hello')
            const questionId = generateRandomId()
            const {
                categoryId,
                questionType,
                questionPic,
                answerSelectionType,
                answers,
                question,
                correctAnswer,
                point,
                concepts,
                difficulty,
            } = questionData
            const convertedAnswers = answers.map((answerData, index) => {
                const weight = correctAnswer.includes(index + 1) ? 100 : 0
                const answerId = generateRandomId()

                return {
                    _id: answerId,
                    text: answerData,
                    weight: weight,
                }
            })

            return {
                _id: questionId,
                categoryId: categoryId,
                questionType: questionType,
                questionPic: questionPic,
                question_type:
                    answerSelectionType === 'single'
                        ? 'fill_in_the_blanks_question'
                        : 'multiple_choice_question',
                answers: convertedAnswers,
                question: question,
                correctAnswer: correctAnswer,
                point: point,
                concepts: concepts,
                difficulty: difficulty,
            }
        })
    }
    // Function to handle editing a quiz
    const handleEdit = (id) => {
        idRef.current = id
        handleMenuClose()
        dispatch(fetchQuiz(id))
    }
    // useEffect hook to handle quiz data changes
    useEffect(() => {
        const id = idRef.current
        if (quizData.quiz) {
            setQuiz(quizData.quiz)
            setQuizDetails(quizData.quiz.data)
            const convertedQuestions = convertToQuestionModel(
                quizData.quiz.data.questions,
                id
            )
            console.log(convertedQuestions, 'hello')

            setQuestions(convertedQuestions)
            // console.log(convertedQuestions, 'hello')

            setShowCanvasQuiz(true)
        } else {
            setShowCanvasQuiz(false)
        }
    }, [quizData])
    // Function to handle deleting a quiz
    const handleDelete = async (id, deleted) => {
        await dispatch(updateQuizMetaData({ id, deleted }))
        setFlag(!flag)
        handleMenuClose()
    }
    // Function to render menu items
    const renderMenu = (id, isPublished) => (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => handlePublish(id, !isPublished)}>
                {isPublished ? 'Unpublish' : 'Publish'}
            </MenuItem>
            <MenuItem 
                onClick={() =>{
                    setEditQuizId(id);
                    setIsEditQuizModalOpen(true); 
                }}
            >
                Edit
            </MenuItem>
            <MenuItem onClick={() => handleDelete(id, true)}>Delete</MenuItem>
        </Menu>
    )

    // Conditional rendering based on user authentication and loading/error states
    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    if (quizMetasData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={quizMetasData.loadingMessage} />
            </div>
        )
    }
    if (quizMetasData.error) {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {quizMetasData.error}
                </h4>
            </div>
        )
    }
    // if (quizMetasData.quizMetas.length === 0) {
    //     return (
    //         <div className="container main-container">
    //             <h4 className="alert alert-light mt-4">
    //                 No quiz available currently.
    //             </h4>
    //         </div>
    //     )
    // }
    // Rendering quiz cards
    
    const quizCards = (
        <Card className={classes.card}>
            <CardContent>
                <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                >
                    Quizzes
                </Typography>
                {quizMetasData.quizMetas.map((quizMeta) => {
                    const { title, synopsis, id, isPublished } = quizMeta
                    const synopsisTrimmed =
                        synopsis.length > 83
                            ? `${synopsis.substring(0, 80).trim()}...`
                            : synopsis
                    return userData.user.role !== 'Instructor' &&
                        !isPublished ? null : (
                        <Link
                            href={`/quiz/${quizMeta.id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                color: 'inherit',
                                textDecoration: 'inherit',
                            }}
                        >
                            <div className={classes.bodyItem}>
                                <Stack
                                    direction={'row'}
                                    display="flex"
                                    justifyContent="space-between"
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        <IconButton
                                            aria-label="link"
                                            // className={classes.linkIcon}
                                        >
                                            <LinkIcon />
                                        </IconButton>
                                        &nbsp;
                                        <div>
                                            <Typography
                                                fontSize="1.1rem"
                                                fontWeight="bold"
                                                sx={{ textAlign: 'left' }}
                                            >
                                                {title}
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{ textAlign: 'left' }}
                                            >
                                                {synopsisTrimmed}
                                            </Typography>
                                        </div>
                                    </div>
                                    <Stack direction={'row'}>
                                        {/* <Stack>
                                            {isPublished ? (
                                                <Tooltip
                                                    title="Published"
                                                    arrow
                                                >
                                                    <Box padding={'0.5rem'}>
                                                        <CheckCircleIcon
                                                            style={{
                                                                color: 'green',
                                                            }}
                                                        />
                                                    </Box>
                                                </Tooltip>
                                            ) : (
                                                <Box padding={'0.5rem'}>
                                                    <Tooltip
                                                        title="Not Published"
                                                        arrow
                                                    >
                                                        <CancelIcon
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </Box>
                                            )}
                                        </Stack> */}
                                        {/* <Stack>
                                            {userData.user.role ===
                                                'Instructor' &&
                                            quizMeta.createdBy ===
                                                userData.user.id ? (
                                                <Box padding={'0.5rem'}>
                                                    <MoreVertIcon
                                                        className="menu-icon"
                                                        onClick={(event) => {
                                                            event.preventDefault()
                                                            handleMenuOpen(
                                                                event,
                                                                id
                                                            )
                                                        }}
                                                    />
                                                </Box>
                                            ) : null}
                                        </Stack> 
                                        {selectedQuiz === id &&
                                            renderMenu(id, isPublished)}
                                        */}
                                        {
                                            userData.user.role === 'Instructor' &&
                                            quizMeta.createdBy === userData.user.id ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        gap: '1em'
                                                    }}
                                                >
                                                    <FormControlLabel 
                                                        control={
                                                            <Switch
                                                                checked={isPublished}
                                                                onChange={() => handlePublish(id, !isPublished)}
                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                color='success'
                                                            />
                                                        } 
                                                        sx={{
                                                            margin: 0
                                                        }}
                                                        label="Publish" 
                                                        labelPlacement="start"
                                                    />
                                                    <EditIcon
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            setEditQuizId(id);
                                                            setIsEditQuizModalOpen(true); 
                                                        }} 
                                                    />
                                                    <DeleteIcon
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            handleDelete(id, true)
                                                        }}  
                                                    />
                                                    
                                                </div>
                                            ) : (null) 
                                        }
                                    </Stack>
                                </Stack>
                            </div>
                        </Link>
                        // <div key={title.substring(0, 10)} className="basic-card card">
                        //     {/* <img class="card-img-top" src="..." alt="Card image cap"/> */}
                        //     <div className="card-body meta-container">
                        //         {userData.user.role === 'Instructor' ? (
                        //             <Stack display="flex" alignItems="flex-end">
                        //                 <MoreVertIcon
                        //                     className="menu-icon"
                        //                     onClick={(event) => handleMenuOpen(event, id)}
                        //                 />
                        //             </Stack>
                        //         ) : null}
                        //         <h5 className="card-title">{title}</h5>
                        //         {/* <h5 className="card-title">{quizCategory}</h5> */}
                        //         <p className="card-text">{synopsisTrimmed}</p>
                        //         {isPublished ? (
                        //             <a
                        //                 className="btn btn-primary"
                        //                 href={`/quiz/${quizMeta.id}`}
                        //             >
                        //                 Take the quiz
                        //             </a>
                        //         ) : (
                        //             <Box
                        //                 border={'1px solid black'}
                        //                 padding={'0.5rem'}
                        //                 borderRadius={'0.3rem'}
                        //             >
                        //                 <Typography textAlign={'center'}>
                        //                     Quiz Not Published
                        //                 </Typography>
                        //             </Box>
                        //         )}
                        //     </div>
                        //     {selectedQuiz === id && renderMenu(id, isPublished)}
                        // </div>
                    )
                })}
            </CardContent>
        </Card>
    )
    // Conditional rendering based on user role and quiz availability
    const isStudent = userData.user.role === 'Student'

    const noPublishedQuizzes = quizMetasData.quizMetas.every(
        (quizMeta) => !quizMeta.isPublished
    )
    return (
        <Stack mb={'15rem'} mt={'1rem'}>
            <Stack
                direction="row"
                justifyContent="flex-end"
                display="flex"
                width="100%"
                mb={'2rem'}
            >
                {/* <h1 className="container">Quizzes</h1> */}
                {userData.user.role === 'Instructor' &&
                currentCourseData.createdBy === userData.user.id ? (

                    <NavLink onClick={()=>{setIsCreateQuizModalOpen(true)}}>
                        <Fab aria-label="add" color="primary">
                            <Tooltip title="Create Quiz">
                                <AddIcon />
                            </Tooltip>
                        </Fab>
                    </NavLink>
                    
                ) : null}
            </Stack>

            <div style={{ marginBottom: '3rem' }}>
                {quizMetasData.quizMetas.length === 0 ||
                (isStudent && noPublishedQuizzes) ? (
                    <div className="container main-container">
                        <h4 className="alert alert-light mt-2">
                            No quizzes available currently.
                        </h4>
                    </div>
                ) : (
                    <div>{quizCards}</div>
                )}
            </div>
            {showCanvasQuiz && (
                <Stack marginBottom={'5rem'}>
                    <Typography textAlign={'center'} mb={'3rem'} variant="h4">
                        {quizDetails.quizTitle}
                    </Typography>
                    {
                        <CanvasEditQuiz
                            ques={questions}
                            quizDetails={quizDetails}
                            quizCategories={quiz.category}
                            id={quiz.id}
                            onRefresh={handleRefresh}
                        />
                    }
                </Stack>
            )}
            <CreateQuiz open={isCreateQuizModalOpen} setOpen={setIsCreateQuizModalOpen}/>
            <EditQuiz open={isEditQuizModalOpen} setOpen={setIsEditQuizModalOpen} quizId={editQuizId} />
        </Stack>
    )
}

export default Quiz
