/* eslint-disable */
import {
    Box,
    IconButton,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    Tab,
    Tabs,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useState, useEffect } from 'react'
import { SketchField, Tools } from 'react-sketch2'
import { TwitterPicker } from 'react-color'
import '../../../css/CourseHome.css'
import { Redirect, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SaveIcon from '@mui/icons-material/Save'
import ModeIcon from '@mui/icons-material/Mode'
import ClearIcon from '@mui/icons-material/Clear'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import UndoIcon from '@mui/icons-material/Undo'
import RedoIcon from '@mui/icons-material/Redo'
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined'
import RectangleOutlinedIcon from '@mui/icons-material/RectangleOutlined'
import PanToolIcon from '@mui/icons-material/PanTool'
import MouseIcon from '@mui/icons-material/Mouse'
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'
import {
    resetCourseData,
    clearErrorForKey,
    fetchSavedCourse,
} from '../../../redux'
import GradeAnalyzer from '../../GradeAnalyzer'
import TabPanel from '../../commons/TabPanel'
import Syllabus from './Tabs/Syllabus'
import MeetingDetails from './Tabs/MeetingDetails'
import StudentList from './Tabs/StudentList'
import CourseResources from './Tabs/CourseResources'
import QuizListCourse from '../../quiz/QuizListCourse'
import CourseSideBar from '../../commons/courseSideBar'
import Quiz from './Tabs/Quiz'
// import store from './Tabs/store'

function CourseHome() {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const currentCourseData = useSelector((state) => state.currentCourseData)
    // const people = useSelector((state) => state.people)
    const user = userData && userData.user
    const { course_id: courseId = '' } = useParams()
    const [selectedOption, setSelectedOption] = useState(JSON.parse(localStorage.getItem("activeTab")));
    const [lineColor, setLineColor] = useState('000')
    const [backColor, setBackColor] = useState('white')
    const [fillColor, setFillColor] = useState('transparent')
    const [selectedTool, setSelectedTool] = useState(Tools.Pencil)
    const [lineColorShow, setLineColorShow] = useState('none')
    const [backColorShow, setBackColorShow] = useState('none')
    const [fillColorShow, setFillColorShow] = useState('none')
    const [open, setOpen] = useState(false)
    const options =
        userData &&
        userData.user &&
        userData.user.id &&
        userData.user.firstName === 'Admin'
            ? ['Pending Requests']
            : [
                  'Syllabus',
                  'Meeting details',
                  // 'White Board',
                  'Grade Analyzer',
                  'Course Resources',
                  'People',
                  'quiz',
              ]
    console.log(options.length)

    // const adminOptions = [
    //     'Pending Requests',
    //     // 'All courses',
    //     // 'Users',
    // ]

    const { courseName } = currentCourseData || {}

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(fetchSavedCourse(courseId))
        return () => {
            dispatch(resetCourseData())
            dispatch(clearErrorForKey('course'))
            dispatch(clearErrorForKey('target'))
        }
    }, [dispatch, userData.userFetchCompleted, userData.user, courseId])

    const handleLineColorChange = (color) => {
        setLineColor(color.hex)
        setLineColorShow('none')
    }
    const handleBackColorChange = (color) => {
        setBackColor(color.hex)
        setBackColorShow('none')
    }
    const handleFillColorChange = (color) => {
        setFillColor(color.hex)
        setFillColorShow('none')
    }

    const downloadImage = () => {
        const canvas = document.getElementsByTagName('canvas')
        const url = canvas[0].toDataURL('image/png')
        const link = document.createElement('a')
        link.download = 'whiteboard.png'
        link.href = url
        link.click()
    }

    const handleSelectedOption = (e) => {
        setSelectedOption(e)
        localStorage.setItem("activeTab", JSON.stringify(e));
    }

    const handleLineColorShow = () => {
        if (lineColorShow === 'none') {
            setLineColorShow('block')
            setBackColorShow('none')
            setFillColorShow('none')
        } else {
            setLineColorShow('none')
        }
    }

    const handleBackColorShow = () => {
        if (backColorShow === 'none') {
            setBackColorShow('block')
            setLineColorShow('none')
            setFillColorShow('none')
        } else {
            setBackColorShow('none')
        }
    }
    const handleFillColorShow = () => {
        if (fillColorShow === 'none') {
            setFillColorShow('block')
            setLineColorShow('none')
            setBackColorShow('none')
        } else {
            setFillColorShow('none')
        }
    }
    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!user || user.id === undefined)
    ) {
        return <Redirect to="/" />
    }

    const actions = [
        { icon: <SaveIcon />, name: 'Save', handleAction: downloadImage },
        {
            icon: <ModeIcon />,
            name: 'Pencil',
            handleAction: () => setSelectedTool(Tools.Pencil),
        },
        {
            icon: <ClearIcon />,
            name: 'Clear',
            handleAction: () => {
                // eslint-disable-next-line no-underscore-dangle
                CourseHome._sketch.clear()
                setBackColor('white')
            },
        },
        {
            icon: <UndoIcon />,
            name: 'Undo',
            handleAction: () =>
                // eslint-disable-next-line no-underscore-dangle
                CourseHome._sketch.canUndo() && CourseHome._sketch.undo(),
        },
        {
            icon: <RedoIcon />,
            name: 'Redo',
            handleAction: () =>
                // eslint-disable-next-line no-underscore-dangle
                CourseHome._sketch.canRedo() && CourseHome._sketch.redo(),
        },
        {
            icon: <TextFieldsOutlinedIcon />,
            name: 'Share',
            handleAction: () => {
                // eslint-disable-next-line no-underscore-dangle
                CourseHome._sketch.addText('New Text')
                setSelectedTool(Tools.Select)
            },
        },
    ]
    const renderTabContent = (tabIndex) => {
        switch (tabIndex) {
            case 0:
                return (
                    <Syllabus
                        courseId={courseId}
                        courseSyllabus={currentCourseData.courseSyllabus}
                    />
                )
            case 1:
                return (
                    <MeetingDetails
                        courseId={courseId}
                        courseMeetingDetails={
                            currentCourseData.courseMeetingDetails
                        }
                    />
                )

            case 2:
                return <GradeAnalyzer courseId={courseId} />
            case 3:
                return <CourseResources courseId={courseId} />
            case 4:
                return (
                    <StudentList
                        courseId={courseId}
                        people={currentCourseData.people}
                    />
                )
            case 5:
                return <Quiz courseId={courseId} />

            default:
                return <h3>Please select an option</h3>
        }
    }

    const renderadminTabContent = (tabIndex) => {
        switch (tabIndex) {
            case 0:
                return <Requests />
        }
    }

    return (
        <>
            {/* <CourseSideBar /> */}
            <div className="container main-container mb-5">
                <div className="course-top-wrapper">
                    <h3 className="col-md-8">{courseName}</h3>
                    {/* {
                    isMobileOrTab &&
                    <CustomizedMenus name={"Options"} options={options} handleSelectedOption={handleSelectedOption} />
                } */}
                </div>

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            aria-label="course home tabs"
                            onChange={(e, idx) => handleSelectedOption(idx)}
                            scrollButtons="auto"
                            value={selectedOption}
                            variant="scrollable"
                        >
                            {options.map((text) => (
                                <Tab key={text} label={text} />
                            ))}
                        </Tabs>
                    </Box>
                    <div className="render-wrapper">
                        {options.map((text, index) => (
                            <TabPanel
                                key={`tab-${text}`}
                                index={index}
                                value={selectedOption}
                            >
                                {userData &&
                                userData.user &&
                                userData.user.id &&
                                userData.user.firstName === 'Admin'
                                    ? renderadminTabContent(index)
                                    : renderTabContent(index)}
                            </TabPanel>
                        ))}
                    </div>
                    {/* <div className='render-wrapper'>
                {
                    renderSwitch()
                }
                </div> */}
                </Box>
            </div>
        </>
    )
}

export default CourseHome
/* eslint-disable */
