import axios from 'axios'
import {
    CANVAS_ASSIGNMENT_META_FAILURE,
    CANVAS_ASSIGNMENT_META_REQUEST,
    CANVAS_ASSIGNMENT_META_SUCCESS,
} from './canvasAssignmentTypes'

export const assignmentDataRequest = () => ({
    type: CANVAS_ASSIGNMENT_META_REQUEST,
})

export const assignmentDataSuccess = (assignmentData) => ({
    type: CANVAS_ASSIGNMENT_META_SUCCESS,
    payload: assignmentData,
})

export const assignmentDataFailure = (errorMsg) => ({
    type: CANVAS_ASSIGNMENT_META_FAILURE,
    payload: errorMsg,
})

export const fetchAssignmentData = (courseId) => (dispatch) => {
    dispatch(assignmentDataRequest())
    const url = `${process.env.REACT_APP_BASE_URL}/canvas/courses/${courseId}/assignment`
    // if (courseId && courseId.trim().length > 0) {
    //     url += `?courseId=${courseId}/assignment`
    // }
    axios
        .get(url)
        .then((res) => {
            const assignmentData = res.data
            if (!assignmentData) {
                dispatch(
                    assignmentDataFailure('No assignment/assignments available')
                )
            } else {
                dispatch(assignmentDataSuccess(assignmentData))
            }
        })
        .catch((err) => {
            dispatch(
                assignmentDataFailure(
                    (err.response && err.response.data) ||
                        'Error fetching assignment data from canvas'
                )
            )
        })
}
