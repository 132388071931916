import axios from 'axios'
import {
    CANVAS_ASSIGNMENT_GROUP_META_REQUEST,
    CANVAS_ASSIGNMENT_GROUP_META_SUCCESS,
    CANVAS_ASSIGNMENT_GROUP_META_FAILURE,
} from './canvasAssignmentGroupTypes'

export const assignmentGroupDataRequest = () => ({
    type: CANVAS_ASSIGNMENT_GROUP_META_REQUEST,
})

export const assignmentGroupDataSuccess = (assignmentGroupData) => ({
    type: CANVAS_ASSIGNMENT_GROUP_META_SUCCESS,
    payload: assignmentGroupData,
})

export const assignmentGroupDataFailure = (errorMsg) => ({
    type: CANVAS_ASSIGNMENT_GROUP_META_FAILURE,
    payload: errorMsg,
})

export const fetchAssignmentGroupData = (courseId) => (dispatch) => {
    dispatch(assignmentGroupDataRequest())
    const url = `${process.env.REACT_APP_BASE_URL}/canvas/courses/${courseId}/assignment_groups`
    axios
        .get(url)
        .then((res) => {
            const assignmentGroupData = res.data
            if (!assignmentGroupData) {
                dispatch(
                    assignmentGroupDataFailure('No assignment group available')
                )
            } else {
                dispatch(assignmentGroupDataSuccess(assignmentGroupData))
            }
        })
        .catch((err) => {
            dispatch(
                assignmentGroupDataFailure(
                    (err.response && err.response.data) ||
                        'Error fetching assignment group data from canvas'
                )
            )
        })
}
