/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import {
    Box,
    Card,
    CardContent,
    Typography,
    Grid,
    Stack,
    Divider,
    Button,
    Container,
    Tab,
    Tabs,
    TextField,
    MenuItem,
    FormControlLabel,
    Checkbox,
} from '@mui/material'
import { fetchquizData, fetchCanvasData } from '../../redux'
import { useSelector, useDispatch } from 'react-redux'
import TopLoader from '../commons/TopLoader'
import Sidebar from './Sidebar'
import { makeStyles } from '@mui/styles'
import CanvasQuiz from './CanvasQuizQuestions'

const useStyles = makeStyles({
    card: {
        // minWidth: 1000,
        border: '1px solid #e0e0e0',
        padding: 0,
        // margin: -10,
    },
    title: {
        fontSize: 20,
        backgroundColor: '#f5f5f5',
        padding: 15,
        margin: 0,
        color: '#333',
    },
    linkIcon: {
        marginLeft: 'auto',
    },
    bodyItem: {
        border: '1px solid #e0e0e0',
        padding: 10,
        transition: 'background-color 0.3s ease', // smooth transition
        '&:hover': {
            backgroundColor: '#f0f8ff', // light gray on hover
        },
        // fontSize: 20,
        margin: 0,
    },
})
const CanvasCreateQuiz = () => {
    const { course_id: courseId } = useParams()
    const classes = useStyles()
    const [value, setValue] = useState(0)
    const [formData, setFormData] = useState({
        quizName: 'Unnamed Quiz',
        quizType: 'Practice Quiz',
        shuffleAnswers: false,
        timeLimit: false,
        timeLimitMinutes: '',
        allowMultipleAttempts: false,
    })

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleInputChange = (key, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [key]: value,
        }))
    }
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const canvasData = useSelector((state) => state.canvasData)

    const quizData = useSelector((state) => state.canvasQuizData)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchquizData(courseId))
            dispatch(fetchCanvasData(courseId))
        }
    }, [dispatch, userData.userFetchCompleted, userData.user])

    // console.log(quizData)

    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    if (userData.user.role !== 'Instructor') {
        return (
            <div className="container main-container">
                <h4 className="alert alert-danger mt-4">
                    {
                        'To view this page, the user must log in as an instructor.'
                    }
                </h4>
            </div>
        )
    }
    if (quizData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={quizData.loadingMessage} />
            </div>
        )
    }

    if (canvasData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={canvasData.loadingMessage} />
            </div>
        )
    }

    return (
        <Stack spacing={30} direction={'row'} marginBottom={200}>
            <Sidebar courseId={courseId} />
            <Container maxWidth="lg">
                <Stack spacing={2}>
                    <Typography
                        variant="h5"
                        component="h2"
                        sx={{ mb: 2, mt: 1 }}
                        fontWeight={'bold'}
                    >
                        {canvasData.canvasData.course_code} : Quiz
                    </Typography>

                    <Divider />
                </Stack>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            textColor="primary"
                            indicatorColor="primary"
                        >
                            <Tab label="Details" />
                            <Tab label="Questions" />
                        </Tabs>
                    </Box>
                    <Box sx={{ p: 3 }}>
                        {value === 0 && (
                            <Box>
                                <TextField
                                    label="Quiz Name"
                                    value={formData.quizName}
                                    onChange={(e) =>
                                        handleInputChange(
                                            'quizName',
                                            e.target.value
                                        )
                                    }
                                    variant="outlined"
                                    size="small"
                                    style={{ marginBottom: '1rem' }}
                                />
                                <div
                                    style={{
                                        fontSize: '1.5rem',
                                        marginBottom: '1rem',
                                    }}
                                >
                                    Quiz Instruction
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: '1rem',
                                    }}
                                >
                                    <div style={{ marginRight: '1rem' }}>
                                        Quiz Type
                                    </div>
                                    <TextField
                                        label=""
                                        select
                                        value={formData.quizType}
                                        onChange={(e) =>
                                            handleInputChange(
                                                'quizType',
                                                e.target.value
                                            )
                                        }
                                        variant="outlined"
                                        size="small"
                                        style={{ width: 300 }}
                                        InputLabelProps={{ shrink: false }}
                                    >
                                        <MenuItem value="Practice Quiz">
                                            Practice Quiz
                                        </MenuItem>
                                        <MenuItem value="Graded Quiz">
                                            Graded Quiz
                                        </MenuItem>
                                    </TextField>
                                </div>
                                <Box style={{ marginLeft: '6rem' }}>
                                    <Typography fontWeight={'bold'}>
                                        Options
                                    </Typography>
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: 800,
                                            marginTop: '1rem',
                                            // marginLeft: '-5rem',
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        formData.shuffleAnswers
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'shuffleAnswers',
                                                            e.target.checked
                                                        )
                                                    }
                                                    name="shuffleAnswers"
                                                    color="primary"
                                                />
                                            }
                                            label="Shuffle Answers"
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: 800,
                                            marginTop: 'rem',
                                            // marginLeft: '-5rem',
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={formData.timeLimit}
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'timeLimit',
                                                            e.target.checked
                                                        )
                                                    }
                                                    name="timeLimit"
                                                    color="primary"
                                                />
                                            }
                                            label="Time Limit"
                                        />
                                        {formData.timeLimit && (
                                            <TextField
                                                id="outlined-basic"
                                                label="Minutes"
                                                variant="outlined"
                                                size="small"
                                                value={
                                                    formData.timeLimitMinutes
                                                }
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'timeLimitMinutes',
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        )}
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: 800,
                                            marginTop: '1rem',
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        formData.allowMultipleAttempts
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'allowMultipleAttempts',
                                                            e.target.checked
                                                        )
                                                    }
                                                    name="allowMultipleAttempts"
                                                    color="primary"
                                                />
                                            }
                                            label="Allow Multiple Attempts"
                                        />
                                    </div>

                                    {formData.allowMultipleAttempts && (
                                        <>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: 800,
                                                    marginTop: '1rem',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        marginRight: '1rem',
                                                    }}
                                                >
                                                    Quiz Score To Keep
                                                </div>
                                                <TextField
                                                    select
                                                    value={
                                                        formData.quizScoreToKeep
                                                    }
                                                    onChange={(e) =>
                                                        handleInputChange(
                                                            'quizScoreToKeep',
                                                            e.target.value
                                                        )
                                                    }
                                                    variant="outlined"
                                                    size="small"
                                                >
                                                    <MenuItem value="highest">
                                                        Highest
                                                    </MenuItem>
                                                    <MenuItem value="average">
                                                        Average
                                                    </MenuItem>
                                                    <MenuItem value="latest">
                                                        Latest
                                                    </MenuItem>
                                                </TextField>
                                            </div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            formData.allowMultipleItems
                                                        }
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                'allowMultipleItems',
                                                                e.target.checked
                                                            )
                                                        }
                                                        name="allowMultipleItems"
                                                        color="primary"
                                                    />
                                                }
                                                label="Allowed Attempts"
                                            />
                                            <TextField
                                                id="outlined-basic"
                                                label="Allowed Attempts"
                                                variant="outlined"
                                                size="small"
                                                value={formData.allowedAttempts}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        'allowedAttempts',
                                                        e.target.value
                                                    )
                                                }
                                                type="number"
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                }}
                                                style={{ marginLeft: '1rem' }}
                                            />
                                        </>
                                    )}
                                </Box>
                            </Box>
                        )}
                        {value === 1 && <CanvasQuiz />}
                    </Box>
                </Box>
            </Container>
        </Stack>
    )
}

export default CanvasCreateQuiz
