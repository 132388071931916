/*eslint-disable*/

import React, { useState } from 'react'
import { TextField, FormControl, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
    sendAllData,
    updateName,
    updateDesc,
    updateEMail,
    updateAffiliation,
    updateLinkedIn,
    updateTitle,
} from '../redux/upgrade/upgradeActions'
import { disableUpgrade } from '../redux'
import { enableUpgrade } from '../redux'
const sendUserDetails = (
    dispatch,
    name,
    email,
    linkedin,
    description,
    title,
    affiliation
) => {
    dispatch(
        sendAllData(name, email, linkedin, description, title, affiliation)
    )
}

const disableUpgradeRequest = async (id, dispatch) => {
    dispatch(disableUpgrade(id))
}

const MyAccountForm = ({ student }) => {
    const dispatch = useDispatch()
    const [email, setEmail] = useState(student.email)
    const [name, setName] = useState(student.firstName + ' ' + student.lastName)
    const [linkedin, setLinkedin] = useState('https://www.linkedin.com/')
    const [title, setTitle] = useState('')
    const [affiliation, setAffiliation] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [description, setDescription] = useState('')

    const handleSubmit = (event) => {
        event.preventDefault()
        setEmailError(false)
        sendUserDetails(
            dispatch,
            name,
            email,
            linkedin,
            description,
            title,
            affiliation
        )
        // console.log(student);
        disableUpgradeRequest(student.id, dispatch)
        // setPasswordError(false)
        if (email == '') {
            setEmailError(true)
        }
        if (email) {
            console.log(email)
        }
        window.location.reload()
    }

    return (
        <React.Fragment>
            <form
                autoComplete="off"
                onSubmit={handleSubmit}
                style={{ paddingTop: 50 }}
            >
                <h4>
                    If you want to teach a course fill the following details.
                </h4>
                <br />
                <br />
                <TextField
                    label="Name"
                    onChange={(e) => {
                        setName(e.target.value)
                        dispatch(updateName(e.target.value))
                    }}
                    // required
                    variant="outlined"
                    color="secondary"
                    type="text"
                    sx={{ mb: 3 }}
                    fullWidth
                    value={name}
                    // error={emailError}
                />
                <br />
                <br />
                <TextField
                    label="LinkedIn link"
                    onChange={(e) => {
                        setLinkedin(e.target.value)
                        dispatch(updateLinkedIn(e.target.value))
                    }}
                    // required
                    variant="outlined"
                    color="secondary"
                    type="text"
                    sx={{ mb: 3 }}
                    fullWidth
                    value={linkedin}
                    error={emailError}
                />
                <br />
                <br />
                <TextField
                    label="Email"
                    onChange={(e) => {
                        setEmail(e.target.value)
                        dispatch(updateEMail(e.target.value))
                    }}
                    // required
                    variant="outlined"
                    color="secondary"
                    type="email"
                    sx={{ mb: 3 }}
                    fullWidth
                    value={email}
                    error={emailError}
                />
                <br />
                <br />
                <TextField
                    label="Affiliation"
                    onChange={(e) => {
                        setAffiliation(e.target.value)
                        dispatch(updateAffiliation(e.target.value))
                    }}
                    // required
                    variant="outlined"
                    color="secondary"
                    type="text"
                    value={affiliation}
                    // error={passwordError}
                    fullWidth
                    sx={{ mb: 3 }}
                />
                <br />
                <br />
                <TextField
                    label="Title"
                    onChange={(e) => {
                        setTitle(e.target.value)
                        dispatch(updateTitle(e.target.value))
                    }}
                    // required
                    variant="outlined"
                    color="secondary"
                    type="text"
                    value={title}
                    // error={passwordError}
                    fullWidth
                    sx={{ mb: 3 }}
                />
                <br />
                <br />
                <TextField
                    label="Description"
                    onChange={(e) => {
                        setDescription(e.target.value)
                        dispatch(updateDesc(e.target.value))
                    }}
                    // required
                    variant="outlined"
                    color="secondary"
                    type="text"
                    value={description}
                    // error={passwordError}
                    fullWidth
                    sx={{ mb: 3 }}
                />
                <br/>
                <Button variant="outlined" color="secondary" type="submit" onClick={handleSubmit}>Request for Instructor access</Button>
        </form>
        {/* <small>Need an account? <Link to="/">Register here</Link></small> */}
        </React.Fragment>
    )
}

export default MyAccountForm

/*eslint-disable*/
