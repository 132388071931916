/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import styles from './Quiz.module.css'
import { useTimer } from "react-timer-hook";

const Timer = ({timeLimit, className, isRunning, handleOnQuizEnd, setProgress}) => {
	const startDateTime = new Date();
	const endDateTime = new Date();
	const timeLimitArray = timeLimit?.split(':');
	const hoursLimit = parseInt(timeLimitArray[0]);
	const minutesLimit = parseInt(timeLimitArray[1]);
	const secondsLimit = parseInt(timeLimitArray[2]);
	const totalSecondsLimit = hoursLimit * 3600 + minutesLimit * 60 + secondsLimit;

	endDateTime.setHours(startDateTime.getHours() + (hoursLimit));
	endDateTime.setMinutes(startDateTime.getMinutes() + (minutesLimit));
	endDateTime.setSeconds(startDateTime.getSeconds() + (secondsLimit));
	
	const {
		totalSeconds,
		seconds,
		minutes,
		hours,
		start,
		pause,
		resume,
		restart,
	  } = useTimer({
		expiryTimestamp: endDateTime,
		autoStart: false,
		onExpire: handleOnQuizEnd,
	  });

	useEffect(()=>{
		if (isRunning) {
			start();
		} else {
			pause();
		}
	}, [isRunning]);

	useEffect(() => {
		console.log(totalSeconds)
		console.log(totalSecondsLimit)
		console.log(totalSeconds / totalSecondsLimit)
		setProgress((100.0 * totalSeconds) / totalSecondsLimit);
	}, [totalSeconds])

  	return (
		<h4 className={styles['quiz-timer']}>
    		{hours > 9 ? hours : '0' + hours}:{minutes > 9 ? minutes : '0' + minutes}:{seconds > 9 ? seconds : '0' + seconds}
		</h4>
  	)
}

export default Timer