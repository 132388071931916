
/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
// import Dialog from '@mui/material/Dialog';
// import DialogContent from '@mui/material/DialogContent';
import VirtualTA from './VirtualTA';



const useStyles = makeStyles(() => ({
    floatingButton: {
        position: 'fixed',
        bottom: '25px',
        right: '20px',
        zIndex: 990,
        height: '3em',
    },
    // dialogBox: {
    //     position: 'fixed',
    //     top: 'calc(100% + 15px)',
    //     right: 'calc(100% + 15px)',
    //     zIndex: 9998,
    // },
    floatingButtonOpen:{
        position: 'fixed',
        bottom: '25px',
        right: '20px',
        zIndex: 990,
        height: '3em',
        backgroundColor: 'red',
        color: 'white',
    },
    sidebar: {
        position: 'fixed',
        marginTop:'100px',
        marginRight:'20px',
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#f7f7f7',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        transition: 'transform 0.2s ease-in-out',
        overflowY: 'auto',
        zIndex: 1101,
        width: '400px', // Set a default width
        borderRadius:'10px',
        borderTop:'5px solid #3f51b5',
        borderBottom:'5px solid #3f51b5',
    },
    sidebarOpen: {
        transform: 'translateX(0)',
        marginBottom:'75px',
        marginTop:'100px',
        marginRight:'20px',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
    },
    widthButton: {
        position: 'absolute',
        top: '10px',
        right: '60px',
        // zIndex: 9999,
    },
    virtualTAMobile: {
        width: '20px',
    },
    // dialogPaper: {
    //     position: 'fixed',
    //     top: 'calc(100% + 1500px)', 
    //     right: 'calc(100% + 1500px)', 
    //     zIndex: 1102,
    // },
    loadingContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
}));

 
function FloatingButton() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(<ChatIcon />);
    // const [dialogOpen, setdialogOpen]= useState(false);
    const overlayRef = useRef(null);
    

    
    const handleButtonClick = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };
    

    useEffect(() => {
        setIsMobile(window.innerWidth <= 480);
    }, []);

    useEffect(() => {
        setButtonIcon(open ? <CloseIcon /> : <ChatIcon />);
    }, [open]);

    const sidebarStyle = {
        transform: open ? 'translateY(0)' : 'translateY(100%)',
    };

    return (
        <>
            {/* <Dialog 
                open={dialogOpen} 
                onClose={handledialogToggle} 
                PaperProps={{className: classes.dialogBox}}
            >
                <DialogContent>
                    Consult your Virtual Assistant
                </DialogContent>
            </Dialog> */}
            <Button
                className={classes.floatingButton}
                variant="contained"
                color="primary"
                onClick={handleButtonClick}
            >
                {buttonIcon}
            </Button>
            {open && <div className={classes.overlay} ref={overlayRef} />}
            
            <div
                id="sidebar"
                className={`${classes.sidebar} ${
                    open ? classes.sidebarOpen : ''
                }`}
                style={sidebarStyle}
            >
                <Button className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </Button>
                {isMobile ? (
                    <VirtualTA
                        handleClose={handleClose}
                        className={classes.virtualTAMobile}
                    />
                ) : (
                    <VirtualTA handleClose={handleClose} />
                )}
                
            </div>
            
        </>
    );
}

export default FloatingButton;
