/*eslint-disable*/

import axios from 'axios';
import {
    SEND_REQUEST_DETAIL, UPDATE_NAME, UPDATE_DESC, UPDATE_LINKED_IN, UPDATE_EMAIL, UPDATE_TITLE, UPDATE_AFFILIATION
} from './upgradeTypes';

export const sendAllData = (userName, userEmail, userLinkedin, userDescription, userTitle, userAffiliation) => async (dispatch) => {
    const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}/upgradereq`, {
        name: userName,
        email: userEmail,
        linkedin: userLinkedin,
        title: userTitle,
        description: userDescription,
        affiliation: userAffiliation,
    })
    console.log(data);
}

export const upgradeNameError = () => ({
    type: UPDATE_NAME_ERROR,
});

export const updateName = (name) => ({
    type: UPDATE_NAME,
    payload: name,
});

export const updateDesc = (desc) => ({
    type: UPDATE_DESC,
    payload: desc,
});

export const updateLinkedIn = (linkedin) => ({
    type: UPDATE_LINKED_IN,
    payload: linkedin,
});

export const updateTitle = (title) => ({
    type: UPDATE_TITLE,
    payload: title,
});

export const updateEMail = (email) => ({
    type: UPDATE_EMAIL,
    payload: email,
});

export const updateAffiliation = (affiliation) => ({
    type: UPDATE_AFFILIATION,
    payload: affiliation,
});

export default{
    sendAllData,
    updateName,
    updateDesc,
    updateLinkedIn,
    updateTitle,   
    updateEMail,
    updateAffiliation,
}