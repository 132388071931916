/* eslint-disable */

import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import DisplayAccount from './DisplayAccount'
import Routes from '../../../../Routes'
import StudentCard from './StudentCard'
import { BrowserRouter as Router, Link } from 'react-router-dom';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export default function StudentDetailsModal({ student }) {
    const [open, setOpen] = useState(false)
    console.log("Inside the details modal");
    console.log(student);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);

    const [LikeList, setLikeList] = useState('')

    const renderLikeList = (studentName, studentEmail) => {
        studentEmail = 'mailto:' + studentEmail
        return <StudentCard student={student} />
    }

    const handleLeave = () => {
        setTimeout(() => {
            setLikeList('')
        }, 0)
    }

    const handleHover = (studentName, studentEmail) => {
        setTimeout(() => {
            setLikeList(renderLikeList(studentName, studentEmail))
        }, 0)
    }

    // On hover

    return (
        <Router>
        <div>
        
            <div
                // href="javascript:void(0)"
                // onClick={() => {
                //     return <DisplayAccount user = {student}/>
                // }}
                onMouseOver={() => handleHover(student.name, student.email)}
                onMouseLeave={handleLeave}
            >
                <Link to ={{ pathname : "/display-account/" + student.email}}>
                <a
                    href=""
                    className="likes__relavance"
                    onClick={() => {
                        setTimeout(() => {
                            // Reload the current page
                            window.location.reload();
                          }, 500);
                    }}
                    onMouseOver={() => handleHover(student.name, student.email)}
                    onMouseLeave={handleLeave}
                >
                    {student.name}
                </a>
                </Link>
                <div className="likes__relavance">{LikeList}</div>
            </div>
        </div>
        </Router>
    )
}

// StudentDetailsModal.propTypes = {
//   student: PropTypes.string.isRequired,
// }
