/*eslint-disable*/
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationCard from './NotificationCard';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ Notifs }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [LikeList, setLikeList] = React.useState('');

  const renderLikeList = (studentName, studentEmail) => {
    studentEmail = 'mailto:' + studentEmail;
    return (
      <NotificationCard Notifications = {Notifs} />
    );
  };

  const handleLeave = () => {
    setTimeout(() => {
      setLikeList('');
    }, 0);
  }

  const handleHover = (studentName, studentEmail) => {
    setTimeout(() => {
      setLikeList(renderLikeList(studentName, studentEmail));
    }, 0);
  }

  return (
    <div>
      <div
        href="javascript:void(0)"
        onMouseOver={() => handleHover("hi", "hi")}
        onMouseLeave={handleLeave}
      >
        <Button className="likes__relavance" onMouseOver={() => handleHover("hi", "hi")} onMouseLeave={handleLeave}>
        <NotificationsIcon style={{ color: 'white' }}/>
        </Button>
        <div className="likes__relavance">
          {LikeList}
        </div>
      </div>
    </div>
  );
}
/*eslint-disable*/