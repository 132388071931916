/* eslint-disable */

import {
    SEND_REQUEST_DETAIL,
    UPDATE_NAME,
    UPDATE_DESC,
    UPDATE_LINKED_IN,
    UPDATE_EMAIL,
    UPDATE_TITLE,
    UPDATE_AFFILIATION,
    UPDATE_NAME_ERROR,
    UPDATE_DESC_ERROR,
    UPDATE_LINKED_IN_ERROR,
    UPDATE_EMAIL_ERROR,
    UPDATE_TITLE_ERROR,
    UPDATE_AFFILIATION_ERROR,
} from './upgradeTypes'

const initialstate = {
    Name: '',
    Linkedin: '',
    Email: '',
    Description: '',
    Title: '',
    Affiliation: '',
}

const upgradeReducer = (state = initialstate, action) => {
    switch (action.type) {
        case UPDATE_NAME:
            return {
                ...state,
                Name: action.payload,
            }
        case UPDATE_NAME_ERROR:
            return state
        case UPDATE_LINKED_IN:
            return {
                ...state,
                Linkedin: '',
            }
        case UPDATE_LINKED_IN_ERROR:
            return state
        case UPDATE_EMAIL:
            return {
                ...state,
                Email: action.payload,
            }
        case UPDATE_EMAIL_ERROR:
            return state
        case UPDATE_DESC:
            return {
                ...state,
                Description: action.payload,
            }
        case UPDATE_DESC_ERROR:
            return state
        case UPDATE_TITLE:
            return {
                ...state,
                Title: action.payload,
            }
        case UPDATE_TITLE_ERROR:
            return state
        case UPDATE_AFFILIATION:
            return {
                ...state,
                Affiliation: action.payload,
            }
        case UPDATE_AFFILIATION_ERROR:
            return state
        default:
            return state
    }
}

export default upgradeReducer
