export const SEND_REQUEST_DETAIL = 'SEND_REQUEST_DETAIL'
export const UPDATE_NAME = 'UPDATE_NAME'
export const UPDATE_DESC = 'UPDATE_DESC'
export const UPDATE_LINKED_IN = 'UPDATE_LINKED_IN'
export const UPDATE_EMAIL = 'UPDATE_EMAIL'
export const UPDATE_TITLE = 'UPDATE_TITLE'
export const UPDATE_AFFILIATION = 'UPDATE_AFFILIATION'
export const UPDATE_NAME_ERROR = 'UPDATE_NAME_ERROR' 
export const  UPDATE_DESC_ERROR = 'UPDATE_DESC_ERROR'
export const  UPDATE_LINKED_IN_ERROR = 'UPDATE_LINKED_IN_ERROR'
export const  UPDATE_EMAIL_ERROR = 'UPDATE_EMAIL_ERROR'
export const  UPDATE_TITLE_ERROR = 'UPDATE_TITLE_ERROR'
export const  UPDATE_AFFILIATION_ERROR = 'UPDATE_AFFILIATION_ERROR'
 