/* eslint-disable */

import React, { useState } from 'react';
import { Container, Paper, TextField, Button, Typography, Select, MenuItem, FormControl, InputLabel, CircularProgress} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import ManageJSON from './previewJSONQuestions'
import { addQuestion } from '../../redux/QuestionBank/questionBankActions'
import { generateQuiz } from '../../redux';

const QuizForm = () => {
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const [quizTitle, setQuizTitle] = useState('');
    const [concept, setConcept] = useState('');
    const [quizSynopsis, setQuizSynopsis] = useState('');
    const [questionCount, setQuestionCount] = useState(1);
    const [difficulty, setDifficulty] = useState('');
    const [questionType, setQuestionType] = useState('');
    const [generatedQuestions, setGeneratedQuestions] = useState(null);
    const [loading, setLoading] = useState(false);
    const [previewQuestions, setPreviewQuestions] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('')
    const categoryData = useSelector((state) => state.categoryData)
    const quizStateData = useSelector((state) => state.quizData)

    const handleGenerate = async () => {
        const quizData = {
        quizTitle,
        concept,
        quizSynopsis,
        questionCount,
        difficulty,
        questionType
        };

        setLoading(true);

        try {
            // const response = await fetch(`${process.env.REACT_APP_BASE_URL}/quiz/generate-quiz`, {
            //     method: 'POST',
            //     headers: {
            //     'Content-Type': 'application/json'
            //     },
            //     body: JSON.stringify(quizData)
            // });

            // const result = await response.json();

            await dispatch(generateQuiz(quizData));
            const result = quizStateData.quiz;
            console.log('Quiz Generated:', result);
            setGeneratedQuestions(result);
            const questions_generated = result.data.questions
            setPreviewQuestions([...questions_generated])
            console.log(questions_generated)
        } catch (error) {
        console.error('Error generating quiz:', error);
        } finally {
            setLoading(false); // Set loading to false when request is finished
        }
    };

    const handleSubmit = async () => {
        console.log("Submit Clicked")
        if (!selectedCategory) {
            alert('Please select the category.')
        } else {
            const Questions = previewQuestions.map(({ _id, answers, ...question }) => ({
                ...question,
                answers: answers.map(({ _id, ...answer }) => answer)
            }))

            await dispatch(
                addQuestion({
                    Questions,
                    selectedCategory,
                })
            )
        }
        setGeneratedQuestions([])
        setPreviewQuestions([])
    }

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value)
    }

    const handleQuestionUpdate = (updatedQuestions) => {
        setPreviewQuestions(updatedQuestions)
    }

    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} style={{ marginTop: 20, padding: 20 }}>
        <Typography variant="h5" gutterBottom>
          Generate Quiz
        </Typography>

        <TextField
          label="Quiz Title"
          fullWidth
          margin="normal"
          value={quizTitle}
          onChange={(e) => setQuizTitle(e.target.value)}
        />
        
        <TextField
          label="Topics"
          fullWidth
          margin="normal"
          value={concept}
          onChange={(e) => setConcept(e.target.value)}
        />

        <TextField
          label="Quiz Synopsis"
          fullWidth
          margin="normal"
          value={quizSynopsis}
          onChange={(e) => setQuizSynopsis(e.target.value)}
        />

        <TextField
          label="Number of Questions"
          type="number"
          fullWidth
          margin="normal"
          value={questionCount}
          onChange={(e) => setQuestionCount(e.target.value)}
          inputProps={{ min: 1 }}
        />

        <FormControl fullWidth>
            <InputLabel>Select Question Bank Category</InputLabel>
            <Select
                value={selectedCategory}
                onChange={handleCategoryChange}
            >
                {categoryData.categoryData.map((category) => (
                    <MenuItem
                        key={category._id}
                        value={category._id}
                    >
                        {category.categoryName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Difficulty</InputLabel>
          <Select
            value={difficulty}
            onChange={(e) => setDifficulty(e.target.value)}
          >
            <MenuItem value="Easy">Easy</MenuItem>
            <MenuItem value="Intermediate">Intermediate</MenuItem>
            <MenuItem value="Advanced">Advanced</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth margin="normal">
          <InputLabel>Question Type</InputLabel>
          <Select
            value={questionType}
            onChange={(e) => setQuestionType(e.target.value)}
          >
            <MenuItem value="mcq">Multiple Choice</MenuItem>
            <MenuItem value="TF">True/False</MenuItem>
          </Select>
        </FormControl>

        <Button variant="contained" color="primary" onClick={handleGenerate}>
          Generate Quiz
        </Button>

      {loading && (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <CircularProgress />
          </div>
        )}

        {generatedQuestions && (
            <ManageJSON
            ques = {previewQuestions}
            onUpdate={handleQuestionUpdate}
            />
        )}

        {generatedQuestions && generatedQuestions.length !== 0 && (
            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
            >
                Submit
            </Button>
        )}

      </Paper>

      {/* {generatedQuestions && (
        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
          <Typography variant="h6">Generated Questions</Typography>
          <pre>{JSON.stringify(generatedQuestions, null, 2)}</pre>
            
        </Paper>
      )} */}

    </Container>
  );
}

export default QuizForm;
