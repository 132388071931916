/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import Avatar from '@mui/material/Avatar'
import { logout } from '../redux';
import { useHistory } from 'react-router';
import {
    Stack,
    Grid,
    IconButton,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Tooltip,
    Typography,
    TextField,
    Checkbox,
    FormControlLabel,
    Link
} from '@mui/material'
import { lightBlue } from '@mui/material/colors'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import axios from 'axios'
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import MyAccountForm from './MyAccountForm'
import {
    enableCanvasAccess,
    disableCanvasAccess,
    updateDescription,
    addLink,
    updateLinks,
    getCurrentUser,
    getUserLinks,
} from '../redux'
import './style2.css'

export default function MyAccountPage({ user }) {
    const dispatch = useDispatch()
    // console.log(user.role);
    const userData = useSelector((state) => state.userData)
    const userLinks = useSelector((state) => state.linkData)
    const [flag, setFlag] = useState(false)
    const [studentLinks, setStudentLinks] = useState([])
    const history = useHistory();
    useEffect(() => {
        const fetchData = () => {
            if (
                userData.userFetchCompleted &&
                userData.user &&
                userData.user.id !== undefined
            ) {
                dispatch(getUserLinks())
            }
        }

        fetchData()
        // console.log(userLinks)
    }, [dispatch, userData.userFetchCompleted, userData.user, flag])

    useEffect(() => {
        // console.log('userLinks updated:', userLinks)
        setStudentLinks(
            userLinks && userLinks.linkData && userLinks.linkData.links
                ? userLinks.linkData.links
                : []
        )
    }, [userLinks])

    const name =
        userData && userData.user && userData.user.firstName === 'Admin'
    const id = userData && userData.user && userData.user.id
    const checkUser =
        userData && userData.user && userData.user.role === 'Instructor'
    const role = userData && userData.user && userData.user.role
    const description = userData && userData.user && userData.user.description

    // const links = userData && userData.user && userData.user.links
    // const links =
    //     userLinks && userLinks.linkData && userLinks.linkData.links
    //         ? userLinks.linkData.links
    //         : []

    // console.log(links_data)

    const phone = userData && userData.user && userData.user.phone
    const upgradeRequest =
        userData && userData.user && userData.user.upgradeRequest

    const [descriptionEdit, setDescriptionEdit] = useState(false)
    const [studentDescription, setStudentDescription] = useState(description)

    const [isViewable, setIsViewable] = React.useState(true)
    const [canvas, setCanvas] = React.useState(user.canvasAccess)

    // setStudentDescription(description);

    const [isFormVisible, setIsFormVisible] = useState(false)
    const [title, setTitle] = useState('')
    const [link, setLink] = useState('')
    const [editMode, setEditMode] = useState(false)
    const [editedLinkId, setEditedLinkId] = useState(null)
    const [editedTitle, setEditedTitle] = useState('')
    const [editedLink, setEditedLink] = useState('')

    const handleAddClick = () => {
        setIsFormVisible(true)
    }
    const [isAlertOpen,setIsAlertOpen] = useState(false);
    const handleContClick = async ()=>{
        setIsAlertOpen(false);
        console.log(user.email);
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/user-deletion/${user.email}`);
    dispatch(logout())
    alert("User deleted");
    history.push("/login");
        
    }
    const handleAccDelClick = async ()=>{
        setIsAlertOpen(true);
        
    }
    const handleClose = ()=>{
        setIsAlertOpen(false);
    }

    const handleSubmit = async () => {
        if (title.trim() === '' || link.trim() === '') {
            alert('Both title and link are required.')
            return
        } else {
            const newLink = {
                title: title,
                link: link,
            }
            await dispatch(addLink(id, newLink))
            setFlag(!flag)
        }
        setIsFormVisible(false)
        setTitle('')
        setLink('')
    }

    const handleCancel = () => {
        setIsFormVisible(false)
        setTitle('')
        setLink('')
    }

    const handleEdit = (id) => {
        // console.log(id)
        const linkToEdit = studentLinks.find((link) => link._id === id)
        if (linkToEdit) {
            setEditMode(true)
            setEditedLinkId(id)
            setEditedTitle(linkToEdit.title)
            setEditedLink(linkToEdit.link)
        }
    }

    const handleDelete = async (identity) => {
        const confirmed = window.confirm(
            'Are you sure you want to delete this link?'
        )
        if (confirmed) {
            const updatedLinks = studentLinks.filter(
                (link) => link._id !== identity
            )
            await dispatch(updateLinks(id, updatedLinks))
            setFlag(!flag)
        }
    }

    const handleEditSubmit = async () => {
        if (editedTitle.trim() === '' || editedLink.trim() === '') {
            alert('Both title and link are required.')
            return
        }
        const updatedLinks = studentLinks.map((link) =>
            link._id === editedLinkId
                ? { ...link, title: editedTitle, link: editedLink }
                : link
        )
        await dispatch(updateLinks(id, updatedLinks))
        setFlag(!flag)
        setEditedLinkId(null)
        setEditedTitle('')
        setEditedLink('')
    }

    const handleEditCancel = () => {
        setEditMode(false)
        setEditedLinkId(null)
        setEditedTitle('')
        setEditedLink('')
    }

    const enableCanvasRequest = async (id, dispatch) => {
        dispatch(enableCanvasAccess(id))
    }

    const disableCanvasRequest = async (id, dispatch) => {
        dispatch(disableCanvasAccess(id))
    }

    const handleEditButtonClick = () => {
        dispatch(updateDescription(id, studentDescription))
        setDescriptionEdit(!descriptionEdit)
    }
    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' })
        }
    }

    if (userLinks && !userLinks.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }

    if (userLinks.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={userLinks.loadingMessage} />
            </div>
        )
    }

    return (
        <section>
            <div className="card mb-2" style={{ borderRadius: '.5rem' }}>
                <div className="row g-5">
                    <div
                        className="col-md-4 gradient-custom text-right text-white"
                        style={{
                            borderTopLeftRadius: '.5rem',
                            borderBottomLeftRadius: '.5rem',
                            padding: '30px',
                        }}
                    >
                        <div
                            className="img-fluid my-5"
                            style={{ width: '80px', textAlign: 'center' }}
                        >
                            <Avatar
                                sx={{
                                    bgcolor: lightBlue[50],
                                    width: 120,
                                    height: 120,
                                    marginLeft: 15,
                                    marginRight: 15,
                                    paddingTop: 1,
                                }}
                            >
                                <h2 style={{ color: 'black' }}>
                                    {user
                                        ? user.firstName[0] + user.lastName[0]
                                        : ' '}
                                </h2>
                            </Avatar>
                        </div>
                        <hr
                            style={{ borderColor: 'white' }}
                            className="mt-0 mb-4"
                        />
                        <h4>{user.firstName + ' ' + user.lastName}</h4>
                        {user.role == 'Student' ? (
                            <div>
                                <Tooltip title="Click the text to ask for instructor access">
                                    <Button
                                        variant="contained"
                                        style={{ color: 'white' }}
                                        className="link1"
                                        component={Link}
                                        to="#upgrade-form"
                                        onClick={() => {
                                            if (upgradeRequest)
                                                alert(
                                                    'You have already requested once. Your request is under review.'
                                                )
                                            else {
                                                setIsViewable(false)
                                                scrollToElement('upgrade-form')
                                            }
                                        }}
                                    >
                                        Request
                                    </Button>
                                </Tooltip>
                                <a
                                    onClick={() => {
                                        if (upgradeRequest)
                                            alert(
                                                'You have already requested once. Your request is under review.'
                                            )
                                        setIsViewable(false)
                                    }}
                                    className="link1"
                                    href="#upgrade-form"
                                >
                                </a>
                                Instructor Access : No
                            </div>
                        ) : (
                            <p>Instructor Access: Yes</p>
                        )}

                        <br />
                        <div className="mb-3">
                            <h5>Email</h5>
                            <p className="text" style={{ color: 'white' }}>
                                {user.email}
                            </p>
                        </div>
                        <div className="mb-3"></div>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={canvas}
                                    onChange={() => {
                                        if (canvas)
                                            disableCanvasRequest(
                                                user.id,
                                                dispatch
                                            )
                                        else
                                            enableCanvasRequest(
                                                user.id,
                                                dispatch
                                            )
                                        setTimeout(() => {
                                            window.location.reload()
                                        }, 1000)
                                    }}
                                />
                            }
                            label="Canvas access"
                        />

                        <div 
                            className='delete-acc-btn' 
                        >
                            <Button onClick={handleAccDelClick} variant="contained" color="error" startIcon={<DeleteIcon />}>
                                Delete
                            </Button>
                        </div>
                        <Dialog
          open={isAlertOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {'Are you sure?'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Do you want to delete your account?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleContClick} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
                    </div>
                    <div className="col-md-8">
                        <div className="card-body p-4">
                            <h5>Profile</h5>
                            <hr className="mt-0 mb-4" />

                            <br />

                            <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Grid item>
                                    <Typography
                                        variant="h5"
                                        color={'black'}
                                        fontSize={'1.5rem'}
                                        fontWeight={'bold'}
                                    >
                                        About
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        color="primary"
                                        onClick={handleEditButtonClick}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                            <Divider />

                            <hr className="mt-0 mb-4" />

                            {descriptionEdit ? (
                                <TextField
                                    fullWidth
                                    helperText={`${studentDescription.length}/512 characters`}
                                    id="description-edit"
                                    inputProps={{
                                        style: {
                                            padding: 0,
                                            minHeight: '23.5%',
                                        },
                                    }}
                                    multiline
                                    onBlur={handleEditButtonClick}
                                    onChange={(e) =>
                                        setStudentDescription(e.target.value)
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                    rows={4}
                                    value={studentDescription}
                                    variant="filled"
                                />
                            ) : (
                                <p>
                                    {studentDescription ||
                                        'No description available'}
                                </p>
                            )}

                            <br />
                            <Stack>
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Grid item>
                                        <Typography
                                            variant="h5"
                                            color={'black'}
                                            fontSize={'1.5rem'}
                                            fontWeight={'bold'}
                                        >
                                            Links
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            color="primary"
                                            onClick={handleAddClick}
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>

                                <Divider />
                                <hr className="mt-0 mb-4" />
                                {studentLinks.length > 0 ? (
                                    <TableContainer component={Paper} center>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">
                                                        <Typography
                                                            variant="h6"
                                                            fontWeight="bold"
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid black',
                                                            }}
                                                        >
                                                            Title
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <Typography
                                                            variant="h6"
                                                            fontWeight="bold"
                                                            style={{
                                                                borderBottom:
                                                                    '1px solid black',
                                                            }}
                                                        >
                                                            Link
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {/* <Typography
                                                        variant="h6"
                                                        fontWeight="bold"
                                                        style={{
                                                            borderBottom:
                                                                '2px solid black',
                                                        }}
                                                    >
                                                        Actions
                                                    </Typography> */}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {studentLinks.map(
                                                    (studentLink, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell align="center">
                                                                {editMode &&
                                                                    editedLinkId ===
                                                                    studentLink._id ? (
                                                                    <TextField
                                                                        label="Title"
                                                                        variant="outlined"
                                                                        value={
                                                                            editedTitle
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setEditedTitle(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <Typography variant="body1">
                                                                        {
                                                                            studentLink.title
                                                                        }
                                                                    </Typography>
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {editMode &&
                                                                    editedLinkId ===
                                                                    studentLink._id ? (
                                                                    <TextField
                                                                        label="Link"
                                                                        variant="outlined"
                                                                        value={
                                                                            editedLink
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            setEditedLink(
                                                                                e
                                                                                    .target
                                                                                    .value
                                                                            )
                                                                        }
                                                                    />
                                                                ) : (
                                                                    
                                                                    <a href={studentLink.link}> {studentLink.link} </a>
                                                                )}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {editMode &&
                                                                    editedLinkId ===
                                                                    studentLink._id ? (
                                                                    <>
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() =>
                                                                                handleEditSubmit(
                                                                                    studentLink._id
                                                                                )
                                                                            }
                                                                        >
                                                                            Submit
                                                                        </Button>
                                                                        &nbsp;
                                                                        &nbsp;
                                                                        <Button
                                                                            variant="outlined"
                                                                            onClick={() =>
                                                                                handleEditCancel(
                                                                                    studentLink._id
                                                                                )
                                                                            }
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                handleEdit(
                                                                                    studentLink._id
                                                                                )
                                                                            }
                                                                        >
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            onClick={() =>
                                                                                handleDelete(
                                                                                    studentLink._id
                                                                                )
                                                                            }
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                ) : isFormVisible ? null : (
                                    <p>No links for the current user.</p>
                                )}
                                {isFormVisible && (
                                    <Stack spacing={2} mt={2}>
                                        <Grid
                                            container
                                            spacing={2}
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <TextField
                                                    label="Title"
                                                    variant="outlined"
                                                    value={title}
                                                    onChange={(e) =>
                                                        setTitle(e.target.value)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item mr={'2rem'}>
                                                <TextField
                                                    label="Link"
                                                    variant="outlined"
                                                    value={link}
                                                    onChange={(e) =>
                                                        setLink(e.target.value)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                >
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleSubmit}
                                                    >
                                                        Submit
                                                    </Button>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={handleCancel}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Stack>
                                )}
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>
            <div id="upgrade-form">
                {name || checkUser || upgradeRequest || isViewable || (
                    <MyAccountForm student={user} />
                )}
            </div>

            <br />
            <br />
        </section>
    )
}

/*eslint-disable*/
