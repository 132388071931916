/*eslint-disable*/
import React, { useState } from 'react';
import { Button, Divider, Fab, Skeleton, Tab, Tabs, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import tabStyles from '../Tab.module.css';
import styles from './Syllabus.module.css';
import RTE from '../../../commons/RTE';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import UploadFileModal from './UploadFileModal';

const Syllabus = ({isLoading}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [syllabusText, setSyllabusText] = useState('Enter syllabus text');
	const [isUploadFileModalOpen, setIsUploadFileModalOpen] = useState(false);
	
	const closeFileModal = () => {
		setIsUploadFileModalOpen(false);
	}

    return (
		<div className={`${tabStyles['tab-container']}`}>
			<div className={`${tabStyles['tab-heading']}`}>
                Syllabus
			</div>
            <Divider />
			<div className={`${styles['container']}`}>
				<div className={`${styles['syllabus-container']}`}>
					<div className={`${styles['container-heading']}`}>
						Description
						<Tooltip title="Edit Syllabus">
							<Fab aria-label="add" color="primary" disabled={isEditing} onClick={()=>setIsEditing(true)}>
								<EditIcon disabled={isEditing} />
							</Fab>
						</Tooltip>
					</div>	
					<Divider variant='middle' />
					<div className={styles['main-text-container']}>
						{
							isEditing ? 
							(
								<>
									<RTE value={syllabusText} setValue={setSyllabusText} placeholder={'Enter syllabus'} />
									<Button className={styles['save-btn']} onClick={()=>setIsEditing(false)} variant='contained'>Save</Button>
								</>
							) : (
								<div dangerouslySetInnerHTML={{__html: syllabusText}} />
							)
						}
					</div>
				</div>
				<Divider orientation='vertical' flexItem />
				<div className={`${styles['files-container']}`}>
					<div className={`${styles['container-heading']}`}>
						Files
						<Tooltip title="Upload file">
							<Fab aria-label="add" color="primary" disabled={isLoading} onClick={()=>setIsUploadFileModalOpen(true)}>
								<UploadFileIcon disabled={isEditing} />
							</Fab>
						</Tooltip>
					</div>	
					<Divider variant='middle' />
				</div>

			</div>
			<UploadFileModal 
				open={isUploadFileModalOpen} 
				handleClose={closeFileModal} 
			/>
		</div>
	)
}

export default Syllabus