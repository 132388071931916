/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react'
import {
    Button,
    Container,
    Typography,
    Paper,
    Select,
    MenuItem,
    Stack,
    FormControl,
    InputLabel,
    Box,
    Alert,
    Snackbar,
    TextField,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { addQuestion } from '../../redux/QuestionBank/questionBankActions'
import {
    fetchSavedCoursesMeta,
    fetchQuizCategories,
    addQuizCategory,
    deleteQuizCategory,
    editQuizCategory,
    fetchAllQuizQuestions,
    fetchAllQuestions,
} from '../../redux'
import CategoryManagement from './ManageCategories'
import QuestionManagement from './ManageQuestions'
import ManageJSON from './previewJSONQuestions'
import QuizForm from './generateQuiz'

function UploadQuizQuestion() {
    const fileInputRef = useRef(null)
    const dispatch = useDispatch()
    const userData = useSelector((state) => state.userData)
    const savedCourseData = useSelector((state) => state.savedCourseData)
    const categoryData = useSelector((state) => state.categoryData)
    const questionBank = useSelector((state) => state.questionBankData)
    console.log(questionBank)
    const [flag, setFlag] = useState(true)
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState('')
    const [successAlertOpen, setSuccessAlertOpen] = useState(false)
    const [errorAlertOpen, setErrorAlertOpen] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [previewQuestions, setPreviewQuestions] = useState([])
    const [jsonDataTest, setJsonDataTest] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            dispatch(fetchSavedCoursesMeta())
            dispatch(fetchQuizCategories())
            dispatch(fetchAllQuestions())
            dispatch(fetchAllQuizQuestions())
        }
    }, [dispatch, userData.userFetchCompleted, userData.user, flag])

    useEffect(() => {
        if (questionBank && questionBank.status != null) {
            setSuccessAlertOpen(true)
        } else if (questionBank && questionBank.error != null) {
            setErrorAlertOpen(true)
        }
    }, [questionBank])

    // Preview JSON Functions
    const handleQuestionUpdate = (updatedQuestions) => {
        setPreviewQuestions(updatedQuestions)
    }

    const addQuestionToBank = (dispatch, question) =>
        dispatch(addQuestion(question))

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0]
        setSelectedFile(selectedFile)
        const reader = new FileReader()
        reader.onload = (e) => {
            try {
                const jsonData = JSON.parse(e.target.result)
                setJsonDataTest(jsonData.data.questions)
                setPreviewQuestions(jsonData.data.questions)
                setErrorMessage(null)
            } catch (error) {
                setJsonDataTest(null)
                setErrorMessage(
                    'Error parsing JSON file. Please ensure the file is valid JSON.'
                )
            }
        }
        reader.readAsText(selectedFile)
    }
    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value)
    }

    const handleSuccessAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setSuccessAlertOpen(false)
        setSelectedFile(null)
        setSelectedCategory('')
    }

    const handleErrorAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setErrorAlertOpen(false)
        setSelectedFile(null)
        setSelectedCategory('')
    }

    const handleSubmit = () => {
        if (!selectedCategory) {
            alert('Please select the category.')
        } else if (errorMessage || !selectedFile) {
            alert('Please select the correct file.!')
        } else {
            const fileInput = fileInputRef.current
            const file = fileInput.files[0]

            if (file) {
                const reader = new FileReader()
                let Questions

                reader.onload = async (event) => {
                    const content = event.target.result
                    const Questions = previewQuestions.map(({ _id, answers, ...question }) => ({
                        ...question,
                        answers: answers.map(({ _id, ...answer }) => answer)
                    }))

                    await dispatch(
                        addQuestion({
                            Questions,
                            selectedCategory,
                        })
                    )
                    console.log(questionBank, '----')

                    // Reset the file input value
                    fileInput.value = ''
                    setJsonDataTest(null)
                }

                reader.readAsText(file)
            }
        }
    }

    const handleAddCategory = (newCategory) => {
        try {
            dispatch(addQuizCategory(newCategory))
            setFlag(!flag)
            dispatch(fetchQuizCategories())
        } catch (error) {
            console.error('Error adding category:', error)
        }
    }

    const handleEditCategory = (categoryId, updatedCategory) => {
        try {
            dispatch(editQuizCategory(categoryId, updatedCategory))
            setFlag(!flag)
            dispatch(fetchQuizCategories())
        } catch (error) {
            console.error('Error editing category:', error)
        }
    }

    const handleDeleteCategory = (categoryId) => {
        try {
            dispatch(deleteQuizCategory(categoryId))
            setFlag(!flag)
            dispatch(fetchQuizCategories())
        } catch (error) {
            console.error('Error deleting category:', error)
        }
    }

    return (
        <Container style={{ marginBottom: '50rem' }}>
            <QuizForm/>
            <Paper elevation={3} style={{ marginTop: 20, padding: 20 }}>
                <Typography variant="h5">
                    Upload Questions To Question Bank
                </Typography>

                <Stack style={{ marginTop: 10 }} maxWidth={'20rem'} spacing={2}>
                    <FormControl fullWidth>
                        <label>Select Question Bank Category</label>
                        <Select
                            label=""
                            value={selectedCategory}
                            onChange={handleCategoryChange}
                        >
                            {categoryData.categoryData.map((category) => (
                                <MenuItem
                                    key={category._id}
                                    value={category._id}
                                >
                                    {category.categoryName}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>

                <Box mt={2}>
                    <Box display="flex" alignItems="center">
                        <input
                            accept=".json"
                            ref={fileInputRef}
                            id="upload-json"
                            type="file"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                        />
                        <label htmlFor="upload-json">
                            <Button variant="contained" component="span">
                                Upload JSON
                            </Button>
                        </label>
                        {selectedFile && (
                            <Typography variant="body1" ml={1}>
                                {selectedFile.name}
                            </Typography>
                        )}
                    </Box>
                    {errorMessage && (
                        <div style={{ marginTop: '0.5rem' }}>
                            <Typography
                                variant="body2"
                                style={{ color: 'red' }}
                            >
                                {errorMessage}
                            </Typography>
                        </div>
                    )}
                </Box>

                {jsonDataTest && (
                    <Stack spacing={30} direction={'row'} marginBottom={10}>
                        <Container maxWidth="lg">
                            <Stack spacing={2}>
                                <h1>JSON Question Preview:</h1>
                                <Stack
                                    container
                                    spacing={2}
                                    paddingRight={2}
                                    maxHeight={'100%'}
                                    fullWidth
                                >
                                    <ManageJSON
                                        ques = {previewQuestions}
                                        onUpdate = {handleQuestionUpdate}
                                    />
                                </Stack>
                            </Stack>
                        </Container>
                    </Stack>
                )}

                <Box mt={2}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </Box>
            </Paper>
            <CategoryManagement
                categories={categoryData.categoryData}
                onAdd={handleAddCategory}
                onEdit={handleEditCategory}
                onDelete={handleDeleteCategory}
            />
            <Snackbar
                open={successAlertOpen}
                autoHideDuration={3000}
                onClose={handleSuccessAlertClose}
            >
                <Alert onClose={handleSuccessAlertClose} severity="success">
                    Questions added successfully!
                </Alert>
            </Snackbar>
            <Snackbar
                open={errorAlertOpen}
                autoHideDuration={3000}
                onClose={handleErrorAlertClose}
            >
                <Alert onClose={handleErrorAlertClose} severity="error">
                    There was an error adding questions!
                </Alert>
            </Snackbar>
            <QuestionManagement/>
        </Container>
    )
}

export default UploadQuizQuestion
