/* eslint-disable */

import React from 'react'

export * from './categories'

export const roundValueToDecimals = (value, decimalCount) =>
    Math.round(value * 10 ** decimalCount) / 10 ** decimalCount

export const validateEmail = (email) => {
    const re =
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
    return re.test(String(email).toLowerCase())
}
export const validateCategory = (category) => {
    if (
        !category ||
        category.trim().length === 0 ||
        category.trim().length > 30
    ) {
        return false
    }
    return true
}
const populateWeights = (totalCount) => new Array(totalCount).fill(1)

const getNormalizedWeights = (weights) => {
    const sumWeights = weights.reduce((sum, weight) => sum + weight, 0)
    return weights.map((weight) => (sumWeights === 0 ? 0 : weight / sumWeights))
}

export const getDistributedValuesTotaling = (
    total,
    valuesCount,
    decimalCount,
    weights = []
) => {
    let allWeights = weights
    if (!allWeights || allWeights.length !== valuesCount) {
        allWeights = populateWeights(valuesCount)
    }
    const normalizedWeights = getNormalizedWeights(allWeights)
    if (new Set(normalizedWeights).size === 1 && normalizedWeights[0] === 0) {
        return getDistributedValuesTotaling(total, valuesCount, decimalCount)
    }
    const distributedValues = []
    normalizedWeights.forEach((normalizedWeight) =>
        distributedValues.push(
            roundValueToDecimals(normalizedWeight * total, decimalCount)
        )
    )
    const minimumTolerance = roundValueToDecimals(0.1 ** decimalCount, 2)
    const differenceFromTotal = roundValueToDecimals(
        total - distributedValues.reduce((sum, val) => sum + val, 0),
        decimalCount
    )
    if (Math.abs(differenceFromTotal) >= minimumTolerance) {
        const differenceToAddPerValue = roundValueToDecimals(
            minimumTolerance *
                (differenceFromTotal / Math.abs(differenceFromTotal)),
            decimalCount
        )
        const decreasingValueIndexes = Array.from(
            Array(valuesCount).keys()
        ).sort((x, y) => distributedValues[y] - distributedValues[x])
        for (
            let i = 0;
            i < Math.abs(differenceFromTotal / minimumTolerance);
            i += 1
        ) {
            distributedValues[decreasingValueIndexes[i]] = roundValueToDecimals(
                distributedValues[decreasingValueIndexes[i]] +
                    differenceToAddPerValue,
                decimalCount
            )
        }
    }
    return distributedValues
}

const URL_REGEX =
    /(\b(?:https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim

export const addAnchorTagsForLinksInText = (text) =>
    text.split(URL_REGEX).map((part, index) =>
        URL_REGEX.test(part) ? (
            <a
                key={`${part}-${index}`} // Use a combination of the link and index for a unique key
                href={part}
                rel="noreferrer"
                target="_blank"
            >
                {part}
            </a>
        ) : (
            part
        )
    )
