/* eslint-disable */

import React, {Fragment, useState, useEffect } from 'react'
import {
    Button,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    IconButton,
    Stack,
    Paper,
    Typography,
    Radio,
    Card,
    CardContent,
    DialogActions,
    Grid,
    DialogTitle,
    DialogContent,
    Container,
    TextareaAutosize,
    MenuItem,
    Select,
    Dialog,
    Checkbox,
    ListItemText,
} from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'

import { Redirect } from 'react-router-dom'
import TopLoader from '../commons/TopLoader'
import { makeStyles } from '@mui/styles'
import AddIcon from '@mui/icons-material/Add'
import parse from 'html-react-parser'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material'
import PhotoCamera from '@material-ui/icons/PhotoCamera'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CloseIcon from '@material-ui/icons/Close'
import { fetchAllQuestions, fetchAllQuizQuestions, removeQuestion, updateQuestion, addQuestion } from '../../redux'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Chip from '@mui/material/Chip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, categoryName, theme) {
    return {
      fontWeight:
        categoryName && categoryName.split(',').indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

const useStyles = makeStyles({
    card: {
        border: '1px solid #e0e0e0',
        padding: 0,
    },
    title: {
        fontSize: 20,
        backgroundColor: '#f5f5f5',
        padding: 15,
        margin: 0,
        color: '#333',
    },
    question: {
        fontSize: 20,
        padding: 15,
        margin: 0,
        color: '#333',
    },
    input: {
        display: 'none',
    },
    previewImage: {
        maxWidth: '30%',
        maxHeight: '30%',
    },
    bodyItem: {
        border: '1px solid #e0e0e0',
        padding: 15,
        transition: 'background-color 0.3s ease', // smooth transition
        '&:hover': {
            backgroundColor: '#f0f8ff', // light gray on hover
        },
        margin: 0,
    },
})

// const QuestionManagement = ({allQuestions, fetchQuestions }) => {
const QuestionManagement = () => {
    const theme = useTheme()
    const userData = useSelector((state) => state.userData)
    const [questions, setQuestions] = useState([])
    const questionBank = useSelector((state) => state.questionBankData)
    const quizQuestion = useSelector((state) => state.quizQuestionData)
    const [showAll, setShowAll] = useState(false)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [edit, setEdit] = useState(0)
    // const [mountFiles, setMountFiles] = useState({})

    

  const isAdminAccount = userData && userData.user && userData.user.email === process.env.REACT_APP_ADMIN_EMAIL_ADDRESS;
    const [selectedCategory, setSelectedCategory] = useState('')
    const categoryData = useSelector((state) => state.categoryData)

    useEffect(() => {
        if (selectedCategory === ""){
            setQuestions([]);
        }
        else if (!selectedCategory.split(',').includes('all')){
            const categoryArray = selectedCategory.split(',');
            const filteredQuestions = [...questionBank.questions, ...quizQuestion.questions].filter(
                // (question) => (question?.categoryId ?? null) === selectedCategory
                (question) => categoryArray.includes(question?.categoryId ?? '')
            )
            setQuestions(removeDuplicates(filteredQuestions, "question"))
        }
        else {
            setQuestions(removeDuplicates([...questionBank.questions, ...quizQuestion.questions],"question"))
        }
    }, [questionBank, quizQuestion, selectedCategory])

    useEffect(() => {
        console.log(selectedCategory)
    }, [selectedCategory])

    const classes = useStyles()
    const dispatch = useDispatch()
    const canvasData = useSelector((state) => state.canvasData)
    const quizData = useSelector((state) => state.canvasQuizData)

    const handleEdit = (id) => {
        setEdit(id)
    }

    const generateUniqueId = () => {
        return '_' + Math.random().toString(36).substr(2, 9)
    }

    const handleAddQuestion = async () => {
        const newQuestion = {
            question_type: 'multiple_choice_question',
            questionType: 'text',
            question:
                'New Question Statement',
            point: 1.0,
            answers: [
                {
                    weight: 100,
                    text: 'Option 1',
                },
                {
                    weight: 0,
                    text: 'Option 2',
                },
                {
                    weight: 0,
                    text: 'Option 3',
                },
                {
                    weight: 0,
                    text: 'Option 4',
                },
            ],
        }
        const tempQuestion = [newQuestion,]
        const tempQuestionData = { Questions: tempQuestion, selectedCategory: ""}
        await dispatch(addQuestion(tempQuestionData))
        await handleFetchQuestions()
    }

    const handleEditQuestion = (id, ques) => {

    }

    const handleUpdate = async (id, updatedQuestion) => {
        const hasCorrectAnswer = updatedQuestion.answers.some(
            (answer) => answer.weight === 100
        )

        if (
            !hasCorrectAnswer &&
            updatedQuestion.question_type === 'multiple_choice_question'
        ) {
            alert('Please select at least one correct answer')
            return
        }

        const updatedQuestions = questions.map((question) =>
            question._id === id ? updatedQuestion : question
        )

        setQuestions(updatedQuestions)

        await dispatch(updateQuestion(id, updatedQuestion))
        await handleFetchQuestions()

        // setMountFiles((files) => ({ ...selectedFile }))

        setEdit(0)
    }

    const handleCheckboxChange = (questionId, answerId, checked) => {
        const updatedQuestions = questions.map((question) => {
            if (question._id === questionId) {
                const updatedAnswers = question.answers.map((answer) =>
                    answer._id === answerId
                        ? { ...answer, weight: checked ? 100 : 0 }
                        : answer
                )
                return { ...question, answers: updatedAnswers }
            }
            return question
        })
        setQuestions(updatedQuestions)
    }

    const handleAnswerTypeChange = (event, question) => {
        const confirmed = window.confirm(
            'The selected options and answer will not be saved. Do you want to proceed?'
        )
        if (confirmed) {
            let updatedQuestions = questions.map((q) =>
                q._id === question._id
                    ? { ...q, question_type: event.target.value }
                    : q
            )
            if (
                question.question_type === 'true_false_question' &&
                event.target.value !== 'true_false_question'
            ) {
                const updatedAnswers = Array.from({ length: 4 }).map((_, index) => {
                    return {
                        _id: generateUniqueId(),
                        text: `Option ${index + 1}`,
                        weight: index === 0 ? 100 : 0, // Assuming the first option is the correct answer
                    }
                })
                updatedQuestions = updatedQuestions.map((q) =>
                    q._id === question._id
                        ? { ...q, answers: updatedAnswers }
                        : q
                )
            }
            else if (
                question.question_type !== 'true_false_question' &&
                event.target.value === 'true_false_question'
            ) {
                const updatedAnswers = question.answers
                    .slice(0, 2)
                    .map((answer, index) => {
                        if (index === 0) {
                            return { ...answer, text: 'True', weight: 100 }
                        } else {
                            return { ...answer, text: 'False', weight: 0 }
                        }
                    })
                updatedQuestions = updatedQuestions.map((q) =>
                    q._id === question._id
                        ? { ...q, answers: updatedAnswers }
                        : q
                )
            } else if (
                question.question_type !== 'fill_in_the_blanks_question' &&
                event.target.value === 'fill_in_the_blanks_question'
            ) {
                const updatedAnswers = question.answers.map(
                    (answer, index) => ({
                        _id: answer._id,
                        text: answer.text,
                        weight: index === 0 ? 100 : 0,
                    })
                )
                updatedQuestions = updatedQuestions.map((q) =>
                    q._id === question._id
                        ? { ...q, answers: updatedAnswers }
                        : q
                )
            }
            else if (event.target.value === 'multiple_choice_question') {
                const hasChecked = question.answers.some(
                    (answer) => answer.weight === 100
                )
                if (!hasChecked) {
                    const updatedAnswers = question.answers.map(
                        (answer, index) => ({
                            _id: answer._id,
                            text: answer.text,
                            weight: index === 0 ? 100 : 0,
                        })
                    )
                    updatedQuestions = updatedQuestions.map((q) =>
                        q._id === question._id
                            ? { ...q, answers: updatedAnswers }
                            : q
                    )
                }
            }
            setQuestions(updatedQuestions)
        }
    }

    const handleAddNewAnswer = (questionId) => {
        const updatedQuestions = questions.map((question) => {
            if (question._id === questionId) {
                const newAnswer = {
                    _id: Math.random().toString(36).substr(2, 9),
                    text: '',
                    weight: 0,
                }
                return {
                    ...question,
                    answers: [...question.answers, newAnswer],
                }
            }
            return question
        })
        setQuestions(updatedQuestions)
    }

    const handleDeleteAnswer = (questionId, answerId) => {
        const updatedQuestions = questions.map((question) => {
            if (question._id === questionId) {
                const filteredAnswers = question.answers.filter(
                    (answer) => answer._id !== answerId
                )
                return {
                    ...question,
                    answers: filteredAnswers,
                }
            }
            return question
        })
        setQuestions(updatedQuestions)
    }

    const handleTrueFalseAnswerChange = (questionId, answerId) => {
        const updatedQuestions = questions.map((question) => {
            if (question._id === questionId) {
                const updatedAnswers = question.answers.map((answer) =>
                    answer._id === answerId
                        ? { ...answer, weight: 100 }
                        : { ...answer, weight: 0 }
                )
                return { ...question, answers: updatedAnswers }
            }
            return question
        })
        setQuestions(updatedQuestions)
    }

    const handleDeleteQuestion = async (questionId) => {
        const confirmed = window.confirm(
            'The selected question will be deleted. Do you want to proceed?'
        )
        if (confirmed){
            try {
                await dispatch(removeQuestion(questionId))
                await handleFetchQuestions()
            } catch (error) {
            console.error('Error deleting question:', error)
            }
        }
    };

    const handleCancel = () => {
        setEdit(0)
        // setSelectedFile((files) => ({ ...mountFiles }))
        setQuestions(questions)
        handleFetchQuestions()
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    const removeDuplicates = (array, key) => {
        // Create a Set to keep track of unique keys
        const seen = new Set();
        
        // Filter out duplicate objects based on 'id' field
        return array.filter(item => {
            const keyValue = item[key];
            if (!seen.has(keyValue)) {
            seen.add(keyValue);
            return true;
            }
            return false;
        });
    };

    const handleFetchQuestions = () => {
        dispatch(fetchAllQuestions())
        dispatch(fetchAllQuizQuestions())
        // setQuestions(removeDuplicates([...questionBank.questions, ...quizQuestion.questions],"question"))
    }

    const generateRandomId = () => {
        return Math.random().toString(36).substr(2, 10)
    }

    const findQuestionId = (questionStatement) => {
        const matchingQuestion = questionBank.questions.find(
            (question) => question.question === questionStatement
        );
        return matchingQuestion ? matchingQuestion._id : 0;
    }

    const findCategoryName = (categoryId) => {
        const matchingCategory = categoryData.categoryData.find(
            (category) => category._id.toString() == categoryId
        )
        return matchingCategory ? matchingCategory.categoryName : ''
    }

    const handleCategoryChange = (id, event) => {
        const updatedQuestions = questions.map((question) =>
            question._id === id
                ? { ...question, categoryId: event.target.value }
                : question
        )
        setQuestions(updatedQuestions)
    }

    const handleQuestionChange = (id, event) => {
        const updatedQuestions = questions.map((question) =>
            question._id === id
                ? { ...question, question: event.target.value }
                : question
        )
        setQuestions(updatedQuestions)
    }

    const handleDeleteCategory = (category) => {
        const categoryArray = selectedCategory.split(',');
        const modifiedCateogryArray = categoryArray.filter((cat) => cat != category);
        setSelectedCategory(Array.isArray(modifiedCateogryArray) ? modifiedCateogryArray.join(',') : "");
    }

    if (userData && !userData.userFetchCompleted) {
        return <div className="container main-container mb-5" />
    }
    if (
        userData &&
        userData.userFetchCompleted &&
        (!userData.user || userData.user.id === undefined)
    ) {
        return <Redirect to="/" />
    }
    
    if (quizData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={quizData.loadingMessage} />
            </div>
        )
    }

    if (canvasData.loading) {
        return (
            <div className="container main-container mb-5">
                <TopLoader message={canvasData.loadingMessage} />
            </div>
        )
    }

    return (!isAdminAccount? (
        <Fragment>
        <Dialog
          open={true}
        >
          <DialogTitle>UNAUTHORISED ACCESS</DialogTitle>
          <DialogActions>
            
            <Button onClick={()=>{history.push("/")}}>Back to homepage</Button>
          </DialogActions>
        </Dialog>
        </Fragment>
      ): (
        <Paper elevation={3} style={{ marginTop: 20, padding: 20 }}>
            <Stack>
                <Typography fontSize={'1.5rem'} fontWeight={'bold'}>
                    Manage Questions
                </Typography>
            </Stack>
            {/* <Stack width={'15rem'}>
                <Typography fontWeight="bold">
                    Select Quiz Category
                </Typography>
                <Select
                    label=""
                    multiple
                    value={selectedCategory ? selectedCategory.split(',') : []}
                    renderValue={(selected) =>
                    (
                        selected.includes('all')
                            ? 'All'
                            : selected.map((id) => categoryData.categoryData.find(category => category._id === id)?.categoryName).join(', ')
                    )}
                    onChange={(e) => {
                        // setSelectedCategory(e.target.value)
                        setSelectedCategory(e.target.value.join(','))
                        console.log(selectedCategory)
                        setShowAll(false)
                    }}
                >
                    {categoryData.categoryData.map((category) => (
                        <MenuItem
                            key={category._id}
                            value={category._id}
                        >
                            {category.categoryName}
                            <Checkbox checked={selectedCategory.split(',').includes(category._id)} />
                            <ListItemText primary={category.categoryName} />
                        </MenuItem>
                    ))}
                    <MenuItem
                            key={'all'}
                            value={'all'}
                        >
                            {"All"}
                            <Checkbox checked={selectedCategory.split(',').includes('all')} />
                            <ListItemText primary="All" />
                    </MenuItem>
                </Select>
            </Stack> */}
            <Stack width={'15rem'}>
                <FormControl sx={{ m: 1, width: 600 }}>
                <InputLabel>Select Quiz Category</InputLabel>
                <Select
                labelId=""
                id=""
                multiple
                value={selectedCategory !== '' ? selectedCategory.split(',') : []}
                onChange={(e) => {
                    setSelectedCategory(Array.isArray(e.target.value) ? e.target.value.join(',') : e.target.value)
                    setShowAll(false)
                }}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {/* {selected.map((value) => (
                        <Chip key={value} label={value} />
                    ))} */}
                    {selected.map((id) => {
                        const categoryName = categoryData.categoryData.find(category => category._id === id)?.categoryName;
                        return <Chip 
                        key={id} 
                        label={categoryName || id} 
                        onDelete={() => handleDeleteCategory(id)} 
                        onMouseDown={(event) => {
                            event.stopPropagation();
                        }}/>;
                    })}
                    </Box>
                )}
                MenuProps={MenuProps}
                >
                {categoryData.categoryData.map((category) => (
                    <MenuItem
                    key={category._id}
                    value={category._id}
                    style={getStyles(category, selectedCategory, theme)}
                    >
                        {category.categoryName}
                    </MenuItem>
                ))}
                <MenuItem
                    key={'all'}
                    value={'all'}
                >
                    {"All"}
                </MenuItem>
                </Select>
            </FormControl>
            </Stack>
            <Stack style={{ marginTop: 10 }} maxWidth={'20rem'} spacing={2}>
                <Button
                    variant="contained"
                    onClick={() => {
                        setShowAll(true)
                        handleFetchQuestions()
                    }}
                    style={{ width: '10rem' }}
                >
                    Show Questions
                </Button>
            </Stack>

            {/* <Stack width={'auto'} minWidth={'13rem'}>
                <Typography fontWeight="bold">
                    Enter Keywords
                </Typography>
                <Autocomplete
                    multiple
                    freeSolo
                    forcePopupIcon={false}
                    id="keywords"
                    options={[]} // Provide options if needed
                    value={selectedKeyword}
                    onChange={(event, newValue) => {
                        setSelectedKeyword(newValue)
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="" />
                    )}
                />
            </Stack> */}
            
            {/* {showAll && (
                <TableContainer style={{ marginTop: 20 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Question</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {questions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((question, index) => (
                                <TableRow key={index}>
                                    <TableCell>{question.question}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50]}
                        component="div"
                        count={questions.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableContainer>
            )} */}
            {showAll && <Stack spacing={30} direction={'row'}>
                <Container maxWidth="lg">
                    {questions.length === 0 && <>No Questions to display</>}
                    <Stack spacing={2}>
                        <Stack
                            container
                            spacing={2}
                            paddingRight={2}
                            maxHeight={'100%'}
                            fullWidth
                        >
                            {questions.map((question, index) =>
                            question.answers.length > 0 ? (
                                <Card className={classes.card}>
                                    <CardContent>
                                        <Grid
                                            container
                                            xs={12}
                                            justifyContent="space-between"
                                            className={classes.title}
                                        >
                                            <Stack
                                                direction={'row'}
                                                spacing={2}
                                            >
                                                <Typography inline variant="h6">
                                                    Question
                                                </Typography>
                                                {edit === question._id ? null : (
                                                    questionBank.questions.includes(question) ? (
                                                    // UI Bug : cannot align to the right
                                                    <Typography
                                                        marginTop={1}
                                                        inline
                                                        align="right"
                                                    >
                                                        {'Source : Question Bank'}  {'Category: '}{findCategoryName(question.categoryId)}
                                                    </Typography>)
                                                    : (
                                                    <Typography
                                                        marginTop={1}
                                                        inline
                                                        align="right"
                                                    >
                                                        {'Source : Instructor '}{question.createdBy}
                                                    </Typography>)
                                                )}
                                                
                                            </Stack>
                                            <Stack
                                                direction={'row'}
                                                spacing={2}
                                            >
                                                {edit === question._id ? (
                                                    <>
                                                        <Select
                                                            variant="outlined"
                                                            size="small"
                                                            style={{
                                                                width: 300,
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: false,
                                                            }}
                                                            label=""
                                                            labelId="answerType"
                                                            id="answerType"
                                                            value={
                                                                question.question_type
                                                            }
                                                            onChange={(event) =>
                                                                handleAnswerTypeChange(
                                                                    event,
                                                                    question
                                                                )
                                                            }
                                                        >
                                                            <MenuItem value="multiple_choice_question">
                                                                Multiple Choice
                                                            </MenuItem>
                                                            <MenuItem value="fill_in_the_blanks_question">
                                                                Fill in the
                                                                Blanks
                                                            </MenuItem>
                                                            <MenuItem value="true_false_question">
                                                                True or False
                                                            </MenuItem>
                                                        </Select>
                                                        <Select
                                                            variant="outlined"
                                                            size="small"
                                                            style={{
                                                                width: 300,
                                                            }}
                                                            InputLabelProps={{
                                                                shrink: false,
                                                            }}
                                                            label = ""
                                                            value = {question.categoryId}
                                                            onChange={(event) =>
                                                                handleCategoryChange(
                                                                    question._id,
                                                                    event
                                                                )
                                                            }
                                                        >
                                                            {categoryData.categoryData.map((category) => (
                                                                <MenuItem
                                                                    key={category._id}
                                                                    value={category._id}
                                                                >
                                                                    {category.categoryName}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </>
                                                ) : null}
                                                <Stack
                                                    marginTop={-1}
                                                    direction={'row'}
                                                    spacing={1}
                                                >
                                                    {edit !== question._id && questionBank.questions.includes(question) ? (
                                                        <>
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleEdit(
                                                                        question._id
                                                                    )
                                                                }
                                                                aria-label="edit"
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() =>
                                                                    handleDeleteQuestion(
                                                                        question._id
                                                                    )
                                                                }
                                                                aria-label="delete"
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </>
                                                    ) : null}
                                                </Stack>
                                                {edit === question._id ? null : (
                                                    <Typography
                                                        marginTop={1}
                                                        inline
                                                        align="right"
                                                    >
                                                        {question.point} {'pts'}
                                                    </Typography>
                                                )}
                                            </Stack>
                                        </Grid>
                                        {edit !== question._id ? (
                                            <Stack
                                                className={classes.question}
                                                direction={'row'}
                                            >
                                                <Typography variant="h6">
                                                    {parse(question.question)}
                                                </Typography>
                                            </Stack>
                                        ) : null}
                                        {edit === question._id ? (
                                            <>
                                                <TextareaAutosize
                                                    aria-label="question"
                                                    placeholder="Question"
                                                    style={{
                                                        width: '100%',
                                                        minHeight: 100,
                                                    }} // Set the width here
                                                    value={question.question}
                                                    onChange={(e) =>
                                                        handleQuestionChange(
                                                            question._id,
                                                            e
                                                        )
                                                    }
                                                />
                                                {/* <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems="center"
                                                >
                                                    <Grid item>
                                                        <input
                                                            accept="image/*"
                                                            className={
                                                                classes.input
                                                            }
                                                            id={`contained-button-file-${question._id}`}
                                                            type="file"
                                                            onChange={(e) =>
                                                                handleFileInputChange(
                                                                    question._id,
                                                                    e
                                                                )
                                                            }
                                                        />
                                                        <label
                                                            htmlFor={`contained-button-file-${question._id}`}
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                component="span"
                                                                startIcon={
                                                                    <PhotoCamera />
                                                                }
                                                            >
                                                                Choose Picture
                                                            </Button>
                                                        </label>
                                                    </Grid>
                                                    {!!selectedFile[
                                                        question._id
                                                    ] && (
                                                        <>
                                                            <Grid item>
                                                                <Typography variant="body1">
                                                                    {
                                                                        selectedFile[
                                                                            question
                                                                                ._id
                                                                        ].name
                                                                    }
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item>
                                                                <IconButton
                                                                    color="error"
                                                                    variant="outlined"
                                                                    aria-label="delete picture"
                                                                    component="span"
                                                                    onClick={() =>
                                                                        handleDeleteButtonClick(
                                                                            question._id
                                                                        )
                                                                    }
                                                                >
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>

                                                {!!selectedFile[
                                                    question._id
                                                ] && (
                                                    <Grid item p={'1rem'}>
                                                        <img
                                                            src={URL.createObjectURL(
                                                                selectedFile[
                                                                    question._id
                                                                ]
                                                            )}
                                                            alt="Preview"
                                                            className={
                                                                classes.previewImage
                                                            }
                                                        />
                                                    </Grid>
                                                )} */}
                                                {question.question_type ===
                                                    'true_false_question' && (
                                                    <div>
                                                        {question.answers.map(
                                                            (answer) => (
                                                                <div
                                                                    key={
                                                                        answer._id
                                                                    }
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            answer.weight ===
                                                                            100
                                                                        }
                                                                        onChange={() =>
                                                                            handleTrueFalseAnswerChange(
                                                                                question._id,
                                                                                answer._id
                                                                            )
                                                                        }
                                                                        disabled={
                                                                            answer.weight ===
                                                                            100
                                                                        }
                                                                    />
                                                                    <Typography
                                                                        variant="subtitle2"
                                                                        style={{
                                                                            marginLeft: 10,
                                                                        }}
                                                                    >
                                                                        {
                                                                            answer.text
                                                                        }
                                                                    </Typography>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                                {question.question_type ===
                                                    'multiple_choice_question' && (
                                                    <div>
                                                        {question.answers.map(
                                                            (answer) => (
                                                                <div
                                                                    key={
                                                                        answer._id
                                                                    }
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={
                                                                            answer.weight ===
                                                                            100
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            handleCheckboxChange(
                                                                                question._id,
                                                                                answer._id,
                                                                                e
                                                                                    .target
                                                                                    .checked
                                                                            )
                                                                        }
                                                                    />
                                                                    <TextareaAutosize
                                                                        aria-label="answer"
                                                                        placeholder="Answer"
                                                                        value={
                                                                            answer.text
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            handleAnswerChange(
                                                                                question._id,
                                                                                answer._id,
                                                                                e
                                                                            )
                                                                        }
                                                                        style={{
                                                                            width: '90%',
                                                                            minHeight: 50,
                                                                            marginLeft: 10,
                                                                            marginBottom: 10,
                                                                        }}
                                                                    />
                                                                    {question.question_type !==
                                                                    'true_false_question' ? (
                                                                        <IconButton
                                                                            sx={{
                                                                                justifyContent:
                                                                                    'center',
                                                                                alignItems:
                                                                                    'center',
                                                                            }}
                                                                            onClick={() =>
                                                                                handleDeleteAnswer(
                                                                                    question._id,
                                                                                    answer._id
                                                                                )
                                                                            }
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    ) : null}
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                                {question.question_type ===
                                                    'fill_in_the_blanks_question' && (
                                                    <div>
                                                        {question.answers.map(
                                                            (answer, index) => (
                                                                <div
                                                                    key={
                                                                        answer._id
                                                                    }
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <Radio
                                                                        checked={
                                                                            answer.weight ===
                                                                            100
                                                                        }
                                                                        onChange={() => {
                                                                            const updatedAnswers =
                                                                                question.answers.map(
                                                                                    (
                                                                                        ans,
                                                                                        i
                                                                                    ) => ({
                                                                                        ...ans,
                                                                                        weight:
                                                                                            i ===
                                                                                            index
                                                                                                ? 100
                                                                                                : 0,
                                                                                    })
                                                                                )
                                                                            setQuestions(
                                                                                questions.map(
                                                                                    (
                                                                                        q
                                                                                    ) =>
                                                                                        q._id ===
                                                                                        question._id
                                                                                            ? {
                                                                                                  ...q,
                                                                                                  answers:
                                                                                                      updatedAnswers,
                                                                                              }
                                                                                            : q
                                                                                )
                                                                            )
                                                                        }}
                                                                    />
                                                                    <TextareaAutosize
                                                                        aria-label="answer"
                                                                        placeholder="Answer"
                                                                        value={
                                                                            answer.text
                                                                        }
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            handleAnswerChange(
                                                                                question._id,
                                                                                answer._id,
                                                                                e
                                                                            )
                                                                        }
                                                                        style={{
                                                                            width: '90%',
                                                                            minHeight: 50,
                                                                            marginLeft: 10,
                                                                            marginBottom: 10,
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                                {question.question_type !==
                                                'true_false_question' ? (
                                                    <Button
                                                        sx={{
                                                            fontWeight: 'bold',
                                                            color: 'skyBlue',
                                                            float: 'right',
                                                            marginBottom: 4,
                                                            marginTop: 1,
                                                        }}
                                                        onClick={() =>
                                                            handleAddNewAnswer(
                                                                question._id
                                                            )
                                                        }
                                                        variant="link"
                                                        startIcon={<AddIcon />}
                                                    >
                                                        Add New Answer
                                                    </Button>
                                                ) : null}
                                                <br></br>
                                                <br></br>
                                                <label
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Points Possible :{'   '}
                                                    <input
                                                        type="number"
                                                        value={question.point}
                                                        onChange={(e) =>
                                                            setQuestions(
                                                                questions.map(
                                                                    (q) =>
                                                                        q._id ===
                                                                        question._id
                                                                            ? {
                                                                                  ...q,
                                                                                  point: e
                                                                                      .target
                                                                                      .value,
                                                                              }
                                                                            : q
                                                                )
                                                            )
                                                        }
                                                    />
                                                </label>
                                                <label
                                                    style={{
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Keyword :{'   '}
                                                    <input
                                                        type="text"
                                                        value={question.keyword}
                                                        onChange={(e) =>
                                                            setQuestions(
                                                                questions.map(
                                                                    (q) =>
                                                                        q._id === question._id
                                                                            ? {
                                                                                  ...q,
                                                                                  keyword: e.target.value,
                                                                              }
                                                                            : q
                                                                )
                                                            )
                                                        }
                                                    />
                                                </label>
                                                <Stack
                                                    justifyContent={'flex-end'}
                                                    marginTop={-1}
                                                    direction={'row'}
                                                    spacing={1}
                                                >
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() =>
                                                            handleUpdate(
                                                                question._id,
                                                                question
                                                            )
                                                        }
                                                        aria-label="update"
                                                    >
                                                        Update
                                                    </Button>
                                                    <Button
                                                        size="small"
                                                        variant="contained"
                                                        onClick={handleCancel}
                                                        aria-label="cancel"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Stack>
                                            </>
                                        ) : (
                                            question.answers.map(
                                                (answer, index) => (
                                                    <div
                                                        className={
                                                            classes.bodyItem
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems:
                                                                    'center',
                                                                flexWrap:
                                                                    'wrap',
                                                            }}
                                                        >
                                                            <Stack
                                                                direction={
                                                                    'row'
                                                                }
                                                                spacing={2}
                                                            >
                                                                {answer.weight ==
                                                                100 ? (
                                                                    <RadioButtonChecked></RadioButtonChecked>
                                                                ) : (
                                                                    <RadioButtonUnchecked></RadioButtonUnchecked>
                                                                )}
                                                                <Typography variant="subtitle2">
                                                                    {
                                                                        answer.text
                                                                    }
                                                                </Typography>
                                                            </Stack>
                                                        </div>
                                                    </div>
                                                )
                                            )
                                        )}
                                    </CardContent>
                                </Card>
                                ) : null
                            )}
                        </Stack>
                    </Stack>
                    {/* <Stack
                        direction={'row'}
                        justifyContent={'flex-end'}
                        spacing={2}
                    >
                        <Button
                            startIcon={<AddIcon />}
                            variant="contained"
                            align="right"
                            sx={{ marginLeft: 'auto' }}
                            onClick={handleAddQuestion}
                        >
                            Add Question
                        </Button>
                    </Stack> */}
                </Container>
            </Stack>}   
        </Paper>)
    )
}

export default QuestionManagement