/* eslint-disable */
import { combineReducers } from 'redux'
import userReducer from './user/userReducer'
import courseReducer from './course/courseReducer'
import errorReducer from './error/errorReducer'
import userVerificationReducer from './userVerification/userVerificationReducer'
import resetPasswordReducer from './resetPassword/resetPasswordReducer'
import contactUsReducer from './contactUs/contactUsReducer'
import quizMetaReducer from './quizMeta/quizMetaReducer'
import quizReducer from './quiz/quizReducer'
import quizCategoryMetaReducer from './quizCategory/quizCategoryReducer'
import quizQuestionReducer from './quizQuestion/quizQuestionReducer'
import savedCourseReducer from './savedCourse/savedCourseReducer'
import jobReducer from './job/jobReducer'
import upgradeReducer from './upgrade/upgradeReducer'
import searchReducer from './search/searchReducer'
import questionBankReducer from './QuestionBank/questionBankReducer'
import AdminReducer from './Admin/AdminReducer'
import virtualTAReducer from './virtualTA/virtualTAReducer'
import canvasReducer from './canvas/canvasReducer'
import modulesReducer from './canvasModules/canvasModulesReducer'
import canvasQuizReducer from './canvasQuiz/canvasQuizReducer'
import canvasAssignmentReducer from './canvasAssignments/canvasAssignmentReducer'
import canvasAssignmentGroupReducer from './canvasAssignmentGroups/canvasAssignmentGroupReducer'
import canvasAccessReducer from './canvasAccess/canvasAccessReducer'
import categoryReducer from './category/categoryReducer'
import userLinkReducer from './userLinks/userLinkReducer'

const initialBoolState = {
    boolValue: false,
  };
  
  
  const boolReducer = (state = initialBoolState, action) => {
    switch (action.type) {
      case 'TOGGLE_BOOL':
        return {
          ...state,
          boolValue: !state.boolValue,
        };
      case 'SET_TRUE':
        return {
            ...state,
            boolValue:true,
        };
      default:
        return state;
    }
  };
  


const rootReducer = combineReducers({
    linkData: userLinkReducer,
    userData: userReducer,
    currentCourseData: courseReducer,
    errorData: errorReducer,
    userVerificationData: userVerificationReducer,
    resetPasswordData: resetPasswordReducer,
    contactUsData: contactUsReducer,
    quizMetaData: quizMetaReducer,
    quizData: quizReducer,
    quizCategoryMetaData: quizCategoryMetaReducer,
    quizQuestionData: quizQuestionReducer,
    savedCourseData: savedCourseReducer,
    jobData: jobReducer,
    searchData: searchReducer,
    questionBankData: questionBankReducer,
    // upgrade Request
    adminData: AdminReducer,
    upgradeData: upgradeReducer,
    virtualTAData: virtualTAReducer,
    canvasData: canvasReducer,
    modulesData: modulesReducer,
    canvasQuizData: canvasQuizReducer,
    assignmentData: canvasAssignmentReducer,
    assignmentGroupData: canvasAssignmentGroupReducer,
    categoryData: categoryReducer,
    canvasAccessData: canvasAccessReducer,
    bool: boolReducer,
})

export default rootReducer
